import { Box, Stack, styled, Typography } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultButton } from '../Forms'
import IconSuccess from './Assets/IconSuccess.svg'

const ResponseCardSuccess = observer(props => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const { width } = useSizeWindow()

    return (
      <StackStyled backgroundColor={'#FFFFFF'} borderRadius="12px">
        <BoxStyled>
            <img style={{ height: '28px' }} src={IconSuccess} alt={'Error'} />
            <TypographyHeader >
                <T>welcome_unicity</T>!<br/>
                <T>thanks_for_signing_up</T>
            </TypographyHeader>

            <TypographyStyled mt={width < 600 ? '16px' : '30px'} fontWeight={'600 !important'}>
                <T>account_details</T>
            </TypographyStyled>

            <TypographyStyled>
                {`${translate('unicity_id')} ${StoreAuth.GetId()}`}
            </TypographyStyled>

            <TypographyStyled>
                {`${translate('email_address')}: ${StoreUser.CustomerData().Email()}`}
            </TypographyStyled>

            <Box mt="40px">
                <DefaultButton onClick={() => navigate('/products')} titleText={translate('Shop_feel_great')} width={'279px'}/>
            </Box>
        </BoxStyled>
      </StackStyled>
    )
})

const StackStyled = styled(Stack)`
    height: 395px;
    
    @media screen and (min-width: 600px) {
        width: 600px;
    }

    @media screen and (min-width: 1200px) {
        width: 770px;
        height: 428px;
    }
`

const BoxStyled = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px 50px 30px;

    @media screen and (min-width: 1200px) {
        padding: 50px 30px;
    }
`

const TypographyHeader = styled(Typography)`
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-top: 30px;

    @media screen and (min-width: 600px) {
        font-size: 32px;
    }
`

const TypographyStyled = styled(Typography)`
    font-size: 14px !important;
    font-weight: 400;

    @media screen and (min-width: 414px) {
        font-size: 16px !important;
    }
`


export default ResponseCardSuccess