import { ReactComponent as CheckedIcon } from 'Components/Elements/Checkout/Assets/check-green.svg'
import { ReactComponent as TimesIcon } from 'Components/Elements/Checkout/Assets/times.svg'
import Loader from 'Components/Elements/Checkout/Assets/Loader.svg'
import { Box, Modal, Stack, Typography } from '@mui/material'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import styled from '@emotion/styled'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import Errors from '../Errors/Errors'
import { DefaultButton } from '../Forms'
import { runInAction } from 'mobx'
import { storeElement } from 'GlobalStores/StoreElement'

const ModalProcessPayments = ({ isOpen, onClose }) => {
    const { width } = useSizeWindow()
    const translate = useTranslate()

    const PaymentStepTranslate = (key, step) => {
        let keyText = key
        if (key === 'payment') {
            keyText = 'processing_payment'
        } else if (key === 'createOrder') {
            keyText = 'preparing_order'
        } else if (key === 'updateLog') {
            keyText = 'update_log'
        } else if (key === 'autoship') {
            keyText = 'processing_subscription'
        } else if (key === 'prepare_data') {
            keyText = 'prepare_data'
        }

        if (key === 'payment') {
            if (step === -1) {
                keyText = keyText + '_failed'
            } else if (step === 1) {
                keyText = keyText + '_success'
            } else if (step === 0) {
                keyText = keyText + '_processing'
            }
        } else if (key === 'createOrder') {
            if (step === -1) {
                keyText = keyText + '_failed'
            } else if (step === 1) {
                keyText = keyText + '_success'
            } else if (step === 0) {
                keyText = keyText + '_processing'
            }
        } else if (key === 'updateLog') {
            if (step === -1) {
                keyText = keyText + '_failed'
            } else if (step === 1) {
                keyText = keyText + '_success'
            } else if (step === 0) {
                keyText = keyText + '_processing'
            }
        } else if (key === 'autoship') {
            if (step === -1) {
                keyText = keyText + '_failed'
            } else if (step === 1) {
                keyText = keyText + '_success'
            } else if (step === 0) {
                keyText = keyText + '_processing'
            }
        } else if (key === 'prepare_data') {
            if (step === -1) {
                keyText = keyText + '_failed'
            } else if (step === 1) {
                keyText = keyText + '_success'
            } else if (step === 0) {
                keyText = keyText + '_processing'
            }
        }

        return (
            <Typography fontSize={'24px'} color="#ffffff">
                <T>{keyText}</T>
            </Typography>
        )
    }

    const ProcessingColor = step => {
        if (step === 1) {
            return 'process-success'
        } else if (step === -1) {
            return 'process-failed'
        }
    }

    const ProcessingIcon = step => {
        switch (step) {
            case 0:
                return <Spiner src={Loader} alt="Loading" style={{ marginLeft: 5 }} />
            case 1:
                return <CheckedIcon />
            case -1:
                return <TimesIcon />
            default:
                return <TimesIcon />
        }
    }

    return (
        <Modal
            disableEscapeKeyDown={true}
            open={isOpen}
            onClose={() => onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Stack display={'flex'} alignItems={'center'} height="100%" justifyContent={'center'} padding={width < 730 ? '0 16px' : '0 47px'}>
                <BackdropBodyStyled>
                    <Typography variant="h2" textAlign={'left'} fontSize={'30px'} color="#ffffff">
                        <T>hydra_payment_processing</T>
                    </Typography>
                    {StorePaymentStep.GetPaymentSteps() && (
                        <ProcessingStyled>
                            {StorePaymentStep.GetPaymentSteps().payment > -2 && (
                                <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().prepare_data)}>
                                    {PaymentStepTranslate('prepare_data', StorePaymentStep.GetPaymentSteps().prepare_data)}{' '}
                                    {ProcessingIcon(StorePaymentStep.GetPaymentSteps().prepare_data)}
                                </li>
                            )}
                            {StorePaymentStep.GetPaymentSteps().payment > -2 && (
                                <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().payment)}>
                                    {PaymentStepTranslate('payment', StorePaymentStep.GetPaymentSteps().payment)}{' '}
                                    {ProcessingIcon(StorePaymentStep.GetPaymentSteps().payment)}
                                </li>
                            )}

                            {/* {StorePaymentStep.GetPaymentSteps().createOrder > -2 && (
                                <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().createOrder)}>
                                    {PaymentStepTranslate('createOrder', StorePaymentStep.GetPaymentSteps().createOrder)}{' '}
                                    {ProcessingIcon(StorePaymentStep.GetPaymentSteps().createOrder)}
                                </li>
                            )} */}

                            {StorePaymentStep.GetPaymentSteps().autoship > -2 && (
                                <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().autoship)}>
                                    {PaymentStepTranslate('autoship', StorePaymentStep.GetPaymentSteps().autoship)}{' '}
                                    {ProcessingIcon(StorePaymentStep.GetPaymentSteps().autoship)}
                                </li>
                            )}
                            {StorePaymentStep.GetPaymentSteps().updateLog > -2 && (
                                <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().updateLog)}>
                                    {PaymentStepTranslate('updateLog', StorePaymentStep.GetPaymentSteps().updateLog)}
                                    {ProcessingIcon(StorePaymentStep.GetPaymentSteps().updateLog)}
                                </li>
                            )}
                        </ProcessingStyled>
                    )}
                    {StorePaymentStep.PaymentErrors().length > 0 ? <Errors list={StorePaymentStep.PaymentErrors()} showUUID={false} /> : null}
                    {StorePaymentStep.PaymentErrors().length > 0 ? (
                        <Stack mt="40px" display={'flex'} alignItems={'center'}>
                            <Box width="40%">
                                <DefaultButton
                                    titleText={translate('CLOSE')}
                                    onClick={() => {
                                        onClose()
                                        runInAction(() => {
                                            storeElement.showCardFrontDrop = false
                                        })
                                    }}
                                />
                            </Box>
                        </Stack>
                    ) : null}
                </BackdropBodyStyled>
            </Stack>
        </Modal>
    )
}

const BackdropBodyStyled = styled.div`
    padding: 40px 24px;
    background: #003b6f;
    border-radius: 8px;

    @media screen and (min-width: 1024px) {
        width: 650px;
    }
`

const ProcessingStyled = styled.ul`
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 0px;
    li {
        color: #ffffff;
        list-style-type: none;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: auto 35px;
        align-items: center;
        padding-left: 0px;

        svg {
            width: 35px;
        }

        &:last-child {
            padding-bottom: 0px;
        }

        &.process-success {
            color: #03c302;
        }

        &.process-failed {
            color: rgb(254, 93, 124);
        }
    }
`

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

export default ModalProcessPayments
