import { Stack, Typography } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import copy from 'copy-to-clipboard'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import ClipboardIcon from './ClipboardIcon'
import styled from '@emotion/styled'
import { ReactComponent as CopiedIcon } from './copied.svg'

const UFGLinkBox = observer(({ referralCode }) => {
    const [currentUrl, setCurrentUrl] = useState('')
    const [isCopied, setIsCopied] = useState(false)
    const [display, setDisplay] = useState('')

    const translate = useTranslate()

    const handleCopy = () => {
        if (display === referralCode) {
            if (!isCopied) {
                copy(referralCode)
                setIsCopied(true)
                setTimeout(() => {
                    setIsCopied(false)
                }, 5000)
            }
        } else {
            if (!isCopied) {
                copy(currentUrl)
                setIsCopied(true)
                setTimeout(() => {
                    setIsCopied(false)
                }, 5000)
            }
        }
    }

    const toggleShowReferralCode = () => {
        if (display === referralCode) {
            setDisplay(`https://ufg.expert/uae/${referralCode}`)
        } else {
            setDisplay(referralCode)
        }
    }

    useEffect(() => {
        if (referralCode !== '') {
            setCurrentUrl(`https://ufg.expert/uae/${referralCode}`)
            setDisplay(`https://ufg.expert/uae/${referralCode}`)
        }
    }, [referralCode])

    return (
        <LinkActionStack mb="20px">
            <Stack
                direction={{
                    xs: 'column',
                    md: 'row'
                }}
                width={'100%'}
                gap={{
                    xs: '0px',
                    md: '20px'
                }}>
                <Stack width={'100%'} maxWidth={'500px'}>
                    <LinkWrapper>
                        <Typography
                            variant="subtitle1"
                            color={'rgb(11, 59, 111)'}
                            component="a"
                            href={currentUrl}
                            fontSize={'14px'}
                            style={{ textDecoration: 'none' }}>
                            {display}
                        </Typography>
                        <div className="icon-container" onClick={handleCopy}>
                            {isCopied ? <CopiedIcon /> : <ClipboardIcon />}
                        </div>
                    </LinkWrapper>
                    <Stack style={{ visibility: isCopied ? 'visible' : 'hidden', justifyContent: 'flex-end', textAlign: 'right' }} display={'flex'}>
                        <Typography
                            display={'flex'}
                            justifyContent={'flex-end'}
                            variant="error"
                            textAlign={'right'}
                            component="span"
                            color={'#FF820E'}
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'flex'
                                }
                            }}>
                            {translate(`copied`)}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography
                        variant="caption"
                        component="span"
                        onClick={toggleShowReferralCode}
                        sx={{ cursor: 'pointer', color: 'rgb(7, 159, 244)', textDecoration: 'underline' }}
                        fontSize={'14px'}
                        marginTop={{
                            xs: '15px',
                            md: '15px'
                        }}>{`${display === referralCode ? `${translate('show_my_ref_link')}` : `${translate('show_my_ref_code')}`}`}</Typography>
                    <Stack style={{ visibility: isCopied ? 'visible' : 'hidden', justifyContent: 'flex-end', textAlign: 'right' }}>
                        <Typography
                            variant="error"
                            component="span"
                            color={'#FF820E'}
                            sx={{
                                display: {
                                    xs: 'flex',
                                    sm: 'none'
                                }
                            }}>
                            {translate(`copied`)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </LinkActionStack>
    )
})

export default UFGLinkBox

const LinkWrapper = styled(Stack)`
    width: auto;
    max-width: 500px;
    padding: 10px 15px;
    color: #003b6f;
    font-size: 14px;
    font-weight: 400;

    background-color: #f6f9fd;
    border: 1px solid #bdd2e7;
    border-radius: 8px;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .icon-container {
        cursor: pointer;
        svg {
            width: 18px;
            height: 20px;

            :hover {
                filter: brightness(0.8);
            }
        }
    }

    & a:hover {
        text-decoration: underline !important;
        color: #003b6f !important;
    }
`

const LinkActionStack = styled(Stack)`
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`
