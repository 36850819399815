import isArray from 'lodash/isArray'
import { useEffect, useRef, useState } from 'react'

const ImageHelper = ({ src, ...rest }) => {
    const [imgSrc, setImgSrc] = useState(isArray(src) ? src[0] : src)
    const imgRef = useRef()

    useEffect(() => {
        setImgSrc(isArray(src) ? src[0] : src)
    }, [src])

    return (
        <img
            {...rest}
            ref={imgRef}
            src={imgSrc}
            onLoad={() => {
                if (imgRef.current?.clientWidth === 0) {
                    setImgSrc(isArray(src) ? src[1] : src)
                }
            }}
            onError={() => {
                setImgSrc(isArray(src) ? src[1] : src)
            }}
            alt=""
        />
    )
}

export default ImageHelper
