// import { isProd } from 'Shared/appEnv.js'
const liveMarkets = {
    americas: {
        CA: ['en', 'fr'],
        // MX: ['es'],
        PR: ['es', 'en'],
        US: ['en', 'es']
        // CO: ['es'],
        // DO: ['es', 'en'],
        // JM: ['en']
    },
    europe: {
        AT: ['de', 'en'],
        BE: ['fr', 'en', 'nl'],
        DE: ['de', 'en'],
        NO: ['en'],
        // TR: ['tr', 'en'],
        HU: ['en'],
        PL: ['en'],
        // UA: ['en'],
        DK: ['en'],
        IE: ['en'],
        ES: ['en'],
        GB: ['en'],
        // FI: ['en'],
        IT: ['en', 'de'],
        SE: ['en'],
        FR: ['fr', 'en'],
        NL: ['de', 'en', 'nl'],
        CH: ['de', 'fr', 'en'],
        LU: ['en', 'de', 'fr']
    },
    asia_pacific: {
        AU: ['en'],
        // JP: ['ja', 'en'],
        // LB: ['ar', 'en'],
        PH: ['en'],
        // BN: ['en'],
        // MY: ['en'],
        SG: ['en'],
        // KH: ['km', 'en'],
        // JO: ['ar', 'en'],
        // MN: ['mn', 'en'],
        // TW: ['zh'],
        // HK: ['zh', 'en'],
        // KR: ['ko', 'en'],
        // MM: ['ar', 'en'],
        // TH: ['th', 'en'],
        // IN: ['en'],
        // KW: ['ar', 'en'],
        NZ: ['en'],
        SA: ['en']
        // VN: ['vi'],
        // ID: ['id', 'en'],
        // LA: ['lo', 'en'],
    },
    middle_east_and_africa: {
        OM: ['ar', 'en'],
        // DZ: ['ar', 'en', 'fr'],
        // KE: ['en'],
        // MA: ['ar', 'en', 'fr'],
        UE: ['en']
        // EG: ['ar', 'en']
    }
}

// const devMarkets = {}
// let pickerRegions = liveMarkets
// if (!isProd()) {
//     pickerRegions = { ...pickerRegions, ...devMarkets }
// }
// export default pickerRegions

const countriesLanguage = {
    CA: ['en', 'fr'],
    MX: ['es'],
    PR: ['es', 'en'],
    US: ['en', 'es'],
    CO: ['es'],
    DO: ['es', 'en'],
    JM: ['en'],
    AT: ['de', 'en'],
    DE: ['de', 'en'],
    NO: ['en'],
    TR: ['tr', 'en'],
    BE: ['fr', 'en', 'nl'],
    HU: ['en'],
    PL: ['en'],
    UA: ['en'],
    DK: ['en'],
    IE: ['en'],
    ES: ['en'],
    GB: ['en'],
    FI: ['en'],
    IT: ['en', 'de'],
    SE: ['en'],
    FR: ['fr', 'en'],
    NL: ['de', 'en', 'nl'],
    CH: ['de', 'fr', 'en'],
    LU: ['en', 'de', 'fr'],
    AU: ['en'],
    JP: ['ja', 'en'],
    LB: ['ar', 'en'],
    PH: ['en'],
    BN: ['en'],
    MY: ['en'],
    SG: ['en'],
    KH: ['km', 'en'],
    JO: ['ar', 'en'],
    MN: ['mn', 'en'],
    TW: ['zh'],
    HK: ['zh', 'en'],
    KR: ['ko', 'en'],
    MM: ['ar', 'en'],
    TH: ['th', 'en'],
    IN: ['en'],
    KW: ['ar', 'en'],
    NZ: ['en'],
    SA: ['en'],
    VN: ['vi'],
    ID: ['id', 'en'],
    LA: ['lo', 'en'],
    OM: ['ar', 'en'],
    DZ: ['ar', 'en', 'fr'],
    KE: ['en'],
    MA: ['ar', 'en', 'fr'],
    UE: ['en'],
    EG: ['ar', 'en']
}

const languageTranslations = {
    ar: 'العربية',
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    id: 'Indonesia',
    ja: '日本語',
    km: 'Khmer',
    ko: '한국어',
    tr: 'Türkçe',
    vi: 'Tiếng Việt',
    zh: '中文',
    mn: 'монгол',
    lo: 'ພາສາລາວ',
    th: 'ไทย',
    nl: 'Nederlands'
}

const countryRoutes = {
    UE: 'uae',
    AT: 'aut',
    AU: 'aus',
    BE: 'bel',
    BN: 'brn',
    CA: 'can',
    CH: 'che',
    CO: 'col',
    DE: 'deu',
    DK: 'dnk',
    DO: 'dom',
    DZ: 'dza',
    EG: 'egy',
    ES: 'esp',
    FI: 'fin',
    FR: 'fra',
    GB: 'gbr',
    HK: 'hkg',
    HU: 'hun',
    ID: 'idn',
    IE: 'irl',
    IN: 'ind',
    IT: 'ita',
    JM: 'jam',
    JO: 'jor',
    JP: 'jpn',
    KE: 'ken',
    KH: 'khm',
    KR: 'kor',
    KW: 'kwt',
    LA: 'lao',
    LB: 'lbn',
    MA: 'mar',
    MM: 'mmr',
    MN: 'mng',
    MX: 'mex',
    MY: 'mys',
    NL: 'nld',
    NO: 'nor',
    NZ: 'nzl',
    OM: 'omn',
    PH: 'phl',
    PL: 'pol',
    PR: 'pri',
    SA: 'sau',
    SE: 'swe',
    SG: 'sgp',
    TH: 'tha',
    TR: 'tur',
    TW: 'twn',
    UA: 'ukr',
    US: 'usa',
    VN: 'vnm',
    LU: 'lux'
}

function getRouteLink(country, language) {
    const languageCodes = {
        'en-JP': 'https://express.unicity.com/japan'
    }

    return languageCodes[`${language}-${country}`] ?? `/${countryRoutes[country].toLowerCase()}/${language}/home`
}

export { languageTranslations, countryRoutes, getRouteLink, liveMarkets, countriesLanguage }
