import { Box, Stack, styled, Typography } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultButton } from '../Forms'
import IconError from './Assets/IconError.svg'

const ResponseCardError = observer(props => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const { width } = useSizeWindow()

    return (
      <StackStyled>
        <BoxStyled>
            <img style={{ width: '140px', height: '65px' }} src={IconError} alt={'Error'} />

            <TypographyHeader>
                <T>page_not_found</T>
            </TypographyHeader>

            <TypographySubtitle>
                We can’t seem to find what you’re looking for. Don’t worry, you can find plenty of other things by visiting our homepage.
            </TypographySubtitle>

            <Box mt="40px">
                <DefaultButton width={'279px'} onClick={() => navigate('/products')} titleText={translate('back_to_home')} />
            </Box>
        </BoxStyled>
      </StackStyled>
    )
})

const StackStyled = styled(Stack)`
    height: 459px;
    
    @media screen and (min-width: 600px) {
        width: 600px;
    }

    @media screen and (min-width: 700px) {
        width: 770px;
    }
`

const BoxStyled = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px 60px 20px;
    background-color: #FFFFFF;
    border-radius: 12px;

    @media screen and (min-width: 1200px) {
        padding: 50px 30px;
    }
`

const TypographyHeader = styled(Typography)`
    margin-top: 30px;
    font-size: 24px;
    line-height: 42px;
    text-align: center;

    @media screen and (min-width: 600px) {
        font-size: 32px;
    }
`

const TypographySubtitle = styled(Typography)`
    width: 307px;
    margin-top: 30px;
    text-align: center;
    font-size: 16px !important;
    font-weight: 400;

    @media screen and (min-width: 600px) {
        width: 355px;
    }
`

export default ResponseCardError