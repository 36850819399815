import { reverse } from 'lodash'

function currency({ number, decimal = false, locale = 'en-US', currency, symbol, swapPosition = true }) {
    // console.log('locale', locale)
    try {
        let format = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            currencyDisplay: symbol ? 'narrowSymbol' : 'code',
            symbol: symbol ? symbol : null,
            minimumFractionDigits: decimal ? decimal : 0
        }).format(number)

        if (swapPosition === true) {
            let swapPosition = format.split(/\s/)
            swapPosition = reverse(swapPosition)

            if (number < 0) {
                return `-${swapPosition[0].replace('-', '')} ${swapPosition[1] ? swapPosition[1].replace('-', '') : ''}`
            } else {
                return `${swapPosition[0]} ${swapPosition[1] ? swapPosition[1] : ''}`
            }
        } else {
            return format
        }
    } catch (e) {
        let format = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            currencyDisplay: symbol ? 'symbol' : 'code',
            symbol: symbol ? symbol : null,
            minimumFractionDigits: decimal ? decimal : 0
        }).format(number)

        if (swapPosition === true) {
            let swapPosition = format.split(/\s/)
            swapPosition = reverse(swapPosition)

            if (number < 0) {
                return `-${swapPosition[0].replace('-', '')} ${swapPosition[1] ? swapPosition[1].replace('-', '') : ''}`
            } else {
                return `${swapPosition[0]} ${swapPosition[1] ? swapPosition[1] : ''}`
            }
        } else {
            return format
        }
    }
}

export const NumberHelpers = {
    currency
}
