import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import HttpHelper from 'Services/HttpHelper'

const HydraOrderTerms = (data, token) => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/orderTerms` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${token}`,
            'X-Application': `asia.fg.${storeCountry.Country2().toLowerCase()}`
        }
    })
}

const HydraCreateCustomer = data => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers` })
    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Application': `asia.fg.${storeCountry.Country2().toLowerCase()}`
        }
    })
}

export const HydraAPIs = {
    HydraOrderTerms,
    HydraCreateCustomer
}
