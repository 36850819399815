import { Typography, Box, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { useLocation, useNavigate } from 'react-router-dom'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { runInAction } from 'mobx'
import { storeElement } from 'GlobalStores/StoreElement'
import StoreAuth from 'GlobalStores/User/StoreAuth'

const BreadcrumbWrapper = observer(({ breadcrumbOption, circledimeter, option, index, activeAt, disabled }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [isActive, setIsActive] = useState()
    const pathname = location.pathname
    const { width } = useSizeWindow()

    const mobileSize = width < 600

    const link = option.authLink && StoreAuth.IsAuthorized() ? option.authLink : option.link

    useEffect(() => {
        if (disabled) return
        const idx = breadcrumbOption.findIndex(option => option.link === pathname || option.authLink === pathname)
        if (index === idx) {
            setIsActive(1)
        } else {
            setIsActive(0)
        }
    }, [pathname, index, disabled, breadcrumbOption])

    return (
        <BreadcrumbWrapperStyle circledimeter={circledimeter}>
            <BreadcrumbLinkItem
                onClick={() => {
                    if (!StoreCheckout.canGoToNextStep(`${link}`)) return
                    const idx = breadcrumbOption.findIndex(e => (e.authLink && StoreAuth.IsAuthorized() ? e.authLink.includes(link) : e.link.includes(link)))
                    const result = breadcrumbOption.reduce((acc, current, index) => {
                        if (index > idx) return acc
                        if (current.link === '/products') {
                            return [...acc, current.link]
                        }
                        let currentLink = current.link
                        if (current.authLink && StoreAuth.IsAuthorized()) {
                            currentLink = current.authLink
                        }
                        return [...acc, `${currentLink}?uuid=${StoreCheckout.GetUuid()}`]
                    }, [])

                    runInAction(() => {
                        StoreCheckout.completedActionsLink = result
                    })

                    navigate(`${link}${option.paramsRequire ? window.location.search : ''}`)
                }}
                cangonextstep={StoreCheckout.canGoToNextStep(`${link}`)}>
                <BreadcrumbCircle active={isActive} circledimeter={circledimeter} />
                <BreadcrumbCaption active={isActive}>
                    <T>{StoreAuth.IsAuthorized() && option.authCaption ? option.authCaption : option.caption}</T>
                </BreadcrumbCaption>
            </BreadcrumbLinkItem>
            {breadcrumbOption.length - 1 !== index && (
                <BreadcrumbLeadLineWrapper circledimeter={circledimeter}>
                    {storeElement.dynamicWidth < 767 && (StoreCheckout.CheckOutInstance().GetBreadcrumbOption() || []).length < 4 ? (
                        <Box sx={{ width: { xs: '40px', sm: '120px', lg: '176px' }, border: '1px dashed #A8C3DF' }} />
                    ) : (
                        <Box sx={{ width: { xs: '0px', sm: '100px', lg: '176px' }, border: '1px dashed #A8C3DF' }} />
                    )}
                    {/* <Box sx={{ width: { xs: '40px', sm: '120px', lg: '176px' }, border: '1px dashed #A8C3DF' }} /> */}
                    <Box sx={{ backgroundColor: '#A8C3DF', width: mobileSize ? '7px' : '13px', height: mobileSize ? '7px' : '13px', borderRadius: '50%' }} />
                </BreadcrumbLeadLineWrapper>
            )}
        </BreadcrumbWrapperStyle>
    )
})

const BreadcrumbWrapperStyle = styled(Box)`
    display: flex;
    gap: ${({ circledimeter }) => `${circledimeter / 3}px`};

    @media screen and (min-width: 600px) and (max-width: 829px) {
        gap: 10px;
    }
`

const BreadcrumbLinkItem = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60px;
    cursor: ${({ cangonextstep }) => (cangonextstep ? 'pointer' : '')};
`

const BreadcrumbCircle = styled(Box)`
    width: ${({ circledimeter }) => `${circledimeter}px`};
    height: ${({ circledimeter }) => `${circledimeter}px`};
    border-radius: 50%;
    background-color: ${({ active }) => (active ? '#083057' : '#A8C3DF')};
`

const BreadcrumbLeadLineWrapper = styled(Box)`
    display: flex;
    align-items: center;
    align-self: flex-start;
    padding-top: ${({ circledimeter }) => `${(circledimeter - 13) / 2}px`};
`

const BreadcrumbCaption = styled(Typography)`
    display: flex;
    justify-content: center;
    margin-top: 7px;
    text-align: center;
    font-size: 14px;
    color: ${({ active }) => (active ? '#083057' : '#A8C3DF')};
    white-space: nowrap;

    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`

export default BreadcrumbWrapper
