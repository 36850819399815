import { styled, ThemeProvider } from '@mui/material'
import ResponseCardSuccess from 'Components/Elements/SignUp/ResponseCardSuccess'
import ResponseCardError from 'Components/Elements/SignUp/ResponseCardError'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { MainTemplate } from 'Components/Template/MainTemplate'

const SignUpResponse = observer(props => {
    return (
      <ThemeProvider theme={MainTemplate}>
        <SignUpResponseWrapper>
            {props.type === 'success' && (
                <ResponseCardSuccess />
            )}
            {props.type === 'error' && (
                <ResponseCardError />
            )}
        </SignUpResponseWrapper>
      </ThemeProvider>
    )
})

const SignUpResponseWrapper = styled('div')`
    background-color: #d1d9e2;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 20px;

    @media screen and (min-width: 700px) {
        padding: 40px 70px 100px 70px;
    }
`

export default SignUpResponse