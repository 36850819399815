import { Box, Card, Skeleton, Stack, ThemeProvider, Typography, styled } from "@mui/material"
import { IconSpinner } from "Components/Elements/Spinner/IconSpinner"
import { MainTemplate } from "Components/Template/MainTemplate"
import { useTranslate } from "GlobalStores/Cores/Translator/useTranslate"
import { observer } from "mobx-react-lite"
import { ReactComponent as Layer1 } from '../../Assets/Layer_1.svg'
import storeFeelGreat from "GlobalStores/FeelGreat/StoreFeelGreat"
import StoreTranslate from "GlobalStores/Cores/Translator/StoreTranslate"
import storeSharingSocial from "GlobalStores/Referral/StoreSharingSocial"

const SharingSocial = observer(() => {
  const translate = useTranslate()

  const socialStoriesGuidelines = storeFeelGreat.contents.socialStoriesGuidelines

  return (
    <ThemeProvider theme={MainTemplate}>
      {socialStoriesGuidelines.isEnable &&
        <Container>
          {
            storeSharingSocial.loadingSharing ? (
              <SkeletonLoading />
            ) : (
              <>
                <Title>
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                      fontSize: {
                        xs: '24px',
                        md: '32px'
                      },
                      fontWeight: 700,
                      textAlign: 'left',
                      textWrap: ''
                    }}>
                    {translate('additional')}
                  </Typography>
                </Title>

                {
                  socialStoriesGuidelines.usageType === 'externalLink' ? <Box sx={{
                    marginTop: '20px',
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px !important',
                        fontWeight: 500,
                        color: '#003B6F',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        },
                        position: 'relative',
                      }}
                      component="a"
                      href={StoreTranslate.UseLanguage(socialStoriesGuidelines.externalLink)}
                      target="_blank"
                    >
                      {translate('sharing_social_stories_guidelines')}
                      <span style={{ position: 'absolute', paddingLeft: '5px' }}>
                        <Layer1 width={'16px'} height={'24px'} />
                      </span>
                    </Typography>
                  </Box> : (
                    <Box sx={{
                      marginTop: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '7px',
                      cursor: 'pointer'
                    }}
                    >
                      <Typography
                        fontSize={'16px'}
                        fontWeight={500}
                        color={'#003B6F'}
                        component={'div'}
                        dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(socialStoriesGuidelines.htmlContent) }}
                      />
                    </Box>
                  )
                }
              </>
            )
          }
        </Container>
      }

    </ThemeProvider >
  )
})

const SkeletonLoading = observer(() => {
  return (
    <Stack width={'auto'}>
      <Title>
        <Stack width="250px">
          <Typography variant="h2">
            <Skeleton animation="wave" />
          </Typography>
        </Stack>
      </Title>

      <FeelGreatSection>
        <Stack mt={'30px'} mb={'10px'} />
        <Typography variant="subtitle1" component="h3">
          <Skeleton animation="wave" width={'25%'} />
        </Typography>
        <Typography variant="subtitle1" component="p">
          <Skeleton animation="wave" width={'70%'} />
        </Typography>
        <Typography variant="h2">
          <Skeleton animation="wave" width={'90%'} />
        </Typography>
        <Stack mb={'20px'} />
      </FeelGreatSection>
    </Stack>
  )
})

const Spinner = styled(IconSpinner)`
  width: 25px;
  height: 25px;
`

const Container = styled(Card)`
  padding: 25px 15px;

  @media screen and (min-width: 768px) {
      padding: 25px 30px;
  }
`
const Title = styled(Stack)`
  color: #003b6f;
  /* padding: 0px 15px; */
`

const FeelGreatSection = styled(Stack)`
  padding: 0px 0px;

  @media screen and (min-width: 768px) {
      padding: 0px 0px;
  }
  @media screen and (max-width: 900px) {
      position: relative;
  }
`

export default SharingSocial