import { Card, CardContent, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Box } from '@mui/system'
import styled from '@emotion/styled'
import { DefaultButton } from '../Forms'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { useNavigate } from 'react-router-dom'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const GuideCard = observer(props => {
    const { width } = useSizeWindow()
    const navigate = useNavigate()
    const guide = StoreFeelGreat.GetContent('guilde')
    const guideButton = StoreFeelGreat.GetContent('guildeButton')

    return (
        <Card
            sx={{
                flex: 1,
                display: 'flex'
            }}>
            <CardContent style={{ padding: width < 600 ? '30px 20px' : '30px' }}>
                <StackStyled>
                    <Image src={guide.image} alt="eating" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <Typography
                            variant="h3"
                            sx={{
                                color: '#003764',
                                marginBottom: '12px',
                                color: '#0B3B61',
                                marginBottom: '20px'
                            }}>
                            {StoreTranslate.UseLanguage(guide.title)}
                        </Typography>
                        <Typography
                            variant="body3"
                            sx={{
                                fontSize: {
                                    xs: '14px'
                                },
                                lineHeight: '20px'
                            }}
                            dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(guide.description) }}
                        />

                        <Box
                            sx={{
                                width: '100%',
                                display: {
                                    xs: 'flex',
                                    md: 'flex'
                                },
                                justifyContent: {
                                    xs: 'center',
                                    sm: 'flex-start'
                                },
                                flex: 1
                            }}
                            mt="40px">
                            <Box
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '175px'
                                    }
                                }}>
                                <DefaultButton
                                    type="button"
                                    width={'167px'}
                                    titleText={StoreTranslate.UseLanguage(guideButton.title)}
                                    backgroundcolor="#5A8FC4"
                                    fontSize={16}
                                    height="36px"
                                    onClick={() => {
                                        const isExternal = guideButton.usageType === 'externalLink'

                                        const url = isExternal
                                            ? StoreTranslate.UseLanguage(guideButton.externalLink)
                                            : StoreTranslate.UseLanguage(guideButton.path)

                                        if (isExternal) {
                                            window.open(url)
                                            return
                                        }

                                        navigate(url)
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </StackStyled>
            </CardContent>
        </Card>
    )
})

const StackStyled = styled.div`
    gap: 30px;

    @media screen and (min-width: 600px) {
        height: 100%;
        display: grid;
        grid-template-columns: 50% auto;
    }
    @media screen and (min-width: 1200px) {
        height: 100%;
        display: grid;
        grid-template-columns: 60% auto;
    }
`

const Image = styled.img`
    width: 100%;
    margin-bottom: 20px;
    @media screen and (min-width: 600px) {
        width: inherit;
        margin-bottom: inherit;
    }
`

export default GuideCard
