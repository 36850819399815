import { formToJSON } from "Helpers/GlobalHelpers";
import { useLayoutEffect } from "react";

const PaymentForm = ({ dataPayment }) => {

    useLayoutEffect(() => {

        const elements = document.getElementById('formMarPayment').elements
        let formJson = formToJSON(elements)
        delete formJson['']

        document.getElementById('formMarPayment').submit() 
    }, [])

    return <>
        <form method="POST" name="formMarPayment" id="formMarPayment" className="form" action={dataPayment.actionUrl}>
            {Object.keys(dataPayment).map(key => {
                if (key === 'actionUrl') return;

                return <input type="hidden" name={key} value={dataPayment[key]} key={key} />
            })}
        </form>
    </>
}

export default PaymentForm