import { ThemeProvider } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'
import MaintenanceElem from 'Components/Elements/Maintenance/Maintenance'

const SignUp = observer(() => {

    return (
        <ThemeProvider theme={MainTemplate}>
            <MaintenanceElem />
        </ThemeProvider>
    )
})

export default SignUp
