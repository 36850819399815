import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { DefaultButton } from 'Components/Elements/Forms'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useNavigate } from 'react-router-dom'

const CartEmpty = () => {
    const translate = useTranslate()
    const navigate = useNavigate()

    return (
        <Stack pt={'40px'} alignItems={'center'}>
            <Card
                sx={{
                    width: {
                        xs: '100%',
                        sm: '540px',
                        md: 770
                    }
                }}>
                <CardContent style={{ padding: 60 }}>
                    <Stack alignItems={'center'} display="flex" width={'100%'}>
                        <img src={`${process.env.PUBLIC_URL}/Assets/icons/lemon.svg`} width={89} height={88} alt="empty cart" />
                        <Typography variant="h3" mt="30px">
                            <T>empty_cart_title</T>
                        </Typography>
                        <Typography variant="body3" mt="30px" textAlign={'center'} width={'420px'}>
                            <T>empty_cart_description</T>
                        </Typography>
                        <Box maxWidth={'246px'} minWidth={'246px'} mt="30px">
                            <DefaultButton titleText={<T>buy_now</T>} onClick={() => navigate('/products')} style={{ background: '#153862' }} />
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    )
}

export default CartEmpty
