import React, { useState } from 'react'
import { Stack, Typography, Box, styled, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import StoreProducts from 'GlobalStores/StoreProducts'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { DefaultButton } from 'Components/Elements/Forms'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import ShowError from './ShowError'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { getOSName } from 'Helpers/Bowser'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { ReactComponent as PlusIcon } from '../Assets/plus.svg'
import { ReactComponent as MinusIcon } from '../Assets/minus.svg'
import { useEffect } from 'react'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'

const ChangeFlavor = observer(props => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [listFlavor, setListFlavor] = useState([])

    const translate = useTranslate()

    const purchaseType = 'subscribe'

    useEffect(() => {
        const getFlavorData = () => {
            const flavors = StoreProducts.GetFlavors()
            const data = flavors.map(flavor => {
                const product = StoreProducts.GetProduct(flavor.value, purchaseType)
                const price = StoreCheckout.HasDiscount() ? product.price.member : product.price.subscription

                return {
                    ...flavor,
                    item_code: product.item_code,
                    qty: 0,
                    price
                }
            })
            return data
        }

        const data = getFlavorData()

        const getFlavorQTY = props.flavorQTY

        const filterData = data.map(item => {
            const newData = getFlavorQTY.filter(filter => filter.item_code === item.item_code)

            return {
                ...item,
                qty: newData[0]?.qty === undefined ? 0 : newData[0]?.qty
            }
        })

        setListFlavor(filterData)

        let newPrice = 0

        filterData.forEach(element => {
            newPrice += element.price * element.qty
        })

        StoreSubScriptions.totalPrice = newPrice
    }, [])

    const order = props.products

    const handleSubmit = async () => {
        setLoading(true)
        setErrorMessage([])
        try {
            const productItems = listFlavor.filter(item => item.qty !== 0)
            const newList = productItems.map(item => {
                return {
                    item_code: item.item_code,
                    qty: item.qty,
                    price: item.price
                }
            })

            let recurrence = order.recurrence
            if (order.recurrence.dateStarts) {
                recurrence.schedule.dateStarts = order.recurrence.dateStarts
                delete recurrence.dateStarts
            }

            const newData = {
                autoship_href: order.href,
                autoship_number: order.id.unicity.toString(),
                ba_id: order.customer.id.unicity.toString(),
                market: order.market,
                items: JSON.stringify(newList),
                shippingMethod_href: order.shippingMethod.href,
                shipToAddress: order.shipToAddress,
                shipToName: order.shipToName,
                shipToPhone: order.shipToPhone,
                shipToEmail: order.shipToEmail,
                shipment_options: 'delivery',
                recurrence: recurrence,
                transactions: order.transactions,
                medium: 'Internet',
                agent: 'uFeelGreat',
                platform: getOSName()
            }

            const res = await FeelGreatAPI.EditSubscriptions(newData, StoreAuth.GetToken())

            if (res.data.success) {
                props.handleClose()
                props.handleGetOrder()
            } else {
                throw new Error(res.data.error.message)
            }
        } catch (error) {
            console.error(error)
            if (error.response.status === 504) {
                setErrorMessage([error.response.data.message])
            } else {
                setErrorMessage([error.response.data.message.error.message])
            }
        } finally {
            setLoading(false)
        }
    }

    const checkDisabled = item => {
        if (item[0]?.qty === 0 && item[1]?.qty === 0 && item[2]?.qty === 0) {
            return true
        } else {
            return false
        }
    }

    return (
        <Stack gap="30px">
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography variant="title2">
                    <T>fg_unimate_flavor</T>
                </Typography>
                <Typography variant="title2">
                    <T>fg_quantity</T>
                </Typography>
            </Stack>

            <Stack gap="20px">
                {listFlavor.map((item, index) => {
                    return (
                        <SelectedFlavor
                            key={index}
                            title={StoreTranslate.UseLanguage(item.text)}
                            selected={StoreSubScriptions.GetSelectedFlavor() === item.value}
                            value={item.value}
                            id={index}
                            data={item}
                            check={item.check}
                            setListFlavor={setListFlavor}
                            qty={item.qty}
                            setErrorMessage={setErrorMessage}
                            listFlavor={listFlavor}
                        />
                    )
                })}
            </Stack>

            {errorMessage.length > 0 && <ShowError list={errorMessage} noTranslate={true} />}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <Box onClick={() => props.handleClose()}>
                    <Typography variant="link" color="#000000" fontSize="16px">
                        <T>cancel</T>
                    </Typography>
                </Box>
                <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('save')}
                    width="220px"
                    onClick={() => handleSubmit()}
                    isloading={loading.toString()}
                    fontSize="20px"
                    fontWeight="400"
                    disabled={checkDisabled(listFlavor)}
                />
            </Box>
        </Stack>
    )
})

const SelectedFlavor = observer(props => {
    const translate = useTranslate()

    const { setListFlavor, data, title, qty, setErrorMessage, listFlavor } = props

    const { cart_limit_qty, cart_limit_price } = StoreSubScriptions.orderSpec

    const handleDecrement = item => {
        if (item.qty === 0) return

        setErrorMessage([])
        const totalPrice = StoreSubScriptions.totalPrice - item.price

        setListFlavor(prevState => {
            const updatedList = prevState.map(obj => {
                if (obj.value === item.value) {
                    return { ...obj, qty: Math.max(item.qty - 1, 0) }
                }

                return obj
            })

            return updatedList
        })

        StoreSubScriptions.totalPrice = totalPrice
    }

    const handleIncrement = item => {
        const totalPrice = StoreSubScriptions.totalPrice + item.price
        const cartLimitPrice = Number(StoreTranslate.UseLanguage(cart_limit_price))
        const cartLimitQty = Number(StoreTranslate.UseLanguage(cart_limit_qty))

        if (item.qty < cartLimitQty) {
            if (totalPrice <= cartLimitPrice) {
                const updatedList = updateListFlavor(item)

                setListFlavor(updatedList)
            } else {
                const textError = `${translate('total_price_limit')} ${StoreCountry.GetCurrencySymbol()}${cartLimitPrice}`

                setErrorMessage([textError])

                return
            }

            StoreSubScriptions.totalPrice = totalPrice
        } else {
            const textError = `${translate('qty_limit')} ${cartLimitQty}`
            setErrorMessage([textError])
        }
    }

    const updateListFlavor = item => {
        return listFlavor.map(obj => {
            if (obj.value === item.value) {
                return { ...obj, qty: item.qty + 1 }
            }

            return obj
        })
    }

    const CustomButton = {
        '&:hover': {
            boxShadow: 'none',
            background: '#e9f1fa'
        },
        '&:active': {
            boxShadow: 'none',
            background: '#e9f1fa'
        },

        '&.MuiButtonBase-root': {
            padding: '0'
        }
    }

    return (
        <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
            <BoxSelected
                sx={{
                    border: qty > 0 ? '1px solid #6687A2' : '1px solid #e5eef7'
                }}>
                <Typography variant="caption" color={qty > 0 ? '#153862' : '#6687A2'} fontWeight={qty > 0 ? 600 : ''}>
                    {title}
                </Typography>
            </BoxSelected>

            <BoxQTY sx={CustomButton}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        height: '100%',
                        width: '30px',
                        justifyContent: 'center'
                    }}
                    onClick={() => handleDecrement(data)}>
                    <MinusIcon height="3px" />
                </Box>

                <Box
                    sx={{
                        width: '30px',
                        textAlign: 'center'
                    }}>
                    <Typography variant="caption" color={'#153862'} fontWeight={600}>
                        {qty}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        height: '100%',
                        width: '30px',
                        justifyContent: 'center'
                    }}
                    onClick={() => handleIncrement(data)}>
                    <PlusIcon />
                </Box>
            </BoxQTY>
        </Stack>
    )
})

const BoxSelected = styled(Box)`
    width: 215px;
    height: 50px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 428px) {
        width: 180px;
    }
`

const BoxQTY = styled(Button)`
    width: 95px;
    height: 35px;
    border-radius: 20px;
    background: #e9f1fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export default ChangeFlavor
