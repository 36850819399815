import { observer } from 'mobx-react-lite'
import DefaultButton from './DefaultButton'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Stack, ThemeProvider, Typography, useMediaQuery } from '@mui/material'
import Login from '../Login/Login'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { autorun, reaction } from 'mobx'
import storeAuth from 'GlobalStores/User/StoreAuth'

const ButtonWithPopup = observer(props => {
    const translate = useTranslate()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    const onClick = () => {
        if (props.callback) {
            props.callback()
        }
        storeCheckout.showLoginModal = true
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <DefaultButton
                titleText={props.titleText}
                width={'270px'}
                disabled={props.loading || storeCheckout.CheckOutofStockSelectedItem()}
                onClick={onClick}
                backgroundcolor={props.backgroundcolor}
                isloading={props.loading}
            />
            <Stack>
                <Dialog open={storeCheckout.showLoginModal} onClose={() => (storeCheckout.showLoginModal = false)} fullScreen={matches}>
                    <DialogContent>
                        <Login
                            showFooter={false}
                            setOpenLoginModal={() => (storeCheckout.showLoginModal = false)}
                            callback={props.callback}
                            onCheckout={props.onCheckout}
                        />
                        <Stack textAlign={'center'}>
                            <Typography variant="subtitle1">{translate('jp_no_login_no_referral_msg')}</Typography>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', borderTop: '1px solid rgba(153, 188, 223, 0.25)' }}>
                        <Box pt="20px" pb="20px" sx={{ cursor: 'pointer', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                variant="link2"
                                component={'a'}
                                href="https://www.unicity.com/jpn/infoorder/"
                                target="_blank"
                                sx={{ fontWeight: 400, textDecoration: 'none' }}>
                                {translate('contact_us')}
                            </Typography>
                        </Box>
                    </DialogActions>
                </Dialog>
            </Stack>
        </ThemeProvider>
    )
})

export default ButtonWithPopup
