import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'

const LandingBoxWrapper = observer(props => {
    return (
        <LandingBoxWrapperStyled className="landing-box-content-wrapper" {...props}>
            {props.children}
        </LandingBoxWrapperStyled>
    )
})

const LandingBoxWrapperStyled = styled(Stack)`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (min-width: 1024px) {
        padding: 0;
    }
`

export default LandingBoxWrapper
