import { useEffect, useRef, useState } from 'react'

export function useInterval(callback, delay, kill = false) {
    const savedCallback = useRef()
    const [intervalIdRef, setIntervalIdRef] = useState(null)

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (kill) {
            savedCallback.current = () => {}
            clearInterval(intervalIdRef)
        }
    }, [kill, intervalIdRef])

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay !== null) {
            let id = setInterval(tick, delay)
            setIntervalIdRef(id)
            return () => clearInterval(id)
        }
    }, [delay])
}
