import { Box, Card, CardContent, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'
import { ReactComponent as IconCopy } from './Assets/copy.svg'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { useEffect, useState } from 'react'
import copy from 'copy-to-clipboard'
import { DefaultButton } from '../Forms'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import Loader from '../Invite/Assets/Loader.svg'
import { runInAction } from 'mobx'

const ReferCard = observer(props => {
    const translate = useTranslate()
    const [isCopied, setIsCopied] = useState()
    const { width } = useSizeWindow()

    const [activeButton, setActiveButton] = useState('referral_link')
    const [isloading, setIsLoading] = useState(false)

    const CopyToClipboard = () => {
        if (!StoreAuth.GetId() && props.states.ba_id.length === 0) return
        if (!StoreReferral.loadingReferCode) {
            if (activeButton === 'referral_link') {
                copy(`https://ufeelgreat.com/c/${StoreReferral.referralCode}`)
                // copy(`https://ufeelgreat.com/c/${StoreAuth.GetId()}`)
            } else {
                copy(StoreReferral.referralCode)
            }

            setIsCopied(true)
            setTimeout(() => {
                setIsCopied(false)
            }, 5000)
        }
    }

    useEffect(() => {
        if (StoreReferral.loadingReferCode === false) {
            StoreReferral.loadingReferCode = true
            if (props.states.referral_code.length === 0) {
                StoreReferral.GetReferralCode(StoreAuth.GetToken())
            } else {
                runInAction(() => {
                    StoreReferral.loadingReferCode = false
                })
            }
        }
    }, [])

    useEffect(() => {
        if (activeButton === 'referral_code' && StoreReferral.referralCode.length === 0) {
            try {
                if (props.states.referral_code.length === 0) {
                    StoreReferral.GetReferralCode(StoreAuth.GetToken())
                }
            } catch (e) {
                console.log(e)
            }
        }
    }, [activeButton])

    return (
        <Card
            sx={{
                flex: 1
            }}>
            <CardContent
                sx={{
                    padding: width < 600 ? '30px 20px' : '30px !important'
                }}>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: '25px',
                        lineHeight: '32px'
                    }}>
                    <T>fg_refer_friend</T>
                </Typography>

                <Toggler>
                    <button
                        className={`${activeButton === 'referral_link' ? 'active' : ''}`}
                        onClick={() => setActiveButton('referral_link')}
                        style={{ fontFamily: 'Poppins, NotoSansThai' }}>
                        <T>referral_link</T>
                    </button>
                    <button
                        className={`${activeButton === 'referral_code' ? 'active' : ''}`}
                        onClick={() => setActiveButton('referral_code')}
                        style={{ fontFamily: 'Poppins, NotoSansThai' }}>
                        <T>referral_code</T>
                    </button>
                </Toggler>

                {activeButton === 'referral_link' ? (
                    <Box sx={{ maxWidth: '100%' }} mt="30px">
                        <div style={{ marginBottom: '13px' }}>
                            <label style={{ paddingLeft: 16 }}>
                                <DefaultLabelTypo variant="label">
                                    <T>your_referral_link</T>
                                </DefaultLabelTypo>
                            </label>
                        </div>
                        <UniqueLinkStyled onClick={CopyToClipboard}>
                            <div className="feelgreat--share-box">
                                {StoreReferral.loadingReferCode ? (
                                    <Typography
                                        component="div"
                                        fontWeight={400}
                                        sx={{
                                            color: '#003B6F',
                                            // fontFamily: 'Prompt',
                                            size: '14px'
                                        }}>
                                        &nbsp;
                                    </Typography>
                                ) : (
                                    <Typography
                                        component="div"
                                        sx={{
                                            color: '#003B6F',
                                            // fontFamily: 'Prompt',
                                            size: '14px',
                                            fontWeight: '400 !important'
                                        }}>
                                        https://ufeelgreat.com/c/{StoreReferral.referralCode}
                                    </Typography>
                                )}
                            </div>
                            <div className="">
                                <IconCopy />
                            </div>
                        </UniqueLinkStyled>

                        {isCopied ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginTop: '10px',
                                    marginRight: '10px'
                                }}>
                                <Typography variant="label" sx={{ color: '#6FCF97', fontSize: '12px' }}>
                                    <T>copied_to_clipboard</T>
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                ) : (
                    <>
                        <Box sx={{ maxWidth: '100%' }} mt="30px">
                            <div style={{ marginBottom: '13px' }}>
                                <label style={{ paddingLeft: 16 }}>
                                    <DefaultLabelTypo variant="label">
                                        <T>your_referral_code</T>:
                                    </DefaultLabelTypo>
                                </label>
                            </div>
                            <UniqueLinkStyled onClick={CopyToClipboard}>
                                <div className="feelgreat--share-box">
                                    {StoreReferral.loadingReferCode ? (
                                        <Typography
                                            variant="caption"
                                            fontWeight={400}
                                            sx={{
                                                color: '#003B6F',
                                                // fontFamily: 'Prompt',
                                                size: '14px'
                                            }}>
                                            &nbsp;
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="caption"
                                            fontWeight={400}
                                            component={'p'}
                                            sx={{
                                                color: '#003B6F',
                                                // fontFamily: 'Prompt',
                                                size: '14px'
                                            }}>
                                            {StoreReferral.referralCode}
                                        </Typography>
                                    )}
                                </div>
                                <div className="">{StoreReferral.loadingReferCode ? <Spiner src={Loader} alt="Loading" /> : <IconCopy />}</div>
                            </UniqueLinkStyled>

                            {isCopied ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        marginTop: '10px',
                                        marginRight: '10px'
                                    }}>
                                    <Typography variant="label" sx={{ color: '#6FCF97', fontSize: '12px' }}>
                                        <T>copied_to_clipboard</T>
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                    </>
                )}

                <Box
                    sx={{
                        width: '100%',
                        display: {
                            xs: 'flex',
                            md: 'block'
                        },
                        justifyContent: {
                            xs: 'center',
                            sm: 'flex-start'
                        },
                        boxSizing: 'border-box'
                    }}
                    mt="30px">
                    <Box
                        sx={{
                            width: 'auto',
                            minWidth: {
                                xs: '100%',
                                sm: '175px'
                            }
                        }}>
                        <DefaultButton
                            type="button"
                            onClick={CopyToClipboard}
                            width={'auto'}
                            minWidth={'161px'}
                            titleText={activeButton === 'referral_code' ? translate('copy_code') : translate('copy_link')}
                            backgroundcolor="#003764"
                            fontSize={16}
                            height="36px"
                            sx={{
                                paddingLeft: '25px',
                                paddingRight: '25px'
                            }}
                        />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
})

const UniqueLinkStyled = styled(Box)`
    box-sizing: border-box;
    margin: 0 !important;
    border: 1px solid rgba(0, 55, 100, 0.1);
    border-radius: 8px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;

    &.feelgreate--share-box-saved {
        background: #f1faf7;
        border: 1px solid rgba(0, 55, 100, 0.1);
    }

    .feelgreat--share-box {
        div {
            &:first-of-type {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                color: #33628c;
            }
            &:last-of-type {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #33628c;
                font-weight: bold;
            }
        }
    }
    .icon-box {
        width: 25px;
        height: 25px;
        svg {
            circle {
                stroke: #33628c;
            }
            path {
                stroke: #33628c;
            }
        }
    }
`

const DefaultLabelTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Inter, NotoSansThai',
    fontStyle: 'normal',
    fontSize: 12,
    color: 'rgba(21, 56, 98, 0.5)',
    fontWeight: theme?.typography?.label?.fontWeight || 400
}))

const Toggler = styled(Stack)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    padding: 3px;
    border: 1px solid rgba(11, 59, 97, 0.15);
    border-radius: 6px;
    margin-top: 30px;

    button {
        background: transparent;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: none;
        outline: none;
        height: 100%;
        font-family: Inter, Arial, Sans-Serif;
        font-weight: 600;
        font-size: 14px;
        color: #003764;
        padding: 0;
        cursor: pointer;
        padding: 7px 10px;

        &.active {
            background: rgba(0, 55, 100, 0.1);
        }
    }
`

const Spiner = styled('img')({
    marginTop: '5px',
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

export default ReferCard
