import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import httpHelper from 'Services/HttpHelper'
import axios from 'axios'

export const GetReferralCode = token => {
    const url = 'https://hydra.unicity.net/v5a/customers/me/referralcodes'

    return axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-Application': `asia.fg.${storeCountry.Country2().toLowerCase()}`
        }
    })
}

export const validateEnrollSponser = (market, enrollerID, sponsorID) => {
    const url = `${httpHelper.Proxy({ originalUrl: 'https://member-calls2.unicity.com/unishop-fn-misc/validator/enroller_sponsor' })}`

    return httpHelper.Post({
        url: url,
        data: {
            market: market,
            enrollerId: enrollerID,
            sponsorId: sponsorID
        },
        config: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    })
}
