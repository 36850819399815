import { lowerCase } from 'lodash'
import ReactGA from 'react-ga4'
import { makeAutoObservable } from 'mobx'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
class TrackingEvents {
    get trackingPages() {
        return [
            'menu',
            'commission',
            'orderhistory',
            'seminars',
            'bmi',
            'marketing-artwork',
            'digital-member-card',
            'settings',
            'genealogy/table',
            'genealogy/dashboard',
            'genealogy/card',
            'genealogy/level',
            'shopping',
            'catproduct/all',
            'feedback',
            'cart',
            'checkout',
            'ordersummary',
            'checkout/summary',
            'checkout/payment-response/success',
            'checkout/payment-response/failed',
            'reports',
            'something-went-wrong'
        ]
    }

    CommitToGoogleAnalytic(label, category, action, fullCountryName, interAction) {
        const MeasurementIDAll = StoreCountry.GA4MeasurementID.allmarket
        const eachMarket = StoreCountry.GA4MeasurementID[lowerCase(StoreCountry.CountryCapitalize())]
        const marketName = lowerCase(StoreCountry.CountryCapitalize())
        const trackings = [
            {
                trackingId: MeasurementIDAll,
                gaOptions: {
                    name: 'USHOP-FEELGREAT (ALL MARKET)'
                }
            },
            {
                trackingId: eachMarket,
                gaOptions: {
                    name: marketName
                }
            }
        ]
        ReactGA.initialize(trackings)
    }

    // Ecommerce
    CommitECAction(action, actionType, payload) {
        ReactGA.plugin.execute('ec', action, actionType, payload)
        ReactGA.plugin.execute('ec', 'send')
    }
    // Ecommerce Clear
    EcommerceClear(page) {
        ReactGA.pageview(page)
        ReactGA.plugin.execute('ec', 'clear')
    }

    PurchaseEvent(params) {
        ReactGA.event('purchase', params)
    }

    ViewItems(eventType) {
        ReactGA.event('view_item', eventType)
    }

    AddToCart(eventType, param) {
        ReactGA.event('add_to_cart', eventType, param)
    }

    PurchaseEvent(params) {
        ReactGA.event('purchase', params)
        ReactGA.gtag('purchase', params.eventType)
    }

    BeginCheckout(eventType, items, value, referalId = 'none', event_id) {
        const params = {
            ecommerce: {
                currency: StoreCountry.GetCurrencyCode(),
                value: value,
                items: items
            },
            event_id: event_id,
            referal_id: referalId
        }
        ReactGA.gtag('event', eventType, params)

        ReactGA.gtag('begin_checkout', eventType, params)
        ReactGA.event('begin_checkout', eventType ,params)
    }

    Commit(fullCountryName) {
        const MeasurementIDAll = StoreCountry.GA4MeasurementID.allmarket
        const eachMarket = StoreCountry.GA4MeasurementID[fullCountryName]
        const marketName = fullCountryName
        const trackings = [
            {
                trackingId: MeasurementIDAll,
                gaOptions: {
                    name: 'USHOP-FEELGREAT (ALL MARKET)'
                }
            },
            {
                trackingId: eachMarket,
                gaOptions: {
                    name: marketName
                }
            }
        ]

        ReactGA.initialize(trackings)
    }

    get CATEGORY() {
        return {
            PAGE_VIEW: 'Page View',
            EXTERNAL_LINK: 'External Link'
        }
    }

    get ACTION() {
        return {
            CLICK: 'click',
            VIEW: 'view',
            MOBILE_CLICK: 'click via mobile',
            MOBILE_VIEW: 'view on mobile',
            SETACTION: 'setAction'
        }
    }
    get ACTION_TYPE() {
        return {
            ADD_PRODUCT: 'addProduct',
            REMOVE_PRODUCT: 'removeProduct',
            CHECKOUT: 'checkout',
            CLEAR_CART: 'clearcart'
        }
    }

    get EVENT_TYPE() {
        return {
            ADD_ITEM: 'ADD_ITEM',
            REMOVE_ITEM: 'REMOVE_ITEM',
            PURCHASE: 'PURCHASE',
            CLEAR_CART: 'CLEAR_CART',
            VIEW_ITEM: 'VIEW_ITEM',
            ADD_TO_CART: 'ADD_TO_CART',
            BEGIN_CHECKOUT: 'BEGIN_CHECKOUT',
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}
const trackingEvents = new TrackingEvents()
export default trackingEvents
