import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import axios from 'axios'

import { Buffer } from 'buffer'
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material'
import httpHelper from 'Services/HttpHelper'
import moment from 'moment'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

const Poc = observer(() => {
    const [selectedCountry, setSelectedCountry] = useState('JP')
    const [selectedOption, setSelectedOption] = useState('https://hydraqa.unicity.net/v5a-test') // Initialize with an empty string or a default value
    const [shiptoEmail, setShiptoEmail] = useState('it-exp@unicity.com') // Initialize with an empty string or a default value

    const [userData, setUserData] = useState({
        username: '',
        password: '',
        token: null
    })

    const [addressData, setAddressData] = useState({
        FirstName: '',
        LastName: '',
        Zipcode: '',
        Prefecture: '',
        District: '',
        HouseNumber: '',
        Phone: ''
    })

    const [formData, setFormData] = useState({
        name: 'TESTER',
        number: '5200000000001096',
        expiry: '2025-12',
        cvc: '123'
    })

    const [ddcLoading, setDDCLoading] = useState(false)
    const [orderLoading, setOrderLoading] = useState(false)
    const [loginLoading, setLoginLoading] = useState(false)

    const [ddcHTML, setDdcHTML] = useState(null)
    const [challengeHTML, setChallengeHTML] = useState(null)
    const [iouReceiptBodyHTML, setIouReceiptBodyHTML] = useState(null)
    const [iouReceiptDataHTML, setIouReceiptDataHTML] = useState(null)
    const [sessionId, setSessionId] = useState(null)

    const [countPathChange, setCountPathChange] = useState(0)

    const [frameElement, setFrameElement] = useState(null)

    const [requestDCCRes, setRequestDCCRes] = useState(null)
    const [createOrderRes, setCreateOrderRes] = useState(null)

    const handleFrameElement = useCallback(e => {
        setFrameElement(e.target)
    }, [])

    const handleInputChange = event => {
        const { name, value } = event.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleLoginFormChange = event => {
        const { name, value } = event.target
        setUserData({
            ...userData,
            [name]: value
        })
    }

    const handleAddressFormChange = event => {
        const { name, value } = event.target
        setAddressData({
            ...addressData,
            [name]: value
        })
    }

    const handleChange = event => {
        setSelectedOption(event.target.value)
    }

    useLayoutEffect(() => {
        try {
            let headerElem = document.getElementById('header')
            headerElem.parentNode.removeChild(headerElem)
            let footerElem = document.getElementById('fg_footer')
            footerElem.parentNode.removeChild(footerElem)
        } catch (error) {
            console.log('Just remove parent Tag ERROR', error)
        }
    }, [])

    useEffect(() => {
        console.log('HOOK ', frameElement?.contentDocument?.body?.innerHTML?.length)
    }, [frameElement])

    useEffect(() => {
        console.log('countPathChange ', countPathChange)
    }, [countPathChange])

    const login = (username, password, timeout = 0) => {
        let data = {
            type: 'base64',
            value: Buffer.from(`${username}:${password}`).toString('base64'),
            namespace: `${selectedOption.replace('.com', '.net')}/customers`
        }
        let MEMBERCALL = selectedOption === 'https://hydraqa.unicity.net/v5a-test' ? 'member-calls2-dev' : 'member-calls2'
        let url = `https://${MEMBERCALL}.unicity.com/etlV2/loginTokens?ushopCountryCode=JPN&platform=feelgreat`

        if (MEMBERCALL === 'member-calls2-dev') {
            url += '&isQa=1'
        }
        return axios.post(httpHelper.Proxy({ originalUrl: url, forceProxy: true }), data, {
            headers: {
                'Content-Type': 'application/json',
                'X-Application': `asia.fg.${storeCountry.Country2().toLowerCase()}`
            }
        })
    }

    let _handleLogin = async () => {
        try {
            setLoginLoading(true)
            let loginRes = await login(userData.username, userData.password)
            if (loginRes.status === 200) {
                handleLoginFormChange({ target: { name: 'token', value: loginRes.data.tokenHydra } })
                setLoginLoading(false)
            }
        } catch (error) {
            console.log('Login Faild', error)
            setLoginLoading(false)
        }
    }

    let _getWorldPayDeviceDataCollection = async () => {
        try {
            setRequestDCCRes(null)
            setDDCLoading(true)
            const { data, status } = await FeelGreatAPI.HydraWorldPayReqForm(selectedOption, formData.number)
            if (status === 200) {
                const cleanedHtml = data.ddcHTML.replace(/\r\n/g, '')
                setDdcHTML(cleanedHtml)
                setSessionId(data.sessionId)
            }
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    setDDCLoading(false)
                }, 5000)
            })
        } catch (error) {
            console.log('_getWorldPayDeviceDataCollection Error ', error)
            setDDCLoading(false)
            setRequestDCCRes(error.response)
        }
    }

    let _testSentFormat = async () => {
        let data = {}
        setCreateOrderRes(null)
        if (selectedOption === 'https://hydraqa.unicity.net/v5a-test') {
            data = JSON.stringify({
                lines: {
                    items: [
                        {
                            item: {
                                href: `${selectedOption}/items?id.unicity=${selectedCountry === 'DE' ? '34082' : '35571'}`
                            },
                            quantity: 1
                        }
                    ]
                },
                shipToName: {
                    firstName: addressData.FirstName,
                    lastName: addressData.LastName
                },
                shipToEmail: shiptoEmail,
                shipToPhone: addressData.Phone,
                shipToAddress: {
                    address1: `${addressData.HouseNumber} ${addressData.District} ${addressData.Prefecture}`,
                    address2: ``,
                    city: ``,
                    country: selectedCountry,
                    state: ``,
                    zip: `${addressData.Zipcode}`
                },
                shippingMethod: {
                    href: `${selectedOption}/shippingmethods?type=Economy`
                },
                source: {
                    medium: 'Internet',
                    agent: 'uFeelGreat',
                    platform: 'macOS'
                },
                terms: {
                    period: `${moment().format('YYYY-MM')}`
                },
                transactions: {
                    items: [
                        {
                            type: 'IOU',
                            method: 'CreditCard',
                            amount: 'this.terms.total',
                            methodDetails: {
                                payer: formData.name,
                                creditCardNumber: formData.number,
                                creditCardExpires: formData.expiry,
                                creditCardSecurityCode: formData.cvc,
                                languageDialect: 'en-US',
                                challengeWindowSize: 'fullPage',
                                sessionId: sessionId,
                                bankName: 'WorldPay'
                            },
                            billToAddress: {
                                country: selectedCountry
                            },
                            billToEmail: shiptoEmail
                        }
                    ]
                }
            })
        } else {
            data = JSON.stringify({
                market: selectedCountry,
                shipToEmail: shiptoEmail,
                shipToPhone: addressData.Phone,
                shipToName: {
                    firstName: addressData.FirstName,
                    lastName: addressData.LastName
                },
                shipToAddress: {
                    address1: `${addressData.HouseNumber} ${addressData.District} ${addressData.Prefecture}`,
                    address2: ``,
                    city: ``,
                    country: selectedCountry,
                    state: ``,
                    zip: `${addressData.Zipcode}`
                },
                shippingMethod: {
                    href: `${selectedOption}/shippingmethods?type=Economy`
                },
                terms: {
                    period: `${moment().format('YYYY-MM')}`
                },
                lines: {
                    items: [
                        {
                            item: {
                                href: `${selectedOption}/items?id.unicity=${selectedCountry === 'DE' ? '34082' : '35571'}`
                            },
                            quantity: 1
                        }
                    ]
                },
                source: {
                    agent: 'uFeelGreat',
                    medium: 'Internet',
                    platform: 'macOS'
                },
                notes: '90fd1880-f4fc-436e-94bb-13c7c7f22660|800008467|https://fg-dev.unicity.com',
                transactions: {
                    items: [
                        {
                            type: 'IOU',
                            method: 'CreditCard',
                            amount: 'this.terms.total',
                            methodDetails: {
                                payer: formData.name,
                                creditCardNumber: formData.number,
                                creditCardExpires: formData.expiry,
                                creditCardSecurityCode: formData.cvc,
                                languageDialect: 'en-US',
                                challengeWindowSize: 'fullPage',
                                sessionId: sessionId,
                                bankName: 'WorldPay'
                            },
                            billToAddress: {
                                country: selectedCountry
                            },
                            billToEmail: shiptoEmail
                        }
                    ]
                }
            })
        }

        let config = {
            method: 'post',
            url: `${selectedOption}/customers/me/orders`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: userData.token,
                'X-Application': `asia.fg.${storeCountry.Country2().toLowerCase()}`
            },
            data: data
        }
        setOrderLoading(true)

        axios
            .request(config)
            .then(response => {
                let res = response.data
                setCreateOrderRes(res)
                if (res.transactions.items.length) {
                    let methodDetails = res.transactions.items[0].methodDetails

                    setChallengeHTML(methodDetails.challengeHTML.replace(/\r\n/g, ''))
                    setIouReceiptBodyHTML(methodDetails.iouReceipt.body.replace(/\r\n/g, ''))
                    setIouReceiptDataHTML(methodDetails.iouReceipt.data.replace(/\r\n/g, ''))

                    setOrderLoading(false)
                }
            })
            .catch(error => {
                console.log(error)
                setCreateOrderRes(error)
                setOrderLoading(false)
            })
    }

    return (
        <Box>
            <Container maxWidth="sm">
                <Box sx={{ p: '20px 10px', height: '100vh' }}>
                    <Typography variant="h3">Prove World Pay (JAPAN)</Typography>

                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" sx={{ width: '100%', marginBottom: '30px' }}>
                                        <FormLabel>SELECT Country</FormLabel>
                                        <Select
                                            value={selectedCountry}
                                            onChange={e => {
                                                setSelectedCountry(e.target.value)
                                            }}
                                            sx={{ width: '100%' }}>
                                            <MenuItem value="JP">USE JAPAN DATA</MenuItem>
                                            <MenuItem value="DE">USE GERMAN DATA</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                                        <FormLabel>SELECT ENV HOST</FormLabel>
                                        <Select value={selectedOption} onChange={handleChange} sx={{ width: '100%' }}>
                                            <MenuItem value="https://hydraqa.unicity.net/v5a-test">hydraqa.unicity.net/v5a-test</MenuItem>
                                            <MenuItem value="https://hydra.unicity.net/v5a">hydra.unicity.net/v5a</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                                        <FormLabel>username</FormLabel>
                                        <TextField
                                            fullWidth
                                            name="username"
                                            placeholder="108357166"
                                            value={userData.username}
                                            onChange={handleLoginFormChange}
                                        />
                                    </FormControl>

                                    <FormControl variant="outlined" sx={{ width: '100%', marginBottom: '20px' }}>
                                        <FormLabel>password</FormLabel>
                                        <TextField fullWidth name="password" type="password" value={userData.password} onChange={handleLoginFormChange} />
                                    </FormControl>

                                    <Box sx={{ margin: '0px 0px 20px 0px', width: '100%' }}>
                                        <Button
                                            disabled={userData.token || loginLoading}
                                            type="button"
                                            variant="contained"
                                            onClick={() => {
                                                _handleLogin()
                                            }}
                                            sx={{ width: '100%' }}>
                                            {loginLoading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
                                        </Button>
                                    </Box>

                                    <FormControl variant="outlined" sx={{ width: '100%', marginBottom: '20px' }}>
                                        <FormLabel>SHIPTO EMAIL</FormLabel>
                                        <TextField
                                            fullWidth
                                            name="shiptoemail"
                                            placeholder="it-exp@unicity.com"
                                            value={shiptoEmail}
                                            onChange={e => {
                                                setShiptoEmail(e.target.value)
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card cvc={formData.cvc} expiry={formData.expiry} focused="number" name={formData.name} number={formData.number} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Cardholder Name"
                                        name="name"
                                        placeholder="John Dern"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Card Number"
                                        name="number"
                                        placeholder="4111111111111111"
                                        value={formData.number}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Expiry Date"
                                        name="expiry"
                                        placeholder="12/23"
                                        value={formData.expiry}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth label="CVC" name="cvc" placeholder="123" value={formData.cvc} onChange={handleInputChange} />
                                </Grid>
                            </Grid>
                        </CardContent>

                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel>Shipping Form</FormLabel>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField sx={{ marginTop: '20px' }} fullWidth name="FirstName" label="First name" onChange={handleAddressFormChange} />
                                    <TextField sx={{ marginTop: '20px' }} fullWidth name="LastName" label="Last name" onChange={handleAddressFormChange} />
                                    <TextField sx={{ marginTop: '20px' }} fullWidth name="Zipcode" label="Zip Code" onChange={handleAddressFormChange} />
                                    <TextField sx={{ marginTop: '20px' }} fullWidth name="Prefecture" label="Prefecture" onChange={handleAddressFormChange} />
                                    <TextField sx={{ marginTop: '20px' }} fullWidth name="District" label="District" onChange={handleAddressFormChange} />
                                    <TextField sx={{ marginTop: '20px' }} fullWidth name="Town" label="Town" onChange={handleAddressFormChange} />
                                    <TextField
                                        sx={{ marginTop: '20px' }}
                                        fullWidth
                                        name="HouseNumber"
                                        label="House number"
                                        onChange={handleAddressFormChange}
                                    />
                                    <TextField sx={{ marginTop: '20px' }} fullWidth name="Phone" label="Phone" onChange={handleAddressFormChange} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />
                    <Card>
                        <CardContent>
                            <Box sx={{ margin: '0px 0px 20px 0px', width: '100%' }}>
                                <Button
                                    disabled={!userData.token || ddcHTML || ddcLoading ? true : false}
                                    variant="contained"
                                    type="button"
                                    onClick={_getWorldPayDeviceDataCollection}
                                    sx={{ width: '100%' }}>
                                    {ddcLoading ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : ddcHTML ? (
                                        'DDCHTML initialed'
                                    ) : (
                                        '1. Get Device Data Collection (DDC)'
                                    )}
                                </Button>
                            </Box>

                            <Box sx={{ margin: '0px 0px 20px 0px', width: '100%' }}>
                                <Button
                                    disabled={!ddcHTML || orderLoading || ddcLoading ? true : false}
                                    type="button"
                                    variant="contained"
                                    onClick={_testSentFormat}
                                    sx={{ width: '100%' }}>
                                    {orderLoading ? <CircularProgress size={24} color="inherit" /> : '2. Test create ORDER'}
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>

                    {challengeHTML && (
                        <Card>
                            <CardContent>
                                <Box sx={{ margin: '0px 0px 20px 0px', width: '100%' }}>
                                    <iframe
                                        srcDoc={challengeHTML}
                                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                        onLoad={e => {
                                            handleFrameElement(e)
                                            setCountPathChange(cpc => {
                                                return cpc + 1
                                            })
                                        }}
                                        width="100%"
                                        height="800"
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {requestDCCRes && (
                        <Card>
                            <CardContent>
                                <Box sx={{ margin: '0px 0px 20px 0px', width: '100%', color: 'red' }}>{JSON.stringify(requestDCCRes)}</Box>
                            </CardContent>
                        </Card>
                    )}
                    {createOrderRes && (
                        <Card>
                            <CardContent>
                                <Box sx={{ margin: '0px 0px 20px 0px', width: '100%' }}>{JSON.stringify(createOrderRes)}</Box>
                            </CardContent>
                        </Card>
                    )}
                </Box>
            </Container>

            {ddcHTML && (
                <iframe
                    srcDoc={ddcHTML}
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    onLoad={handleFrameElement}
                    width="10px"
                    height="10px"
                />
            )}
        </Box>
    )
})

export default Poc
