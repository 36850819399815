import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get, isEmpty } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import Payment from '../Payment'
import { PaymentAPIs } from '../PaymentServices/PaymentAPI'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import storeCredit from 'GlobalStores/StoreCredit'
import States from 'Components/Elements/Forms/StatesModal/Country/JapanStates.json'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeCheckout from '../StoreCheckout'
import { delay, getLanguageDialect } from 'Utils/Utils'
const HYDRA_URL = /localhost/.test(window.location.href) ? 'https://hydra.unicity.net/v5a' : 'https://hydra.unicity.net/v5a'
class Japan {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']
    autoAddressKeyToForm = {
        sub2_native: 'town',
        sub1_native: 'district',
        city_native: 'prefecture',
        zip: 'zip_code'
    }

    uuid = ''

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            fullname: '',
            house_number: '',
            zip: '',
            email: '',
            phone: '',
            password: ''
            // password1: '',
            // password2: ''
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            fullname: '',
            house_number: '',
            zip: '',
            email: '',
            phone: '',
            password: ''
        },
        shop: {
            uuid: this.GetUuid(),
            fullname: '',
            house_number: '',
            zip: '',
            email: ''
        }
    }

    formShop = {
        fullname: '',
        house_number: '',
        email: '',
        phone: '',
        zip_code: ''
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        fullname: '',
        house_number: '',
        email: '',
        phone: '',
        password: '',
        zip: '',
        period: ''
    }

    ExcludedFieldsShippingIfCreateAccount = ['firstname_en', 'lastname_en']
    ExcludedFieldsShipping = []

    mapFormKeyToFormatKey = {
        street_address: 'address',
        fullName: 'fullname',
        zip_code: 'zip'
    }

    ArrangeCreateCheckoutForm = ['fullName', 'password', 'zip_code', 'prefecture', 'district', 'town', 'house_number', 'phone', 'email']

    PaymentStep = {
        payment: 0,
        worldPayReq: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    bypassPage = {
        isBypass: true,
        from: '/japan',
        to: 'products'
    }

    notShowReferralBar = false

    // breadcrumbOption = [
    //     {
    //         caption: 'customise',
    //         link: '/products',
    //         paramsRequire: false
    //     },
    //     {
    //         caption: 'signup_shipping',
    //         link: '/checkout/create-account',
    //         paramsRequire: true,
    //         authCaption: 'shipping',
    //         authLink: '/checkout/shipping'
    //     },
    //     {
    //         caption: 'billing',
    //         link: '/checkout/payment',
    //         paramsRequire: true
    //     },
    //     {
    //         caption: 'purchase',
    //         link: '/checkout/review',
    //         paramsRequire: true
    //     }
    // ]

    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        {
            caption: 'billing',
            link: '/checkout/payment',
            paramsRequire: true
        },
        {
            caption: 'purchase',
            link: '/checkout/review',
            paramsRequire: true
        }
    ]

    shippingMethods = [
        {
            id: 1,
            api_value: 'pickup',
            title: 'Pickup, Casablanca Office',
            dict_key: 'pickup_casablanca_office',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: []
                }
            ]
        },
        {
            id: 2,
            api_value: 'ctm',
            title: 'CTM, Delivery to Centers',
            dict_key: 'ctm_delivery_to_centers',
            for_status: [],
            options: []
        },
        {
            id: 3,
            api_value: 'homedelivery',
            title: 'Home Delivery',
            dict_key: 'home_delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: []
                },
                {
                    id: 2,
                    title: 'Cash on Delivery',
                    dict_key: 'cash_on_delivery',
                    descripion: {
                        title: 'Pay when you receive',
                        dict_key: 'pay_when_you_receive'
                    },
                    for_status: ['M']
                }
            ]
        }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: true,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        },
        shippingWithoutMethod: {
            display: true,
            displayStep: ['payment', 'review'],
            dict_key: 'shipping'
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice',
            style: {}
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay',
            style: {}
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: { marginBottom: '80px' }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: { backgroundColor: '#F6F9FC' }
        }
        // {
        //     nameComponent: 'frequentlyAskedQuestions',
        //     titleContent: 'questions',
        //     style: {
        //         backgroundColor: '#F6F9FC'
        //     }
        // }
    ]
    paymentChannel = 'hydraWorldPay' // "hydraWorldPay" | "hydra" | "paymentGateway"

    StatesList() {
        let items = []
        get(States, StoreTranslate.CurrentLanguage(), []).map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        //
        return items
    }

    StatesList() {
        let items = []
        get(States, StoreTranslate.CurrentLanguage(), []).map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        //
        return items
    }

    MapAutoAddressToForm(form, autoAddressData) {
        const lang = StoreTranslate.IsEnglish() ? 'english' : 'native'
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)
        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData) {
                    let useKey = item.key
                    if (lang === 'english') {
                        useKey = item.key.replace('_native', '_roman')
                    }
                    item.value = autoAddressData[swappedValuesAsKeys[useKey]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }

            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    GetShippingMethod() {
        return this.shippingMethods
    }

    GetBypassPage() {
        return this.bypassPage
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    GetNotShowReferralBar() {
        return this.notShowReferralBar
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, products) {
        try {
            storeCheckout.ResetWorldPayData()
            await delay(1000)

            storeCheckout.SetWorldPayResponse(false)

            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            const allowCreditEnable = StoreAuth.IsAllowCredit()

            // WorldPay Retype
            await Pay.PostToHydraWorldPayRequestDDC()
            await delay(4250)

            const currentLanguageStorage = localStorage.getItem('currentLanguage')
            CreditCardEncrypted['type'] = 'IOU'
            CreditCardEncrypted['billToEmail'] = responsePreparePayment.data?.data?.hydra?.shipToEmail
            CreditCardEncrypted['methodDetails'] = {
                ...CreditCardEncrypted['methodDetails'],
                languageDialect: `${getLanguageDialect(currentLanguageStorage)}`,
                challengeWindowSize: `${storeCheckout.dimension}`,
                sessionId: `${storeCheckout.sessionId}`,
                bankName: 'WorldPay'
            }
            Pay.hydra['shippingMethod'] = {
                href: `${HYDRA_URL}/shippingmethods?type=Economy`
            }
            // WorldPay Retype

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, storeCredit.ProductCreditUsed()]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }

            console.log('Pay.hydra.transactions => ', toJS(Pay.hydra.transactions))

            await Pay.PostToHydraWorldPay()

            // Anyway this should return an error instead
            // runInAction(() => {
            //     StorePaymentStep.PaymentDone = true
            // })

            // return { success: false }

            Pay.SendEmail()
            Pay.SendSms()

            await Pay.UpdateToLog()

            const responsePaymentDetail = await PaymentAPIs.GetPaymentDetail(responsePreparePayment.data.data.payment_id)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })
            try {
                TrackingEvents.PurchaseEvent({
                    transaction_id: responsePreparePayment.data.data.payment_id,
                    value: Pay.ushop.total_price,
                    currency: 'JPY',
                    category: Pay.ushop.shipment_purchas_option,
                    items: products
                })
            } catch (e) {
                console.error('TrackingEvents.PurchaseEvent', e)
            }
            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                order_number: responsePaymentDetail.data.order_number
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral, cartItems) {
        try {
            const forms = Object.assign(this.createAccountWithShippingAddress, accForm)
            const returnShippingAddress = {}
            shippingForm.map(item => {
                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value
                    returnShippingAddress[this.mapFormKeyToFormatKey[item.key]] = item.value
                } else {
                    forms[item.key] = item.value
                    returnShippingAddress[item.key] = item.value
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            const periodResponse = await FeelGreatAPI.GetPeriod('AU')
            forms.period = periodResponse.data.entryPeriod
            forms.uuid = uuid
            forms.shipment_packs = JSON.stringify(cartItems)
            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/JPN/feelGreat/createAccount${
                ServerEnv.ENV === 'DEV' ? '?dev=1' : '?emailDelay=1h'
            }`
            const response = await axios.post(url, forms)

            return {
                ...response.data,
                form: forms
            }
        } catch (e) {
            console.error(e)

            return {
                success: false
            }
        }
    }

    SpecialAreasDeliveryFeeCondition(step) {
        let totalPrice = 0

        if (!this.orderSummaryConfig.shippingWithoutMethod.displayStep.includes(step)) return totalPrice

        // const products = toJS(StoreProducts.GetProducts())
        const onetimeItems = get(storeCheckout.GetCart(), 'onetime')
        const subscriptionItems = get(storeCheckout.GetCart(), 'subscribe', [])

        // let totalSubscriptionPV = 0

        // if (!isEmpty(subscriptionItems)) {
        //     const subscriptionProducts = Object.keys(subscriptionItems)
        //     subscriptionProducts.forEach(item => {
        //         const productPV = get(products, [item, 'subscribe', 'pv'], 0)
        //         const productQty = get(subscriptionItems, item, 0)
        //         totalSubscriptionPV = totalSubscriptionPV + productPV * productQty
        //     })
        // }

        if (isEmpty(onetimeItems) && !isEmpty(subscriptionItems)) {
            return totalPrice
        }

        if (+storeCheckout.TotalPrice() < 110000) {
            totalPrice = 627
        }

        return totalPrice
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        makeAutoObservable(this)
    }
}

export default Japan
