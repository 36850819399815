import { observer } from 'mobx-react-lite'
import { Stack, Typography, Card, Box, styled } from '@mui/material'
import DefaultInput from '../../Elements/Forms/DefaultInput'
import DefaultButton from '../../Elements/Forms/DefaultButton'
import Loader from '../Login/Assets/Loader.svg'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { get } from 'lodash'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'

const validationSchema = yup.object({
    password: yup.string('Enter your new password').min(4, 'password_too_short').required('password_is_required'),
    confirmPassword: yup
        .string()
        .required('confirm_password_required')
        .oneOf([yup.ref('password'), null], 'password_not_match')
})

const NewPasswordForm = observer(({ nextStep }) => {
    const [isLoading, setIsLoading] = useState(false)
    const { token } = useParams()
    const navigate = useNavigate()
    const translate = useTranslate()

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            setIsLoading(true)
            const response = await StoreAuth.setNewPassword({
                payload: {
                    value: values.password
                },
                token: atob(token)
            })

            setIsLoading(false)
            if (get(response, 'value', '')) {
                navigate('/new-password-success')
                return
            }
            navigate('/new-password-error')
        }
    })

    return (
        <Card
            sx={{
                marginTop: '40px',
                padding: {
                    xs: '40px 24px 50px 24px',
                    sm: '40px 24px 50px 24px',
                    md: '40px 0 50px 0'
                },
                borderRadius: '12px',
                width: {
                    xs: '100%',
                    sm: '600px',
                    md: '773px'
                },
                boxShadow: 'none'
            }}>
            <Stack width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: '40px',
                        fontSize: {
                            xs: '24px',
                            sm: '32px'
                        },
                        width: '100%',
                        textAlign: {
                            xs: 'start',
                            sm: 'center'
                        }
                    }}>
                    <T>new_password</T>
                </Typography>
                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                    autoComplete="off"
                    noValidate>
                    <Box
                        noValidate
                        autoComplete="off"
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '370px',
                                md: '370px'
                            }
                        }}>
                        <DefaultInput
                            id="password"
                            name="password"
                            label={translate('new_password')}
                            type="password"
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            fullWidth={true}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            onChange={formik.handleChange}
                            // paddingleft={0}
                            helpertext={formik.touched.password && translate(formik.errors.password)}
                        />
                    </Box>
                    <Box
                        noValidate
                        autoComplete="off"
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '370px',
                                md: '370px'
                            }
                        }}
                        mt="20px">
                        <DefaultInput
                            id="confirmPassword"
                            name="confirmPassword"
                            label={translate('confirm_new_password')}
                            type="password"
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            fullWidth={true}
                            onChange={formik.handleChange}
                            // paddingleft={0}
                            helpertext={formik.touched.confirmPassword && translate(formik.errors.confirmPassword)}
                        />
                    </Box>
                    <Box
                        noValidate
                        autoComplete="off"
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '279px'
                            }
                        }}
                        mt="40px">
                        <DefaultButton
                            disabled={!(formik.isValid && formik.dirty)}
                            type="submit"
                            fullWidth={true}
                            titleText={isLoading ? <Spiner src={Loader} alt="Loading" /> : translate('submit')}
                        />
                    </Box>
                </form>
            </Stack>
        </Card>
    )
})

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

export default NewPasswordForm
