import { Box, Modal, Stack, Typography } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import httpHelper from 'Services/HttpHelper'
import { get, isArray, isObject } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import CloseIcon from './CloseIcon'
import DefaultInput from './DefaultInput'
import ErrorStack from './ErrorStack'
import ConfirmBtn from './ConfirmBtn'
import styled from '@emotion/styled'
import { validateEnrollSponser } from '../APIs'

const ChangeEnrollModal = observer(({ propStates, propFunctions }) => {
    const [open, setOpen] = useState(false)
    const [sponsorInputOpen, setSponsorInputOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [enrollerId, setEnrollerId] = useState(propStates.enrollCurrentID)
    const [sponsorId, setSponsorId] = useState(propStates.sponsorCurrentID)
    const [isLoading, setIsLoading] = useState(false)

    const translate = useTranslate()

    const handleOpen = () => {
        setEnrollerId(propStates.enrollCurrentID)
        setSponsorId(propStates.sponsorCurrentID)

        if (propStates.sponsorCurrentID.trim() === '') {
            setSponsorInputOpen(false)
        }

        setOpen(true)
    }

    const handleClose = () => {
        clearErrorMessage() // reset error msg always
        setOpen(false)
    }

    const handleSponsorInputToggle = () => setSponsorInputOpen(prev => !prev)

    const handleShowErrorStack = () => {
        return errorMessage !== '' || (isObject(errorMessage) && Object.keys(errorMessage).length > 0)
    }

    const isDisableConfirmBtn = () => {
        return enrollerId === '' && sponsorId === ''
    }

    const handleChangeEnrollerId = e => {
        const value = get(e, 'target.value', '')
        setEnrollerId(value)
        clearErrorMessage()
    }

    const handleChangeSponsorId = e => {
        const value = get(e, 'target.value', '')
        setSponsorId(value)
        clearErrorMessage()
    }

    const clearErrorMessage = () => {
        setErrorMessage('')
    }

    const validateId = async () => {
        try {
            const response = await validateEnrollSponser('TH', enrollerId, sponsorId || enrollerId)
            const { status } = response
            if (status !== 200) {
                throw new Error('validation ID error')
            }
            return true
        } catch (error) {
            console.error(error.response.data.error_messages)
            if (get(error, 'response.data.error_messages')) {
                setErrorMessage(get(error, 'response.data.error_messages', []))
            } else {
                setErrorMessage(translate('referral_program_ba_id_invalid'))
            }
            return false
        }
    }

    const handleConfirm = async () => {
        setIsLoading(true)
        setErrorMessage('')

        let newId = enrollerId
        let newType = sponsorId.length > 0 ? 'sponsor' : 'enroller'

        let newSponsorId = sponsorId

        const isValidate = await validateId(newId)

        if (isValidate === false) {
            setIsLoading(false)
            return
        }

        propFunctions.confirmCurrentId(newId, newSponsorId, newType)

        setIsLoading(false)

        handleClose()
    }

    return (
        <>
            <Typography
                variant="caption"
                component="span"
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleOpen}
                marginTop={{
                    xs: '0px',
                    md: '15px'
                }}
                fontSize={'14px'}
                color="rgb(7, 159, 244)">
                {translate('change_enroller_id')}
            </Typography>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <ModalBox>
                    <Stack
                        display={'block'}
                        mb={'25px'}
                        position={'absolute'}
                        top={'15px'}
                        right={'15px'}
                        height={'10px'}
                        lineHeight={1}
                        onClick={handleClose}
                        sx={{ cursor: 'pointer' }}>
                        <CloseIcon />
                    </Stack>

                    <Stack justifyContent={'center'} alignItems={'center'} mb={'40px'} mt={'25px'}>
                        <Typography variant="h3" component="h2" fontSize={'30px'} lineHeight={'36px'}>
                            {translate('enroller_and_sponsor')}
                        </Typography>
                    </Stack>

                    <Stack mb={'20px'}>
                        <Stack mb={'10px'} ml={'15px'}>
                            <Typography variant="caption2" component="p" fontSize={'14px'}>
                                {translate('enroller_id')}
                            </Typography>
                        </Stack>
                        <Stack>
                            <DefaultInput value={enrollerId} onChange={handleChangeEnrollerId} type="text" />
                        </Stack>
                    </Stack>

                    <Stack alignItems={'center'} mb={'20px'}>
                        <Typography
                            fontSize={'14px'}
                            variant="caption2"
                            component="span"
                            className={`sponsor-action ${sponsorInputOpen ? 'active' : ''}`}
                            onClick={handleSponsorInputToggle}>
                            {translate('different_enroller_sponsor')}
                        </Typography>
                    </Stack>

                    {sponsorInputOpen ? (
                        <Stack mb={'20px'}>
                            <Stack mb={'10px'} ml={'15px'}>
                                <Typography variant="caption2" component="p" fontSize={'14px'}>
                                    {translate('sponsor_id')}
                                </Typography>
                            </Stack>
                            <Stack>
                                <DefaultInput value={sponsorId} onChange={handleChangeSponsorId} type="text" />
                            </Stack>
                        </Stack>
                    ) : null}

                    {handleShowErrorStack() ? (
                        isArray(errorMessage) ? (
                            errorMessage.map(error => <ErrorStack message={translate(error)} />)
                        ) : (
                            <ErrorStack message={errorMessage} />
                        )
                    ) : null}
                    <ConfirmBtn onClick={handleConfirm} isLoading={isLoading} disabled={isLoading || isDisableConfirmBtn()} titleText={translate('confirm')} />
                </ModalBox>
            </Modal>
        </>
    )
})

export default ChangeEnrollModal

const ModalBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 335px;
    min-height: 371px;
    background-color: #fff;
    padding: 15px 20px 50px;
    border-radius: 12px;

    & span.sponsor-action {
        color: #079ff4;
        font-size: 14px;
        font-weight: 400;
        text-decoration-line: underline;
        text-underline-offset: 2px;

        cursor: pointer;
        &.active {
            text-decoration-line: none;
            color: #8a9bb0;
        }
    }

    @media screen and (min-width: 768px) {
        width: 516px;
        padding: 15px 50px 60px;
    }
`
