import { Box, Card, Stack, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useCallback, useEffect, useState } from 'react'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { ReactComponent as ThaiQR } from  './Assets/thai-qr.svg'
import { get } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { saveAs } from 'file-saver'
import moment from 'moment'
import numeral from 'numeral'
import { runInAction, toJS } from 'mobx'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { storeElement } from 'GlobalStores/StoreElement'
import storeUser from 'GlobalStores/User/StoreUser'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { useInterval } from 'Helpers/useInterval'
import { PaymentAPIs } from 'GlobalStores/Checkout/PaymentServices/PaymentAPI'
import storeFormAddress from 'GlobalStores/Checkout/StoreFormAddress'
import { MainTemplate } from 'Components/Template/MainTemplate'

const QRPaymentPage = observer(() => {
    const location = useLocation()
    const queryString = new URLSearchParams(location.search)
    const referenceNo = queryString.get('ref_id')
    const navigate = useNavigate()

    const [data, setData] = useState(null)
    const [kill, setKill] = useState(false)

    const checkQr = async () => {
        try {
            const resp = await PaymentAPIs.GetQRDetail(referenceNo)
            const status = get(resp, 'data.success', false)
            if (status) {
                setTimeout(() => {
                    CreatCustomer()
                }, 5000)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const CreatCustomer = useCallback(async () => {
        // ถ้าจ่ายเงินสำเร็จ
        setKill(true)
        if (!storeAuth.isAuthorized && !storeCheckout.createCustomerLoading) {
            storeCheckout.createCustomerLoading = true
            const formCheckout = storeCheckout.InitializeFormShippingData('enroll')

            const getCreateCustomerForm = toJS(storeUser.customerHydraPayload)

            const createCustomerRes = await PaymentAPIs.CreateCustomer(getCreateCustomerForm)

            if (createCustomerRes.status === 201) {
                const newBaID = createCustomerRes.data.id.unicity

                const emailConfirm = {
                    to_email: createCustomerRes.data.email,
                    bcc: 'unicity.dev2018@gmail.com',
                    enroll_id: newBaID,
                    enroll_name: `${getCreateCustomerForm.humanName['fullName@th']}`
                }

                FeelGreatAPI.SendEmailV3(emailConfirm, storeCountry.Country3())

                const loginResponse = await storeAuth.Login({ unicityID: newBaID, password: formCheckout.password })

                const patchUUID = {
                    type: 'shop',
                    uuid: storeCheckout.GetUuid(),
                    ba_status: getCreateCustomerForm.type.charAt(0),
                    login_id: `${newBaID}`,
                    login_name: getCreateCustomerForm.humanName['fullName@th'],
                    login_name_native: getCreateCustomerForm.humanName['fullName@th'],
                    token: loginResponse.loginData.token
                }
                await PaymentAPIs.UpdateUUid(patchUUID, storeCountry.Country3())

                const readyToCreateData = {
                    referenceNo: referenceNo,
                    status: 'waiting',
                    type: 'feelGreat',
                    token: `Bearer ${loginResponse.loginData.token}`,
                    ba_id: newBaID
                }

                await PaymentAPIs.UpdateReadyToCreateOrder(readyToCreateData)
                navigate(`/payment-response/success?payment_id=${referenceNo}&id=${newBaID}`)
            }
        } else {
            navigate(`/payment-response/success?payment_id=${referenceNo}`)
        }
    }, [navigate, referenceNo])

    const getQRData = useCallback(async () => {
        try {
            const resp = await PaymentAPIs.GetQRDetail(referenceNo)
            const status = get(resp, 'data.success', false)
            if (status) {
                CreatCustomer()
            }
            setData(resp.data)
        } catch (error) {
            console.log(error)
        }
    }, [referenceNo, setData, CreatCustomer])

    useInterval(checkQr, 5000, kill)

    useEffect(() => {
        getQRData()
    }, [getQRData])

    const handleSave = () => {
        let url = get(data, 'qrUrl', '')
        saveAs(url, 'qr-code.png')
    }

    const onClickBack = async () => {
        try {
            setKill(true)
            runInAction(() => {
                storeCheckout.checkoutErrors = []
                storeElement.showCardFrontDrop = true
            })

            const responseCreateUuid = await storeFormAddress.SubmitShippingForm(true)
            const uuid = responseCreateUuid.uuid

            if (responseCreateUuid.success === true) {
                navigate(`/checkout/payment?uuid=${uuid}`)

                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    storeCheckout.checkoutPreparing = false
                }, 1000)
            }

            if (responseCreateUuid.success === false) {
                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    storeCheckout.checkoutPreparing = false
                    storeCheckout.checkoutErrors = responseCreateUuid.Message_v2
                })
            }
        } catch (error) {
            console.log(error.message)
            runInAction(() => {
                storeElement.showCardFrontDrop = false
            })
            navigate(`/checkout`)
        }
    }

    const getTime = () => {
        const expiredDate = get(data, 'expired_at')
        if (!expiredDate) return '-'
        const originalDate = moment(expiredDate)
        const modifiedDate = originalDate.subtract(7, 'hours')
        return modifiedDate.format('DD/MM/YYYY HH:mm')
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <ReferralMainPageStyled>
                <ContainerStyled>
                    <CardStyled>
                        <DetailWrapper>
                            <DetailRow>
                                <Typography variant="body1">
                                    <T>amount_th</T>
                                </Typography>
                                {data && <Typography variant="body1">{numeral(data.amount).format('0,0')}</Typography>}
                            </DetailRow>
                            <DetailRow>
                                <Typography variant="body1">
                                    <T>payment_code</T>
                                </Typography>
                                <Typography variant="body1">{referenceNo}</Typography>
                            </DetailRow>
                            <DetailRow>
                                <Typography variant="body1">
                                    <T>pay_before</T>
                                </Typography>
                                <Typography variant="body1">{getTime()}</Typography>
                            </DetailRow>
                        </DetailWrapper>
                        <ThaiQrWrapper>
                            <ThaiQR />
                        </ThaiQrWrapper>

                        <QRCodeWrapper>{data && data?.qrUrl && <img src={data.qrUrl} alt="qr" style={{ width: '200px' }} />}</QRCodeWrapper>
                        <QRDesctiption>
                            <Typography variant="subtitle1">Unicity Marketing (Thailand) Co.,Ltd.</Typography>
                        </QRDesctiption>
                        <ButtonWrapper>
                            {/* {(store.isWebview === false || StoreWebView.GetIsWebView() === false) && ( */}
                            <SaveButton
                                onClick={() => {
                                    handleSave()
                                }}>
                                <Typography variant="body1">
                                    <T>save_image</T>
                                </Typography>
                            </SaveButton>
                            {/* )} */}
                            <Cancel onClick={onClickBack}>
                                <Typography variant="body1">
                                    <T>cancel</T>
                                </Typography>
                            </Cancel>
                        </ButtonWrapper>
                    </CardStyled>
                </ContainerStyled>
            </ReferralMainPageStyled>
        </ThemeProvider>
    )
})

const ReferralMainPageStyled = styled(Stack)`
    background-color: #d2d9e1;
    padding: 20px;
    min-height: calc(100dvh - 75px);
    align-items: center;
    flex-direction: column;
`

const CardStyled = styled(Card)`
    margin-top: 20px;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(14, 35, 56, 0.2);
    border-radius: 12px;
    margin-bottom: 20px;
    width: 100%;
    padding: 30px 30px;
    box-sizing: border-box;

    @media (max-width: 600px) {
        padding: 30px 20px;
    }
`

const ContainerStyled = styled(Box)`
    box-sizing: border-box;
    width: 100%;
    max-width: 652px;
    @media screen and (min-width: 730px) and (max-width: 1200px) {
        padding: 0 70px;
    }
`

const DetailWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const DetailRow = styled(Box)`
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .MuiTypography-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #003b6f;
    }
    .MuiTypography-body1 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #003b6f;
    }

    @media (min-width: 428px) {
        font-size: 18px;
    }

    div:last-child {
        text-align: right;
    }
`

const ThaiQrWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #113566;
    border-radius: 8px;
    margin: 30px 0 20px;
`

const QRCodeWrapper = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        width: 100%;
        max-width: 260px;
    }
`

const QRDesctiption = styled(Box)`
    margin-top: 10px;
    color: #003b6f;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
`

const ButtonWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
`

const SaveButton = styled(Box)`
    box-sizing: border-box;
    background-color: #079ff4;
    color: white;
    border-radius: 32px;
    font-size: 24px;
    font-weight: 600;
    padding: 10px 38px;
    border: none;
    letter-spacing: -0.01em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .MuiTypography-body1 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        display: flex;
        align-items: center;
        color: #ffffff;
    }
`

const Cancel = styled(Box)`
    border: none;
    color: #003b6f;
    font-size: 18px;
    font-weight: 300;
    text-decoration: underline;
    background-color: transparent;
    cursor: pointer;

    .MuiTypography-body1 {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        display: flex;
        align-items: center;
        color: #003b6f;
    }
`

export default QRPaymentPage
