import { Stack, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'

const MobileLayoutWrapper = observer(props => {

    return <MobileLayoutWrapperStyled id="mobile-layout">{props.children}</MobileLayoutWrapperStyled>
})

/**
 * @description Layout wrapper for mobile devices (width <= 834px) for user account pages
 */
const MobileLayoutWrapperStyled = styled(Stack)`
    /**
        This style is for mobile devices default is 350px
        Next style is for mobile devices with min-width 768px
     */
    align-items: center;
    background: #d1d9e2;
    width: 100%;
    padding: 0 20px;
    min-height: calc(100vh - (74px + 64px));
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (min-width: 768px) {
        padding-left: 60px;
        padding-right: 60px;
    }
`

export default MobileLayoutWrapper
