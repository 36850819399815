import { Box, Stack, styled, Typography, Modal } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import TermsAndConditionData from './TermAndConditionData.json'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'

export const ModalTermsAndCondition = observer(props => {
    const { open, handleClose } = props

    const { width } = useSizeWindow()
    const translate = useTranslate()

    const termsAndConditionsContent = StoreTranslate.UseLanguage(storeFeelGreat?.contents?.termAndConditions.htmlContent)
    
    return (
        <Modal sx={{ padding: '0 20px' }} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <BoxModalTermAndConditionStyled>
                <Stack padding={width < 600 ? '30px 20px' : '30px'}>
                    {/* <Stack>
                        <IconFireWorks />
                    </Stack> */}

                    <Stack>
                        <Typography variant="h3">
                            <span><T>unicity_distributor_agreement</T></span>
                        </Typography>
                    </Stack>

                    <Stack overflow={'scroll'} mt="20px" flexDirection={'column'} height={'400px'}>
                        <Typography
                            variant="caption"
                            fontWeight={300}
                            color="#0B3B61"
                            fontSize={'14px'}
                            dangerouslySetInnerHTML={{ __html: termsAndConditionsContent }}
                        />
{/*                         
                        <Typography
                            variant="caption"
                            fontWeight={300}
                            color="#0B3B61"
                            fontSize={'14px'}
                            dangerouslySetInnerHTML={{ __html: TermsAndConditionData.desc1 }}
                        />

                        <Typography
                            mt="25px"
                            variant="caption"
                            fontWeight={300}
                            color="#0B3B61"
                            fontSize={'14px'}
                            dangerouslySetInnerHTML={{ __html: TermsAndConditionData.desc2 }}
                        />

                        <Typography
                            mt="25px"
                            variant="caption"
                            fontWeight={300}
                            color="#0B3B61"
                            fontSize={'14px'}
                            dangerouslySetInnerHTML={{ __html: TermsAndConditionData.desc3 }}
                        /> */}
                    </Stack>

                    <Stack mt="20px">
                        <Typography onClick={handleClose} variant={'link'} fontWeight={500} color={'#079FF4'} fontSize={'24px'}><T>close</T></Typography>
                    </Stack>
                </Stack>
            </BoxModalTermAndConditionStyled>
        </Modal>
    )
})

const BoxModalTermAndConditionStyled = styled(Box)`
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 335px;
    height: auto;
    background-color: #ffffff;
    border-radius: 8px;

    @media screen and (min-width: 600px) {
        top: 50%;
        width: auto;
        max-width: 700px;
        min-width: 500px;
    }
`