import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { observer } from 'mobx-react-lite'
import { InputAdornment } from '@mui/material'
import { ReactComponent as LockIcon } from './Assets/Lock.svg'
import { ReactComponent as VerifiedIcon } from './Assets/Verified.svg'
import { ReactComponent as InVisibleIcon } from './Assets/InVisible.svg'
import { ReactComponent as VisibleIcon } from './Assets/Visible.svg'

const DefaultTextFields = observer(props => {
    const { label = '', value = '', fullWidth = true, inputFontSize = '16px', type = 'text' } = props
    const inputRef = React.useRef()
    const [val, setVal] = React.useState(value || '')
    const [switchPWType, setswitchPWType] = React.useState(type)

    const handleVisibled = () => {
        if (switchPWType === 'password') {
            setswitchPWType('text')
        } else {
            setswitchPWType('password')
        }
    }

    const valChange = e => {
        e.preventDefault()
        setVal(e.target.value)
    }

    return (
        <Box width={fullWidth ? '100%' : 'auto'}>
            <TextField
                ref={inputRef}
                disabled={props.verified}
                label={label}
                variant="filled"
                sx={{
                    'input::-ms-reveal, input::-ms-clear': {
                        display: 'none'
                    },
                    width: '100%',
                    '.MuiFormLabel-root': {
                        fontSize: val ? '14px' : '24px',
                        color: '#33628C',
                        '&.Mui-focused': {
                            fontSize: '14px !important',
                            fontWeight: 300
                        }
                    },
                    '.MuiInputBase-root': {
                        fontSize: inputFontSize,
                        color: '#000000',
                        borderRadius: '8px',
                        backgroundColor: props.disabled ? '#F0F2F5' : props.verified ? '#E5FBEE' : '#fff',
                        border: '1px solid #D1D9E2',
                        '::before': {
                            border: 'none !important'
                        },
                        '.Mui-disabled': {
                            opacity: 1,
                            WebkitTextFillColor: '#000000 !important'
                        },
                        '::after': {
                            border: 'none !important'
                        },
                        ':hover': {
                            backgroundColor: '#fff',
                            '::before': {
                                border: 'none !important'
                            },
                            '::after': {
                                border: 'none !important'
                            }
                        },
                        '&.Mui-focused': {
                            backgroundColor: '#fff'
                        }
                    }
                }}
                InputProps={
                    (props.disabled && {
                        endAdornment: (
                            <InputAdornment position="end">
                                {' '}
                                <LockIcon />{' '}
                            </InputAdornment>
                        ),
                        'aria-autocomplete': 'none',
                        autoComplete: 'new-password',
                        spellCheck: 'false'
                    }) ||
                    (props.verified && {
                        endAdornment: (
                            <InputAdornment position="end">
                                {' '}
                                <VerifiedIcon />{' '}
                            </InputAdornment>
                        ),
                        'aria-autocomplete': 'none',
                        autoComplete: 'new-password',
                        spellCheck: 'false'
                    }) ||
                    (props.password && {
                        endAdornment: (
                            <InputAdornment position="end">
                                {switchPWType === 'text' ? (
                                    <VisibleIcon style={{ cursor: 'pointer' }} onClick={() => handleVisibled()} />
                                ) : (
                                    <InVisibleIcon style={{ cursor: 'pointer' }} onClick={() => handleVisibled()} />
                                )}
                            </InputAdornment>
                        ),
                        'aria-autocomplete': 'none',
                        autoComplete: 'new-password',
                        spellCheck: 'false'
                    }) || {
                        'aria-autocomplete': 'none',
                        autoComplete: 'new-password',
                        spellCheck: 'false'
                    }
                }
                {...props}
                type={switchPWType ? switchPWType : type}
                onKeyUp={valChange}
            />
        </Box>
    )
})

export default DefaultTextFields
