import { Stack, ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { MainTemplate } from '../Template/MainTemplate'
import StoreProducts from '../../GlobalStores/StoreProducts'
import ReferralBar from '../Elements/Referral/ReferralBar'
import Landing from '../Elements/Landings/Landing'
import StoreAuth from 'GlobalStores/User/StoreAuth'

const Index = observer(() => {
    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack>
                {!StoreAuth.IsAuthorized() && <ReferralBar />}
                {StoreProducts.GetIsInit() ? <Landing /> : <div></div>}
            </Stack>
        </ThemeProvider>
    )
})
export default Index
