import * as React from 'react'
import { Typography, Popper, Fade, Paper, styled, Backdrop, Box } from '@mui/material'
import { ReactComponent as CloseIcon } from './Assets/close.svg'
import { useSizeWindow } from 'Helpers/GlobalHelpers'

export default function PopperPopup({ paperWidth = '335px', title = ``, subTitle = ``, paragraph = ``, image = null, button = '' }) {
    const [arrowRef, setArrowRef] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [open, setOpen] = React.useState(false)

    const { width } = useSizeWindow()

    const handleClick = event => {
        setOpen(!open)
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    React.useEffect(() => {
        const handleScroll = () => {
            if (!anchorEl) return
            handleClose()
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [anchorEl])

    return (
        <div style={{ position: 'relative' }}>
            <Box sx={{ paddingRight: '16px' }} onClick={handleClick} aria-describedby={'referPoper'}>
                <Typography
                    variant="caption"
                    sx={{
                        color: '#0BA4D4',
                        fontSize: '12px',
                        textDecoration: 'underline',
                        textUnderlineOffset: '3px'
                    }}>
                    {button}
                </Typography>
            </Box>

            <Popper
                sx={{
                    bottom: '20px !important',
                    borderRadius: '8px !important',
                    border: 'none !important',
                    zIndex: 2,
                    padding: width < 600 ? '0 1rem' : ''
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement={'top-end'}
                transition
                keepMounted
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false,
                        options: {
                            altBoundary: true,
                            rootBoundary: 'viewport'
                        }
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: 'viewport',
                        }
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef
                        }
                    }
                ]}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            sx={{
                                borderRadius: '8px !important',
                                width: width < 600 ? '100%' : paperWidth,
                                padding: '20px 30px 30px 30px',
                                backgroundColor: '#fff',
                                boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.05)',
                                boxSizing: 'border-box'
                            }}>
                            <Arrow ref={setArrowRef}>
                                <Triangle />
                            </Arrow>

                            <Box
                                sx={{
                                    position: 'absolute',
                                    right: width <= 834 ? '40px' : '20px',
                                    cursor: 'pointer'
                                }}>
                                <CloseIcon onClick={() => handleClose()} />
                            </Box>

                            <Content>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: '24px'
                                    }}>
                                    {image && <img src={image} alt="Success" />}
                                </Box>
                                <Box sx={{ marginBottom: '24px' }}>
                                    <Typography
                                        variant="h3"
                                        color="#0B3B61"
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontWeight: 700,
                                            fontSize: '24px'
                                        }}>
                                        {title}
                                    </Typography>
                                </Box>
                                <Box sx={{ marginBottom: '13px' }}>
                                    <Typography
                                        variant="h3"
                                        color="#0B3B61"
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            lineHeight: '24px'
                                        }}>
                                        {subTitle}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h3"
                                        color="#0B3B61"
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '24px'
                                        }}>
                                        {paragraph}
                                    </Typography>
                                </Box>
                            </Content>
                        </Paper>
                    </Fade>
                )}
            </Popper>

            <Backdrop sx={{ color: '#fff', zIndex: 1, cursor: 'auto !important', background: 'rgba(56, 66, 80, 0.2)' }} open={open} onClick={handleClose} />
        </div>
    )
}

const Arrow = styled('span')`
    bottom: 5px;
    position: absolute;
    width: 20px;
    height: 10px;
`

const Triangle = styled('div')`
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 19px;
    height: 19px;
    background: #fff;
    transform: rotate(45deg);
`

const Content = styled('div')`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-family: Inter, NotoSansThai;
    font-style: normal;
    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #003764;
    }

    .content-title {
        display: flex;
        flex-direction: row;
        width: '100%';
    }
`
