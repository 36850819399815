import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import HttpHelper from 'Services/HttpHelper'

const GetCustomer = customerId => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers?unicity=${customerId}&expand=customer` })

    return axios.get(url, {
        headers: {
            'X-Application': `asia.fg.${StoreCountry.Country2().toLowerCase()}`
        }
    })
}

const GetCustomerImgProfile = url => {
    return axios.get(url)
}

const getAllowReferrer = customerId => {
    // 249742966
    const url = HttpHelper.Proxy({ originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/products-v2/feelGreat/v3/checkAllowReferrer?countryCode=${StoreCountry.Country3()}&baId=${customerId}` })

    return axios.get(url)
}

export const Customers = {
    GetCustomer,
    GetCustomerImgProfile,
    getAllowReferrer
}
