import { ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { MainTemplate } from '../../../../Template/MainTemplate'
import { useEffect, useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const WhatExpertsSayItem = observer(props => {
    const expertsSay = props.data
    const [description, setDescription] = useState(StoreTranslate.UseLanguage(props.description))

    useEffect(() => {
        setDescription(StoreTranslate.UseLanguage(props.description))
    }, [StoreTranslate.CurrentLanguage()])

    return (
        <ThemeProvider theme={MainTemplate}>
            {props.isDefault ? <IMGProfile src={require(`./assets/svg/${expertsSay.image}.svg`)} /> : <IMGProfile src={expertsSay.image} />}
            <Typography variant="body2" fontWeight={'700'} mt="20px">
                {StoreTranslate.UseLanguage(expertsSay.title)}
            </Typography>

            {props.isDefault ? (
                <Typography variant="body3" fontStyle={'italic'} style={{ textAlign: '-webkit-center', width: '100%' }} mt="20px">
                    {description}
                </Typography>
            ) : (
                <Typography
                    variant="body3"
                    fontStyle={'italic'}
                    style={{ textAlign: '-webkit-center', width: '100%' }}
                    mt="20px"
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                />
            )}
        </ThemeProvider>
    )
})

const IMGProfile = styled.img`
    height: 100px;
    width: 100px !important;
`

export default WhatExpertsSayItem
