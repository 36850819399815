import { Box, Button, Stack, styled, Skeleton, ThemeProvider, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { MainTemplate } from '../../../../Template/MainTemplate'

import { ReactComponent as Plus } from './assets/svg/plus.svg'
import { ReactComponent as Minus } from './assets/svg/minus.svg'
import { useEffect, useState } from 'react'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { reaction, runInAction } from 'mobx'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const FrequentlyAskedQuestions = observer(() => {
    const contentDefault = {
        title: 'Frequently Asked Questions',
        list: [
            {
                title: 'How long will it take for me to see results with Feel Great?',
                check: false,
                description:
                    'Results will vary. However, we guarantee that you will Feel Great within your first 30 days, so long as you are taking the products daily and consistently.'
            },
            { title: 'Am I destined for greatness?', check: false, description: 'DescriptionComponent' },
            { title: 'Will this improve my balance?', check: false, description: 'DescriptionComponent' },
            { title: 'When do I get vegan powers?', check: false, description: 'DescriptionComponent' },
            { title: 'When do I get vegan powers?', check: false, description: 'DescriptionComponent' },
            { title: 'Some days I only eat one meal, should I still drink Balance twice per day?', check: false, description: 'DescriptionComponent' }
        ]
    }

    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({ groupKey: null, itemKey: null })

    const data = useLocalObservable(() => ({
        questions: null,
        titleGroup: [],
        items: [],
        selectedGroup: 0,
        selectedItems: {}
    }))

    const handleShowDescription = (key, value) => {
        runInAction(() => (data.selectedItems[key] = value))
    }

    const handleChange = newAlignment => {
        runInAction(() => {
            data.selectedGroup = newAlignment
        })
    }

    useEffect(() => {
        setTimeout(() => {
            if (StoreFeelGreat.GetContent('questions')) {
                runInAction(() => {
                    data.titleGroup = []
                    data.items = []
                    data.questions = StoreFeelGreat.GetContent('questions')
                })
                StoreFeelGreat.GetContent('questions').items.map((item, key) => {
                    runInAction(() => {
                        data.titleGroup.push(item.name)
                        data.items.push(item.items)
                    })
                })
            }
            setLoading(false)
        }, 1000)
        reaction(
            () => StoreFeelGreat.GetContent('questions'),
            (value, prev, disposer) => {
                runInAction(() => {
                    data.titleGroup = []
                    data.items = []
                    data.questions = value
                })

                value.items.map((item, key) => {
                    runInAction(() => {
                        data.titleGroup.push(item.name)
                        data.items.push(item.items)
                    })
                })
                disposer.dispose()
            }
        )
    }, [])

    return (
        <Stack mb="60px" mt="20px">
            <ThemeProvider theme={MainTemplate}>
                {!loading && data.questions !== null ? (
                    <>
                        <Box width="80%" textAlign={'-webkit-center'} alignSelf={'center'} mt="60px">
                            <Typography variant="h6">{StoreTranslate.UseLanguage(data.questions.title)}</Typography>
                        </Box>
                        <GroupButtonTab>
                            {data.titleGroup.map((item, key) => {
                                return (
                                    <ButtonTabStyle
                                        key={key}
                                        variant="outlined"
                                        isselected={data.selectedGroup === key ? 'true' : 'false'}
                                        onClick={e => handleChange(key)}>
                                        <Typography variant="caption" fontWeight={data.selectedGroup === key ? 600 : 400} fontSize={14}>
                                            {StoreTranslate.UseLanguage(item)}
                                        </Typography>
                                    </ButtonTabStyle>
                                )
                            })}
                        </GroupButtonTab>
                        {data.questions && (
                            <ListComponentStyled>
                                {data.items.map((group, groupKey) => {
                                    return group.map((item, itemKey) => {
                                        const isselected = state.groupKey === groupKey && state.itemKey === itemKey
                                        return (
                                            <AccordionStyled
                                                key={itemKey}
                                                expanded={isselected}
                                                style={{
                                                    display: groupKey === data.selectedGroup ? 'block' : 'none',
                                                    marginBottom: 16,
                                                    padding: 20
                                                }}
                                                onChange={() => {
                                                    if (isselected) {
                                                        setState({ groupKey: null, itemKey: null })
                                                        return
                                                    }
                                                    setState({ groupKey, itemKey })
                                                }}
                                                TransitionProps={{ unmountOnExit: true }}>
                                                <AccordionSummaryStyled
                                                    isshow={isselected.toString()}
                                                    expandIcon={isselected ? <Minus /> : <Plus />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header">
                                                    <Typography
                                                        variant="body3"
                                                        fontWeight={600}
                                                        lineHeight={'24px'}
                                                        key={groupKey}
                                                        style={{ listStyle: 'none' }}>
                                                        {StoreTranslate.UseLanguage(item.title)}
                                                    </Typography>
                                                </AccordionSummaryStyled>
                                                <AccordionDetailsStyled>
                                                    <Typography variant="body3" lineHeight={'24px'} fontWeight={400}>
                                                        <span dangerouslySetInnerHTML={{ __html: StoreTranslate.UseLanguage(item.description) }} />
                                                    </Typography>
                                                </AccordionDetailsStyled>
                                            </AccordionStyled>
                                        )
                                    })
                                })}
                            </ListComponentStyled>
                        )}
                    </>
                ) : (
                    <>
                        <Box width="80%" textAlign={'-webkit-center'} alignSelf={'center'} mb="40px" mt="60px">
                            <Skeleton variant="rounded" width={'56%'} height={'40px'} sx={{ marginTop: '54px' }} />
                            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent="space-between">
                                {Array.from(Array(4).keys()).map(num => {
                                    return <Skeleton key={num + 'gbt'} variant="rounded" width={'20%'} height={'44px'} sx={{ margin: '40px 0 20px 0' }} />
                                })}
                            </Stack>
                        </Box>
                        <ListComponentStyled>
                            {Array.from(Array(6).keys()).map(num => {
                                return <Skeleton key={num + 'listWork'} variant="rounded" width={'100%'} height={'70px'} sx={{ margin: '0px 0 30px 0' }} />
                            })}
                        </ListComponentStyled>
                    </>
                )}
            </ThemeProvider>
        </Stack>
    )
})

const AccordionStyled = styled(Accordion)`
    border: ${props => (props.isselected === 'true' ? '2px solid #99bcdf' : '1px solid #99bcdf')};
    border-radius: 6px;
    background-color: transparent;
    box-shadow: none;
    margin: 0px;
    &.MuiPaper-root.MuiAccordion-root {
        &.Mui-expanded {
            margin: 0px;
        }
    }
`

const AccordionSummaryStyled = styled(AccordionSummary)`
    &.MuiButtonBase-root {
        &.MuiAccordionSummary-root {
            padding: 0;
            min-height: auto;
            .MuiAccordionSummary-content {
                padding: 0;
                margin: 0;
            }
        }
    }
`

const AccordionDetailsStyled = styled(AccordionDetails)`
    &.MuiAccordionDetails-root {
        padding: 0;
    }
`

const GroupButtonTab = styled(Box)`
    width: 80%;
    margin-top: 40px;
    margin-bottom: 20px;
    display: inline-block;
    gap: 16px;
    justify-content: space-between;
    overflow: scroll;
    white-space: nowrap;

    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;

    @media screen and (min-width: 730px) {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: 1200px) {
        width: 970px;
    }
`

const ButtonTabStyle = styled(Button)`
    height: 44px;
    width: auto;
    margin-right: 16px;
    &.MuiButton-outlined {
        border: ${props => (props.isselected === 'true' ? '2px solid #99bcdf' : '1px solid #99bcdf')};
        border-radius: 6px;
    }

    @media screen and (min-width: 730px) {
        min-width: 134px;
        width: 40%;
        margin-right: 0;
    }

    @media screen and (min-width: 1200px) {
        width: 220px;
    }
`

const ListComponentStyled = styled(Stack)`
    width: 90%;
    align-self: center;
    margin-bottom: 40px;
    @media screen and (min-width: 730px) {
        width: 80%;
    }

    @media screen and (min-width: 1200px) {
        width: 970px;
    }
`

export default FrequentlyAskedQuestions
