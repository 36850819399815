import { Stack, Box, Typography } from '@mui/material'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import phoneIcon from './Assets/phone.svg'
import location from './Assets/location.svg'
import emailIcon from './Assets/email.svg'
import { toJS } from 'mobx'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import DataLocation from './DataLocation.json'

const ContactUs = observer(() => {
    const contact = toJS(StoreFeelGreat.GetContent('contact'))
    const { width } = useSizeWindow() 

    return (
        <Box>
            <Typography variant="h2" sx={{ fontWeight: 600, color: '#153862', fontSize: '40px', textAlign: width <= 600 && 'center' }}>
                <T>contact_us</T>
            </Typography>

            <ContainerStyled>
                <StackStyled>
                    <img src={phoneIcon} alt="phone" width={'34px'} height={'36px'} />
                    <TitleStyled variant="h4">
                        <T>phone</T>
                    </TitleStyled>
                    <DescriptionStyled>
                        {contact.phone}
                    </DescriptionStyled>
                </StackStyled>

                <StackStyled>
                    <img src={location} alt="location" width={'34px'} height={'36px'} />
                    <TitleStyled variant="h4">
                        <T>location</T>    
                    </TitleStyled>
                    <DescriptionStyled dangerouslySetInnerHTML={{ __html: DataLocation.address }} />
                    <DescriptionStyled mt="20px" dangerouslySetInnerHTML={{ __html: DataLocation.dateOpen }} />
                    <DescriptionStyled dangerouslySetInnerHTML={{ __html: DataLocation.dateClosed }} />
                </StackStyled>

                <StackStyled>
                    <img src={emailIcon} alt="email" width={'34px'} height={'36px'} />
                    <TitleStyled variant="h4">
                        <T>email</T>
                    </TitleStyled>
                    <DescriptionStyled>
                        {contact.email}
                    </DescriptionStyled>
                </StackStyled>
            </ContainerStyled>
                
        </Box>
    )
})

const ContainerStyled = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 600px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 25px;
        margin-top: 100px;
    }
`

const StackStyled = styled(Stack)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 60px;
    }

    @media screen and (min-width: 600px) {
        max-width: 300px;
        align-items: flex-start;
        margin-top: 0px;

        &:first-of-type {
            margin-top: 0px;
        }
    }

    @media screen and (min-width: 1040px) {
        max-width: 350px;
    }
`

const  TitleStyled = styled(Typography)`
    fontFamily: 'Poppins, NotoSansThai';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #153862;
    margin-top: 25px;
`

const DescriptionStyled = styled(Typography)`
    fontFamily: 'Poppins, NotoSansThai';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    color: #153862;
    text-align: center;

    @media screen and (min-width: 600px) {
        text-align: left;
    }
`
export default ContactUs