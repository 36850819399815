const Form = [
    {
        key: 'name_on_card',
        type: 'text',
        label: 'name_on_card',
        value: ''
    },
    {
        key: 'card_number',
        type: 'tel',
        label: 'card_number',
        value: '',
        args: {
            regx: '^[0-9]+$',
            maxLength: 16
        },
        helpertextcardtype: 'only_accepting_visa_or_mastercard'
    },
    {
        key: 'exp_date',
        type: 'ExpDate',
        label: 'exp_date',
        value: '',
        placeholder: 'MM/YY'
    },
    {
        key: 'cvv',
        type: 'tel',
        label: 'cvv',
        value: '',
        args: {
            regx: '[0-9]'
        }
    }
]

export const CreditCardConfigs = { Form }
