import { Box, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { MainTemplate } from '../../../../Template/MainTemplate'
import { useEffect, useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const RealResultsItem = observer(props => {
    const realResults = props.data
    const [description, setDescription] = useState(StoreTranslate.UseLanguage(props.description))

    useEffect(() => {
        setDescription(StoreTranslate.UseLanguage(props.description))
    }, [StoreTranslate.CurrentLanguage()])

    return (
        <ThemeProvider theme={MainTemplate}>
            {props.isDefault ? (
                <Typography variant="body3" fontStyle={'italic'}>
                    {description}
                </Typography>
            ) : (
                <Typography
                    variant="body3"
                    fontStyle={'italic'}
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                />
            )}
            <Box display="flex" alignItems="center" mt="60px">
                <Typography variant="body3" fontWeight={'500'} lineHeight={'26px'} style={{ textAlign: '-webkit-left' }}>
                    {StoreTranslate.UseLanguage(realResults.footer)}
                </Typography>
            </Box>
        </ThemeProvider>
    )
})

export default RealResultsItem
