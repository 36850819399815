import { Box, Card, Grid, ThemeProvider, Typography, Stack, Skeleton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { ReactComponent as IconCopy } from './Assets/copy.svg'
import { ReactComponent as IconCopied } from './Assets/checked.svg'
import Loader from './Assets/Loader.svg'
import DefaultButton from '../../Elements/Forms/DefaultButton'

import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import copy from 'copy-to-clipboard'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { runInAction } from 'mobx'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useNavigate } from 'react-router-dom'
import './Invite.scss'
import ReferralLinks from './ReferralLinks/ReferralLinks'

const options = [
    {
        title: 'referral_link',
        value: 'link'
    },
    {
        title: 'referral_code',
        value: 'code'
    }
]

const Invite = observer(props => {
    const translate = useTranslate()
    const [referType, setReferType] = useState('link')
    const [isCopied, setIsCopied] = useState(false)
    const [loading, setLoading] = useState(true)

    const { width } = useSizeWindow()

    useEffect(() => {
        runInAction(() => {
            storeElement.cardTitle.titleStep1 = <T>invite</T>
            storeElement.settingCard.title = ''
            storeElement.settingCard.padding = ''
        })
        StoreReferral.GetReferralCode(StoreAuth.GetToken())
        return () => {
            runInAction(() => {
                storeElement.cardTitle.titleStep1 = ''
                storeElement.settingCard.padding = ''
            })
        }
    }, [])

    useEffect(() => {
        if (referType === 'code' && StoreReferral.referralCode.length === 0) {
            try {
                StoreReferral.GetReferralCode(StoreAuth.GetToken())
            } catch (e) {
                console.log(e)
            }
        }
    }, [referType])

    const CopyToClipboard = () => {
        if (!StoreAuth.GetId()) return
        if (!StoreReferral.loadingReferCode) {
            if (referType === 'link') {
                copy(`https://ufeelgreat.com/c/${StoreReferral.referralCode}`)
            } else {
                copy(StoreReferral.referralCode)
            }
            setIsCopied(true)
            setTimeout(() => {
                setIsCopied(false)
            }, 5000)
        }
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <ReferralLinks />
        </ThemeProvider>
    )
})

const InviteContentWrapper = styled(Box)`
    @media screen and (min-width: 1180px) {
        display: flex;
    }

    @media screen and (min-width: 1025px) {
        flex-direction: row;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`

const UniqueLinkStyled = styled(Box)`
    box-sizing: border-box;
    margin: 0 !important;
    border: 1px solid rgba(0, 55, 100, 0.1);
    border-radius: 8px;
    height: 60px;
    background-color: ${({ isCopied }) => (isCopied ? '#E5FBEE' : '#ecf2f9')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;

    &.feelgreate--share-box-saved {
        background: #f1faf7;
        border: 1px solid rgba(0, 55, 100, 0.1);
    }

    .feelgreat--share-box {
        div {
            &:first-of-type {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                color: #33628c;
            }
            &:last-of-type {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #33628c;
                font-weight: bold;
            }
        }
    }
    .icon-box {
        width: 25px;
        height: 25px;
        svg {
            circle {
                stroke: #33628c;
            }
            path {
                stroke: #33628c;
            }
        }
    }
`

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

export default Invite
