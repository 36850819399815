import styled from '@emotion/styled'
import { Box, Stack, Modal, Typography, Fade, Card, CardContent } from '@mui/material'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import Loader from '../Assets/Loader.svg'
import { ReactComponent as CheckedIcon } from '../Assets/check-green.svg'
import { ReactComponent as TimesIcon } from '../Assets/times.svg'

import Errors from 'Components/Elements/Errors/Errors'
import { DefaultButton } from 'Components/Elements/Forms'
import { runInAction, toJS } from 'mobx'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'

const WorldPay = observer(props => {
    const { open = false, onClose = () => { } } = props

    const { width } = useSizeWindow()
    const translate = useTranslate()

    // const [openWorldPayModal, setOpenWorldPayModal] = useState(false)

    const [frameElement, setFrameElement] = useState(null)
    const [countPathChange, setCountPathChange] = useState(0)
    const handleFrameElement = useCallback(e => {
        setFrameElement(e.target)
    }, [])

    // useEffect(() => {
    //     console.log('HOOK ', frameElement?.contentDocument?.body?.innerHTML?.length)
    // }, [frameElement])

    useEffect(() => {
        StoreCheckout.SetWorldPayReq({
            countPathChange: countPathChange
        })
    }, [countPathChange])

    const handleCloseModal = () => { }

    const PaymentStepTranslate = (key, step) => {
        let keyText = key
        if (key === 'payment') {
            keyText = 'loading_text_payment_data'
        } else if (key === 'createOrder') {
            keyText = 'loading_text_order_payment'
        } else if (key === 'updateLog') {
            keyText = 'loading_text_log'
        } else if (key === 'autoship') {
            keyText = 'loading_text_autoship'
        } else if (key === 'worldPayReq') {
            keyText = 'loading_text_req_ddc'
        }

        const creatingText = 'loading_text_creating'
        const textSuccess = 'loading_text_success'
        const textFailed = 'loading_text_failed'

        switch (step) {
            case -1:
                return (
                    <>
                        <Typography fontSize={'24px'} color="#ffffff">
                            <T>{keyText}</T> <T>{textFailed}</T>
                        </Typography>
                    </>
                )
            case 0:
                return (
                    <>
                        <Typography fontSize={'24px'} color="#ffffff">
                            <T>{creatingText}</T> <T>{keyText}</T>
                        </Typography>
                    </>
                )
            case 1:
                return (
                    <>
                        <Typography fontSize={'24px'} color="#ffffff">
                            <T>{keyText}</T> <T>{textSuccess}</T>
                        </Typography>
                    </>
                )
        }
    }

    const ProcessingColor = step => {
        if (step === 1) {
            return 'process-success'
        } else if (step === -1) {
            return 'process-failed'
        }
    }

    const ProcessingIcon = step => {
        switch (step) {
            case 0:
                return <Spiner src={Loader} alt="Loading" style={{ marginLeft: 5 }} />
            case 1:
                return <CheckedIcon />
            case -1:
                return <TimesIcon />
        }
    }

    return (
        <Stack>
            <Modal
                disableEscapeKeyDown={true}
                open={open}
                // onClose={() => onClose(!open)}
                aria-labelledby="worldpay-modal-modal-title"
                aria-describedby="worldpay-modal-modal-description">
                <Stack display={'flex'} alignItems={'center'} height="100%" justifyContent={'center'} padding={width < 730 ? '0 16px' : '0 47px'}>
                    <BackdropBodyStyled style={{ display: !StoreCheckout.challengeHTML ? 'block' : StoreCheckout.countPathChange < 2 ? 'none' : 'block' }}>
                        <Typography variant="h2" textAlign={'left'} fontSize={'30px'} color="#ffffff">
                            World Pay Processing
                        </Typography>
                        {StorePaymentStep.GetPaymentSteps() && (
                            <ProcessingStyled>
                                {StorePaymentStep.GetPaymentSteps().payment > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().payment)}>
                                        {PaymentStepTranslate('payment', StorePaymentStep.GetPaymentSteps().payment)}{' '}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().payment)}
                                    </li>
                                )}
                                {StorePaymentStep.GetPaymentSteps().worldPayReq > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().worldPayReq)}>
                                        {PaymentStepTranslate('worldPayReq', StorePaymentStep.GetPaymentSteps().worldPayReq)}{' '}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().worldPayReq)}
                                    </li>
                                )}

                                {StorePaymentStep.GetPaymentSteps().createOrder > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().createOrder)}>
                                        {PaymentStepTranslate('createOrder', StorePaymentStep.GetPaymentSteps().createOrder)}{' '}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().createOrder)}
                                    </li>
                                )}
                                {StorePaymentStep.GetPaymentSteps().autoship > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().autoship)}>
                                        {PaymentStepTranslate('autoship', StorePaymentStep.GetPaymentSteps().autoship)}{' '}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().autoship)}
                                    </li>
                                )}
                                {StorePaymentStep.GetPaymentSteps().updateLog > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().updateLog)}>
                                        {PaymentStepTranslate('updateLog', StorePaymentStep.GetPaymentSteps().updateLog)}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().updateLog)}
                                    </li>
                                )}
                            </ProcessingStyled>
                        )}
                        {StorePaymentStep.PaymentErrors().length > 0 ? <Errors list={StorePaymentStep.PaymentErrors()} /> : null}
                        {StorePaymentStep.PaymentErrors().length > 0 ? (
                            <Stack mt="40px" display={'flex'} alignItems={'center'}>
                                <Box width="40%">
                                    <DefaultButton
                                        titleText={translate('CLOSE')}
                                        onClick={() => {
                                            onClose()
                                            runInAction(() => {
                                                StoreCheckout.ResetWorldPayData()
                                                storeElement.showCardFrontDrop = false
                                            })
                                        }}
                                    />
                                </Box>
                            </Stack>
                        ) : null}

                        {/* iframe For WorldPay */}
                        {StoreCheckout.ddcHTML && (
                            <iframe
                                srcDoc={StoreCheckout.ddcHTML}
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                onLoad={handleFrameElement}
                                style={{ display: 'none' }}
                            />
                        )}
                        {/* iframe For WorldPay */}
                    </BackdropBodyStyled>

                    {StoreCheckout.challengeHTML && (
                        <Box
                            sx={{
                                margin: '0px 0px 20px 0px',
                                backgroundColor: '#fff',
                                display: !StoreCheckout.challengeHTML ? 'none' : StoreCheckout.countPathChange < 2 ? 'block' : 'none'
                            }}>
                            <iframe
                                srcDoc={StoreCheckout.challengeHTML}
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                onLoad={e => {
                                    handleFrameElement(e)
                                    setCountPathChange(cpc => {
                                        return cpc + 1
                                    })
                                }}
                                width="100%"
                                height="600"
                                style={{
                                    border: "0"
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </Modal>
        </Stack>
    )
})

const BackdropBodyStyled = styled.div`
    padding: 40px 24px;
    background: #003b6f;
    border-radius: 8px;

    @media screen and (min-width: 1024px) {
        width: 650px;
    }
`
const ProcessingStyled = styled.ul`
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 0px;
    li {
        color: #ffffff;
        list-style-type: none;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: auto 35px;
        align-items: center;
        padding-left: 0px;

        svg {
            width: 35px;
        }

        &:last-child {
            padding-bottom: 0px;
        }

        &.process-success {
            color: #03c302;
        }

        &.process-failed {
            color: rgb(254, 93, 124);
        }
    }
`

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

export default WorldPay
