import { ThemeProvider } from '@emotion/react'
import { Grid, Stack, Typography, createTheme, styled } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import React, { Children, useEffect, useState } from 'react'
import { ReactComponent as SpinnerIcon } from './../Assets/Spinner.svg'
import storeUser from 'GlobalStores/User/StoreUser'
import DefaultInput from 'Components/Elements/Invite/ReferralLinks/ChangeEnrollModal/DefaultInput'
import { DefaultButton } from 'Components/Elements/Forms'
import storeAuth from 'GlobalStores/User/StoreAuth'
import userAPI from 'GlobalStores/User/APIs'

const CustomDisplayName = observer(props => {
    const { preferredName, isLoading } = props

    const [isChangeMode, setIsChangeMode] = useState(false)
    const [valueInput, setValueInput] = useState({ firstName: '', lastName: '' })
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)
    const [haveBadWord, setHaveBadWord] = useState(false)
    const [disabledBtn, setDisabledBtn] = useState(true)

    const { width } = useSizeWindow()
    const translate = useTranslate()
    const Filter = require('bad-words'), filterBadWord = new Filter()

    const onToggleChangeMode = () => {
        setIsChangeMode(!isChangeMode)
        setHaveBadWord(false)
        getInitDataInput()
        setError([])
    }

    const onChangeText = e => {
        const { value, name } = e.target

        const haveBadWord = filterBadWord.list.some(badWord => value.toLowerCase().includes(badWord))
        if (haveBadWord) {
            setError(['not_allow_bad_word'])
            setHaveBadWord(true)
        } else {
            setError([])
            setHaveBadWord(false)
        }

        setValueInput({
            ...valueInput,
            [name]: value
        })
    }

    const trimAfterSave = () => {
        const { firstName, lastName } = valueInput
        setValueInput({
            firstName: firstName.trim(),
            lastName: lastName.trim()
        })
    }

    const onSubmitSave = async () => {
        setLoading(true)
        setError([])

        try {
            if (haveBadWord === true) {
                setLoading(false)
                throw new Error("not_allow_bad_word")
            }

            const { firstName, lastName } = valueInput
            const trimmedFirstName = firstName.trim()
            const trimmedLastName = lastName.trim()

            const existingCustomerSite = await storeAuth.getCustomerSite()

            if (existingCustomerSite.isSuccess === false) {
                throw new Error(translate("error_display_name_failed"))
            }

            const existingAliasName = existingCustomerSite?.data?.alias || null

            const aliasName = handleNewAliasName({ existingAliasName })

            const updateDisplayNameResult = await storeAuth.updateDisplayName({
                alias: aliasName,
                firstName: trimmedFirstName,
                lastName: trimmedLastName
            })

            if (updateDisplayNameResult.isSuccess === false) {
                throw new Error(translate("error_display_name_failed"))
            }

            const userResponse = await storeAuth.getCustomerMenu()

            const ushop = userResponse?.onself?.ushop

            ushop.profile.preferredName = {
                firstName: trimmedFirstName,
                lastName: trimmedLastName
            }

            storeUser.setUserData(ushop)

            trimAfterSave()

            await userAPI.clearEtlV2Cache(storeAuth.GetId())

            setLoading(false)
            setIsChangeMode(!isChangeMode)
            setHaveBadWord(false)
        } catch (err) {
            console.error(err)
            setError([err.message === "not_allow_bad_word" ? "not_allow_bad_word" : "update_display_name_failed"])
            setLoading(false)
        }
    }

    const handleNewAliasName = ({ existingAliasName }) => {
        if (existingAliasName === null) {
            return `${storeAuth.GetId()}-${storeAuth.GetUserCountry()}`
        } else {
            return existingAliasName
        }
    }

    const isEmptyValue = (value) => {
        return (
            value === undefined ||
            value === null ||
            (typeof value === 'string' && value.trim() === 'null') ||
            (typeof value === 'string' && value.trim() === 'undefined') ||
            (typeof value === 'string' && value.trim().length === 0) ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' && value !== null && Object.keys(value).length === 0)
        )
    }

    const getInitDataInput = () => {
        const olderFirstName = storeUser.CustomerData().HumanName()?.firstName || ''
        const olderLastName = storeUser.CustomerData().HumanName()?.lastName || ''
        const preferredNameFirstName = preferredName?.firstName || ''
        const preferredNameLastName = preferredName?.lastName || ''

        if (isEmptyValue(preferredName)) {
            setValueInput({
                firstName: olderFirstName,
                lastName: olderLastName
            })
        } else {
            setValueInput({
                firstName: preferredNameFirstName,
                lastName: preferredNameLastName
            })
        }
    }

    const handleDisableSaveButton = () => {
        let { firstName, lastName } = valueInput
        firstName = firstName.replace(/\s/g, '')
        lastName = lastName.replace(/\s/g, '')

        if (firstName.length < 2 || lastName.length < 2) {
            setDisabledBtn(true)
        } else {
            setDisabledBtn(false)
        }
    }

    useEffect(() => {
        getInitDataInput()
    }, [preferredName])

    useEffect(() => {
        handleDisableSaveButton()
    }, [valueInput])



    return (
        <ThemeProvider theme={customTheme}>
            <Item
                item
                sx={{
                    padding: {
                        xs: '25px 0px',
                        md: '24px 0px'
                    },
                }}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center'
                    }}>

                    <Grid item className="item-list" sx={{ width: '100%' }}>
                        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Stack>
                                <Typography variant="label" fontSize={width < 830 ? '16px' : '16px'}>
                                    <T>change_display_name</T>
                                </Typography>
                                <Typography variant="value" mt={'12px'} fontSize={'14px'} fontWeight={400}>
                                    <T>display_name_caption</T>
                                </Typography>
                            </Stack>

                            <Stack
                                item
                                sx={{
                                    alignItems: 'flex-end',
                                    width: '80px',
                                }}>
                                {isLoading ? (
                                    <SpinnerIcon fontSize={'25px'} className="spinning" />
                                ) : (
                                    <Typography
                                        variant="link"
                                        fontSize={width < 830 ? '14px' : '16px'}
                                        fontWeight={500}
                                        color={isChangeMode === true && '#c2c2c2'}
                                        onClick={isChangeMode === false ? onToggleChangeMode : null}
                                        sx={{ cursor: 'pointer' }}>
                                        <T>change</T>
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>

                        <Typography
                            variant="value"
                            mt={{
                                xs: '24px',
                                md: '16px'
                            }}
                            fontSize={'24px'} fontWeight={600} lineHeight={'30px'} color={'#003B6F'}
                        >
                            {storeUser.CustomerData().DisplayName()}
                        </Typography>
                    </Grid>

                    {isChangeMode && (
                        <Grid sx={{ marginTop: '24px', width: '100%', transition: '200ms linear 50ms' }}>
                            <Stack sx={{ gap: '24px', flexDirection: width < 600 ? 'column' : 'row' }}>
                                {Object.keys(valueInput).map((key, index) => {
                                    let translated_text = ""
                                    if (key === "firstName") {
                                        translated_text = "firstname_change_display_name"
                                    } else if (key === "lastName") {
                                        translated_text = "lastname_change_display_name"
                                    }
                                    return (
                                        <DefaultInputStyled
                                            key={index}
                                            name={key}
                                            placeholder={translate(translated_text)}
                                            onChange={onChangeText}
                                            value={valueInput[key]}
                                        />
                                    )
                                })}
                            </Stack>

                            {error.length > 0 && (
                                <Stack mt={'12px'}>
                                    {error.map((err, index) => (
                                        <Typography key={index} variant="value" fontSize={'14px'} color={'#FF0000'}>
                                            <T>{err}</T>
                                        </Typography>
                                    ))}
                                </Stack>
                            )}

                            <Stack sx={{ marginTop: '32px', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography
                                    variant="link"
                                    fontSize={'16px'}
                                    fontWeight={400}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={onToggleChangeMode}
                                >
                                    <T>cancel</T>
                                </Typography>
                                <DefaultButtonStyled
                                    isloading={loading.toString()}
                                    onClick={onSubmitSave}
                                    width={width < 700 ? '275px' : '280px'}
                                    titleText={translate('save')}
                                    disabled={disabledBtn}
                                />
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </Item>
        </ThemeProvider>
    )
})

const Item = styled(Grid)`
    border-bottom: 1px solid #CCDEEF; 
    display: flex;
    :first-of-type {
        padding: 0px 0px 24px 0px;
    }
    :last-of-type {
        border-bottom: none;
        margin-bottom: 0px;
        padding: 24px 0px 0px 0px;
    }
    height: fit-content;
    .item-list {
        display: flex;
        flex-direction: column;
    }
    .spinning {
        animation: spin 1s infinite;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#153862'
        },
        secondary: {
            main: '#ffffff'
        },
        background: {
            default: '#D1D9E2',
            main: '#D1D9E2'
        },
        button: {
            main: '#5A8FC4',
            disabled: 'rgba(90, 143, 196, .3)'
        },
        flavor: {
            main: '#023764'
        }
    },
    typography: {
        label: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: {
                xs: '16px',
                sm: '16px'
            },
            color: '#003B6F'
        },
        value: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: {
                xs: '14px',
                sm: '14px',
                md: '14px'
            },
            color: '#8699AF'
        },
        link: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: {
                xs: '14px',
                sm: '14px',
                md: '18px'
            },
            textDecoration: 'underline',
            color: '#5A8FC3',
            cursor: 'pointer, NotoSansThai'
        }
    }
})

const DefaultInputStyled = styled(DefaultInput)`
    width: 100% !important;

    .MuiInputBase-input {
        height: 50px;
        border: 1px solid #bdd2e7;
        border-radius: 8px;
        color: #003B6F !important;

        &:focus {
            outline: none;
            border: 1px solid #003B6F;
        }

        &::placeholder {
            color: #5A8FC3;
        }
    }
`

const DefaultButtonStyled = styled(DefaultButton)`
    background: #5A8FC3;
    color: #FFFFFF;
    width: 180px;
    font-family: Poppins, NotoSansThai;

    @media screen and (min-width: 536px) {
        width: 200px;
    }
`

export default CustomDisplayName