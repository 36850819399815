import { Stack, ThemeProvider } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { observer } from 'mobx-react-lite'
import MainLayout from '../../Layouts/MainLayout'

import Banner from './Components/Banner/Banner'
import HowItWorks from './Components/HowItWorks/HowItWorks'
import OurApproach from './Components/OurApproach/OurApproach'
import RealResults from './Components/RealResults/RealResults'
import ThePractice from './Components/ThePractice/ThePractice'
import TheProducts from './Components/TheProducts/TheProducts'
import WhatExpertsSay from './Components/WhatExpertsSay/WhatExpertsSay'
import { toJS } from 'mobx'
import JPNFRModal from './Components/JPNFRModal/JPNFRModal'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { get } from 'lodash'

const Landing = observer(() => {
    // const ourApproach = StoreFeelGreat.GetContent('ourApproach')
    // const theProducts = StoreFeelGreat.GetContent('theProducts')
    // const thePractice = StoreFeelGreat.GetContent('thePractice')
    // const howItWorks = StoreFeelGreat.GetContent('howItWorks')
    // const expertsSay = StoreFeelGreat.GetContent('expertsSay')
    // const realResults = StoreFeelGreat.GetContent('realResults')
    // const mainBanner = StoreFeelGreat.GetContent('mainBanner')
    const allLayoutComponent = storeCheckout.CheckOutInstance().GetLandingLayout() || []

    const isShowJPNFRModal = () => {
        const isSeenModal = !!localStorage.getItem('isSeenNFRModal')
        return storeCountry.Country3() === 'XJP' && isSeenModal === false
    }

    const components = {
        banner: Banner,
        ourApproach: OurApproach,
        theProducts: TheProducts,
        thePractice: ThePractice,
        howItWorks: HowItWorks,
        whatExpertsSay: WhatExpertsSay,
        realResults: RealResults
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack>
                {/* {isShowJPNFRModal() && <JPNFRModal />} */}
                {allLayoutComponent.map(component => {
                    const SectionComponent = components[component.nameComponent]
                    const dataContent = toJS(StoreFeelGreat.GetContent(component.titleContent))
                    const isEnableSection = get(dataContent, 'isEnable')

                    return (
                        <MainLayout isEnable={dataContent && isEnableSection} style={{ ...component.style }}>
                            <SectionComponent data={dataContent} />
                        </MainLayout>
                    )
                })}
            </Stack>
        </ThemeProvider>

        // **** Note: Old code before dynamic layout **** //
        // <ThemeProvider theme={MainTemplate}>
        //     <Stack>
        //         {isShowJPNFRModal() && <JPNFRModal />}
        //         <MainLayout isEnable={mainBanner && mainBanner.isEnable}>
        //             <Banner />
        //         </MainLayout>
        //         <MainLayout isEnable={ourApproach && ourApproach.isEnable} minHeight="0px">
        //             <OurApproach data={ourApproach} />
        //         </MainLayout>
        //         <MainLayout isEnable={theProducts && theProducts.isEnable} background="#F1F6FB">
        //             <TheProducts data={theProducts} />
        //         </MainLayout>
        //         <MainLayout isEnable={thePractice && thePractice.isEnable}>
        //             <ThePractice data={thePractice} />
        //         </MainLayout>
        //         <MainLayout isEnable={howItWorks && howItWorks.isEnable} background="#FBF7F1">
        //             <HowItWorks data={howItWorks} />
        //         </MainLayout>
        //         <MainLayout isEnable={expertsSay && expertsSay.isEnable}>
        //             <WhatExpertsSay data={expertsSay} />
        //         </MainLayout>
        //         <MainLayout isEnable={realResults && realResults.isEnable} background="#F2F9F8">
        //             <RealResults data={realResults} />
        //         </MainLayout>
        //     </Stack>
        // </ThemeProvider>
    )
})

export default Landing
