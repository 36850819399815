import { Stack, Typography } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import copy from 'copy-to-clipboard'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import ClipboardIcon from './ClipboardIcon'
import styled from '@emotion/styled'
import storeAuth from 'GlobalStores/User/StoreAuth'
import ChangeEnrollModal from './ChangeEnrollModal/ChangeEnrollModal'
import { ReactComponent as CopiedIcon } from './copied.svg'

const ushopLinkEnrollUrlInit = 'https://ushop-link.unicity.com/th/enroll'

const EnrollLinkBox = observer(() => {
    const translate = useTranslate()
    const [currentID, setCurrentID] = useState({
        id: storeAuth.GetId(),
        type: 'enroller'
    })
    const [enrollCurrentID, setEnrollCurrentID] = useState(storeAuth.GetId())
    const [sponsorCurrentID, setSponsorCurrentID] = useState('')

    const [isCopied, setIsCopied] = useState(false)

    const handleCopy = () => {
        if (!isCopied) {
            copy(`${ushopLinkEnrollUrlInit}/${currentID.id}${sponsorCurrentID.length > 0 ? `/${sponsorCurrentID}` : ''}`)
            setIsCopied(true)
            setTimeout(() => {
                setIsCopied(false)
            }, 5000)
        }
    }

    const confirmCurrentId = (newId, newSponsorId, newType) => {
        setCurrentID({
            id: newId,
            type: newType
        })

        if (newType === 'enroller') {
            setEnrollCurrentID(newId)
            setSponsorCurrentID('')
        } else {
            setEnrollCurrentID(newId)
            setSponsorCurrentID(newSponsorId)
        }
    }

    const propStates = {
        currentID,
        enrollCurrentID,
        sponsorCurrentID
    }

    const propFunctions = {
        confirmCurrentId
    }
    return (
        <LinkActionStack mb="20px">
            <Stack
                direction={{
                    xs: 'column',
                    md: 'row'
                }}
                width={'100%'}
                gap={{
                    xs: '0px',
                    md: '20px'
                }}>
                <Stack width={'100%'} maxWidth={'500px'}>
                    <LinkWrapper>
                        <Typography
                            variant="subtitle1"
                            fontSize={'14px'}
                            component="a"
                            href={`${ushopLinkEnrollUrlInit}/${currentID.id}${sponsorCurrentID.length > 0 ? `/${sponsorCurrentID}` : ''}`}
                            style={{ textDecoration: 'none' }}>{`${ushopLinkEnrollUrlInit}/${currentID.id}${
                            sponsorCurrentID.length > 0 ? `/${sponsorCurrentID}` : ''
                        }`}</Typography>
                        <div className="icon-container" onClick={handleCopy}>
                            {isCopied ? <CopiedIcon /> : <ClipboardIcon />}
                        </div>
                    </LinkWrapper>
                    <Stack
                        style={{ visibility: isCopied ? 'visible' : 'hidden', justifyContent: 'flex-end', textAlign: 'right' }}
                        sx={{
                            display: {
                                xs: 'none',
                                md: 'flex'
                            }
                        }}>
                        <Typography variant="error" component="span" color={'#FF820E'}>
                            {translate(`copied`)}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    marginTop={{
                        xs: '15px',
                        md: '0px'
                    }}>
                    <Stack>
                        <ChangeEnrollModal propStates={propStates} propFunctions={propFunctions} />
                    </Stack>
                    <Stack style={{ visibility: isCopied ? 'visible' : 'hidden', justifyContent: 'flex-end', textAlign: 'right' }}>
                        <Typography
                            variant="error"
                            component="span"
                            color={'#FF820E'}
                            sx={{
                                display: {
                                    xs: 'flex',
                                    sm: 'none'
                                }
                            }}>
                            {translate(`copied`)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </LinkActionStack>
    )
})

export default EnrollLinkBox

const LinkWrapper = styled(Stack)`
    width: auto;
    max-width: 500px;
    padding: 10px 15px;
    color: #003b6f;
    font-size: 14px;
    font-weight: 400;

    background-color: #f6f9fd;
    border: 1px solid #bdd2e7;
    border-radius: 8px;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .icon-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            width: 18px;
            height: 20px;

            :hover {
                filter: brightness(0.8);
            }
        }
    }

    & a:hover {
        text-decoration: underline !important;
        color: #003b6f !important;
    }

    & a:visited {
        color: rgb(11, 59, 111);
    }
`
const LinkActionStack = styled(Stack)`
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`
