import { formToJSON } from 'Helpers/GlobalHelpers'
import { useLayoutEffect } from 'react'

const RedirectPaymentGateway = ({ dataPayment }) => {
    useLayoutEffect(() => {
        const form = document.getElementById('payment-gateway-form')
        const elements = form.elements

        let formJson = formToJSON(elements)

        delete formJson['']

        form.submit()
    }, [])

    return (
        <>
            <form method="POST" name="ePayment" id="payment-gateway-form" className="form" action={dataPayment.payment_action_url}>
                {Object.keys(dataPayment).map(key => {
                    if (key === 'payment_action_url') return

                    return <input type="hidden" name={key} value={dataPayment[key]} key={key} />
                })}
            </form>
        </>
    )
}

export default RedirectPaymentGateway
