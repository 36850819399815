import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
// import moment from 'moment'

class storeSubScriptions {
    subscriptionsList = []
    nextDateDelivery = ''
    selectedFlavor = ''
    flavorItemCode = ''
    qty = 0
    price = 0
    dateOfmonth = ''
    forms = []
    shippingForms = []
    creditCard = []
    fontSize = ''
    orderSpec = null
    scrollY = 0

    isReadyToPlaceOrder = false

    //set

    Init(data) {
        Object.keys(data).map(key => {
            runInAction(() => {
                if (key === 'shippingAddress') {
                    this.forms = data[key].inputs
                    this.shippingForms = data[key].inputs
                }
                if (key === 'order') {
                    this.orderSpec = data[key]
                }
            })

            return true
        })
    }

    IsReadyToPlaceOrder() {
        let allFilled = []
        this.creditCard.map(item => {
            if (item.value.length > 0) {
                allFilled.push(true)
            } else {
                allFilled.push(false)
            }
            return item
        })

        this.isReadyToPlaceOrder = !ObjectHelpers.hasFalseElements(allFilled)
    }

    SetCreditCard(itemToSet, value) {
        this.creditCard.map(item => {
            if (item.key === itemToSet.key) {
                if (value.length === 0) {
                    itemToSet.value = ''
                } else {
                    if (item.args) {
                        const regx = get(itemToSet, 'args.regx', null)
                        let regexPass = false
                        if (regx) {
                            const regex = new RegExp(regx, 'i')
                            if (regex.test(value)) {
                                regexPass = true
                            }
                        } else {
                            regexPass = true
                        }

                        const maxLength = get(itemToSet, 'args.maxLength', null)
                        let maxLengthPass = false
                        if (maxLength) {
                            if (value.length <= maxLength) {
                                maxLengthPass = true
                            }
                        } else {
                            maxLengthPass = true
                        }

                        if (regexPass && maxLengthPass) {
                            item.value = value
                        }
                    } else if (itemToSet.key === 'exp_date') {
                        // item.value = moment(value).format('MM/YY')
                        item.value = [value.month, value.year.slice(2, value.year.length)].join('/')
                    } else {
                        item.value = value
                    }
                }
            }

            return item
        })
        this.IsReadyToPlaceOrder()
    }

    SetCheckoutFormValue(key, value) {
        this.forms.map(item => {
            if (item.key === key) {
                runInAction(() => {
                    item.value = value
                })
            }
            return item
        })
        this.shippingForms.map(item => {
            if (item.key === key) {
                runInAction(() => {
                    item.value = value
                })
            }
            return item
        })
    }

    SetSelectedFlavor(value) {
        runInAction(() => {
            this.selectedFlavor = value
        })
    }

    SetFlavorPrice(value) {
        runInAction(() => {
            this.price = value
        })
    }

    SetSubscriptionsList(value) {
        runInAction(() => {
            this.subscriptionsList = value
        })
    }

    SetFlavorQTY(value) {
        runInAction(() => {
            this.qty = value
        })
    }

    SetFlavorItemCode(value) {
        runInAction(() => {
            this.flavorItemCode = value
        })
    }

    SetDateOfmonth(value) {
        runInAction(() => {
            this.dateOfmonth = value
        })
    }

    //get
    GetCreditCardForm() {
        return this.creditCard
    }

    GetCheckoutForms() {
        return this.forms
    }

    GetShippingForm() {
        return this.shippingForms
    }

    GetFlavorItemCode() {
        return this.flavorItemCode
    }

    GetFlavorQTY() {
        return this.qty
    }

    GetFlavorPrice() {
        return this.price
    }

    GetSelectedFlavor() {
        return this.selectedFlavor
    }

    GetSubscriptionsList() {
        return this.subscriptionsList
    }

    GetNextDateDelivery() {
        return this.nextDateDelivery
    }

    GetDateOfmonth() {
        return this.dateOfmonth
    }

    constructor() {
        makeAutoObservable(this)
    }
}
const StoreSubScriptions = new storeSubScriptions()
export default StoreSubScriptions