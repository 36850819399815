import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

const PickerColumn = observer(({ options, name, value, itemHeight, columnHeight, onChange, tabIndex, propsValue }) => {
    const [isMoving, setIsMoving] = useState(false)
    const [startTouchY, setStartTouchY] = useState(0)
    const [startScrollerTranslate, setStartScrollerTranslate] = useState(0)
    const [scrollerTranslate, setScrollerTranslate] = useState(0)
    const [minTranslate, setMinTranslate] = useState(0)
    const [maxTranslate, setMaxTranslate] = useState(0)
    const [isDragging, setIsDragging] = useState(false)

    const computeTranslate = () => {
        const hasPropsValueAndNoValue = propsValue.length && value.length === 0
        let selectedIndex = options.indexOf(hasPropsValueAndNoValue ? propsValue : value)

        if (hasPropsValueAndNoValue) {
            onChange(name, propsValue)
        } else {
            if (selectedIndex < 0) {
                onChange(name, options[0])
                selectedIndex = 0
            }
        }
        setScrollerTranslate(columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight)
        setMinTranslate(columnHeight / 2 - itemHeight * options.length + itemHeight / 2)
        setMaxTranslate(columnHeight / 2 - itemHeight / 2)
    }

    useEffect(() => {
        computeTranslate()
    }, [options, value, itemHeight, columnHeight])

    const onValueSelected = newValue => {
        onChange(name, newValue)
    }

    const handleTouchStart = event => {
        const startY = event.targetTouches[0].pageY
        setStartTouchY(startY)
        setStartScrollerTranslate(scrollerTranslate)
        setIsMoving(true)
    }

    const handleTouchMove = event => {
        event.preventDefault()
        if (!isMoving) {
            return
        }
        const touchY = event.targetTouches[0].pageY
        let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY
        if (nextScrollerTranslate < minTranslate) {
            nextScrollerTranslate = minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8)
        } else if (nextScrollerTranslate > maxTranslate) {
            nextScrollerTranslate = maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8)
        }
        setScrollerTranslate(nextScrollerTranslate)
    }

    const handleTouchEnd = () => {
        if (!isMoving) {
            return
        }
        setIsMoving(false)
        setTimeout(() => {
            postMove()
        }, 0)
    }

    const handleTouchCancel = () => {
        if (!isMoving) {
            return
        }
        setIsMoving(false)
        setScrollerTranslate(startScrollerTranslate)
    }

    const handleItemClick = option => {
        if (option !== value) {
            onValueSelected(option)
        }
    }

    const postMove = () => {
        let activeIndex
        if (scrollerTranslate > maxTranslate) {
            activeIndex = 0
        } else if (scrollerTranslate < minTranslate) {
            activeIndex = options.length - 1
        } else {
            activeIndex = -Math.floor((scrollerTranslate - maxTranslate) / itemHeight)
        }
        if (options[activeIndex] !== value) {
            onValueSelected(options[activeIndex])
        }
    }

    const postWheel = nextScrollerTranslate => {
        let activeIndex = 0
        if (nextScrollerTranslate > maxTranslate) {
            activeIndex = 0
        } else if (nextScrollerTranslate < minTranslate) {
            activeIndex = options.length - 1
        } else {
            activeIndex = -Math.floor((nextScrollerTranslate - maxTranslate) / itemHeight)
        }

        setTimeout(() => {
            if (options[activeIndex] !== value) {
                onValueSelected(options[activeIndex])
            }
        }, 50)
    }

    const handleScroll = event => {
        let deltaY

        if (!!event.deltaY) {
            deltaY = event.deltaY > 0 ? itemHeight * -1 : itemHeight
        } else {
            deltaY = 0
        }

        let nextScrollerTranslate = scrollerTranslate + deltaY
        if (nextScrollerTranslate < minTranslate) {
            nextScrollerTranslate = minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8)
        } else if (nextScrollerTranslate > maxTranslate) {
            nextScrollerTranslate = maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8)
        }

        if (nextScrollerTranslate < minTranslate) nextScrollerTranslate = minTranslate
        if (nextScrollerTranslate > maxTranslate) nextScrollerTranslate = maxTranslate

        setScrollerTranslate(nextScrollerTranslate)
        setStartScrollerTranslate(nextScrollerTranslate)
        setStartTouchY(event.pageY)

        postWheel(nextScrollerTranslate)
    }

    const renderItems = () => {
        return options.map((option, index) => {
            const style = {
                height: itemHeight + 'px',
                lineHeight: itemHeight + 'px'
            }
            const className = ` picker-item${option === value ? ' picker-item-selected' : ''}`
            return (
                <div key={index} className={className} style={style} onClick={() => handleItemClick(option)} data-index={index}>
                    {option}
                </div>
            )
        })
    }

    const translateString = `translate3d(0, ${scrollerTranslate}px, 0)`
    const style = {
        MsTransform: translateString,
        MozTransform: translateString,
        OTransform: translateString,
        WebkitTransform: translateString,
        transform: translateString
    }
    if (isMoving) {
        style.transitionDuration = '0ms'
    }
    return (
        <div className="picker-column" onWheel={handleScroll}>
            <div
                tabIndex={tabIndex}
                className="picker-scroller"
                style={style}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchCancel}>
                {renderItems()}
            </div>
        </div>
    )
})

export default PickerColumn
