import { IconButton } from '@mui/material'
import { styled } from '@mui/system'
import StoreUser from 'GlobalStores/User/StoreUser'
import { observer } from 'mobx-react-lite'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ProfileIcon } from './Assets/profile.svg'
import { toJS } from 'mobx'

const ProfileButton = observer(props => {
    const navigate = useNavigate()
    const [imageFaild, setImageFaild] = useState(false)

    useEffect(() => {
        let profileImg = toJS(StoreUser.CustomerData().ProfilePicture('original'))
        if (profileImg === '' || typeof profileImg === 'undefined') {
            setImageFaild(true)
        } else {
            setImageFaild(false)
        }
    }, [StoreUser.CustomerData().ProfilePicture('original')])
    return (
        <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
                if (!StoreUser.userData) {
                    navigate('login')
                } else {
                    navigate('user', { replace: true })
                }
            }}
            color="inherit"
            style={{ padding: 0 }}>
            {StoreUser.CustomerData().ProfilePicture() ? (
                <>{!imageFaild ? <UserImage src={StoreUser.CustomerData().ProfilePicture('original')} /> : <ProfileIcon style={{ cursor: 'pointer' }} />}</>
            ) : (
                <ProfileIcon style={{ cursor: 'pointer' }} />
            )}
        </IconButton>
    )
})

const UserImage = styled('img')`
    width: 24px;
    height: 24px;
    background-color: #e5e5e5;
    border-radius: 50%;
    cursor: pointer;
`

export default ProfileButton
