import styled from '@emotion/styled'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'

const CardFrontDrop = observer(() => {
    return <CardFrontDropStyled display={storeElement.showCardFrontDrop ? 'inherit' : 'none'} />
})

const CardFrontDropStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
    display: ${({ display }) => display};
    z-index: 2;
`

export default CardFrontDrop
