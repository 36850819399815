import StoreAuth from 'GlobalStores/User/StoreAuth'
import { observer } from 'mobx-react-lite'
import LoginElem from '../Elements/Login/Login'
import { Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import MobileLayoutWrapper from 'Components/Elements/UserAccountMenu/MobileLayoutWrapper'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import ReferralBar from 'Components/Elements/Referral/ReferralBar'
import storeReferral from 'GlobalStores/Referral/StoreReferral'

const Login = observer(() => {
    const [canSignup, setCanSignup] = useState(true)
    const showReferralBar = ['THA'].includes(storeCountry.Country3()) && storeReferral.errorMessage && !StoreAuth.IsAuthorized()

    useEffect(() => {
        if (['JPN', 'THA'].includes(storeCountry.Country3())) {
            setCanSignup(false)
        }
        if (StoreAuth.IsAuthorized()) {
            return <Navigate to={-1} replace />
        }
    }, [])

    return (
        <>
            {showReferralBar && <ReferralBar />}
            <MobileLayoutWrapper>
                <LoginElem canSignup={canSignup} />
            </MobileLayoutWrapper>
        </>
    )
})

export default Login
