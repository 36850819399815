import Header from 'Components/Elements/Header/Header'
import Footer from 'Components/Elements/Footer/Footer'

import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { storeElement } from 'GlobalStores/StoreElement'
import { reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import ResizeObserver from 'react-resize-observer'
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom'

import Products from '../Components/Pages/Products'

import CheckoutPage from 'Components/Pages/CheckoutPage'
import Index from 'Components/Pages/Index'
import Login from 'Components/Pages/Login'
import ResetPassword from 'Components/Pages/ResetPassword'
import ResetPasswordSuccess from 'Components/Pages/ResetPasswordSuccess'
import NewPassword from 'Components/Pages/NewPassword'
import NewPasswordSuccess from 'Components/Pages/NewPasswordSuccess'

import { useSizeWindow } from './../Helpers/GlobalHelpers'
import UserAccount from '../Components/Pages/UserAccount'
import OrderHistory, { OrderHistoryWrapperMobileLayout } from 'Components/Elements/OrderHistory/OrderHistoryWrapper'
import Subscriptions from 'Components/Elements/Subscriptions/SubscriptionsWrapper'

import PersonalDetailsWrapper from 'Components/Elements/Settings/Wrapper'
import Invite from 'Components/Elements/Invite/Invite'
import { InviteWrapperMobileLayout } from 'Components/Elements/Invite/InviteWrapper'
import PaymentResponseSuccess from 'Components/Pages/PaymentResponseSuccess'
import { useEffect, useState } from 'react'
import SignUp from 'Components/Pages/SignUp'
import SignUpResponse from 'Components/Pages/SignUpResponse'
import Test from 'Components/Pages/Test'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { get, upperCase } from 'lodash'
import PaymentResponseFailed from 'Components/Pages/PaymentResponseFailed'
import PaymentResponseDone from 'Components/Pages/PaymentResponseDone'
import Contact from 'Components/Pages/Contact'
import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import { MenuList } from 'Components/Elements/UserAccountMenu/CardMenu'
import storeUser from 'GlobalStores/User/StoreUser'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'

import axios from 'axios'
import Poc from 'Components/Pages/Poc'
import DirectIntegrationWP from 'Components/Pages/DirectIntegrateWP'
import LoginWrapper from 'Components/Pages/LoginWrapper'
import CustomReferral from 'Components/Elements/Invite/ReferralLinks/CustomReferral/CustomReferral'
import TaxInvoice from 'Components/Elements/Checkout/ShippingAddressForm/Country/Thailand/TaxInvoice/TaxInvoice'
import QRPaymentPage from 'Components/Pages/QRPaymentPage'
import SharingSocial from 'Components/Elements/Invite/ReferralLinks/SharingSocial/SharingSocial'

const AppRouter = observer(() => {
    const { width } = useSizeWindow()
    const [userPath, setUserPath] = useState('/user')
    const [bypassPath, setBypassPath] = useState({ isBypass: false, path: '/' })

    useEffect(() => {
        const qs = new URLSearchParams(window.location.search)
        if (qs.get('lang')) {
            StoreTranslate.SetCurrentLanguage(upperCase(qs.get('lang')))
        }
        // Bypass index page
        const isBypass = get(StoreCheckout.CheckOutInstance().GetBypassPage(), 'isBypass')
        const to = get(StoreCheckout.CheckOutInstance().GetBypassPage(), 'to')

        if (isBypass) {
            setBypassPath({ ...bypassPath, isBypass: true, path: to })
        }

        axios.defaults.headers.common['Accept-Language'] = `${StoreTranslate.CurrentLanguage().toLowerCase()}-${StoreCountry.Country2().toUpperCase()}`

        const disposerLanguageAxios = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    axios.defaults.headers.common['Accept-Language'] = `${n.toLowerCase()}-${StoreCountry.Country2().toUpperCase()}`
                }
            }
        )

        return () => {
            disposerLanguageAxios()
        }
    }, [])

    useEffect(() => {
        const baStatus = storeUser.GetBaStatus()
        if (!baStatus) return

        let JPMenuList = [MenuList[0], MenuList[2], MenuList[1], MenuList[3]]
        const DefaultMenuList = StoreCountry.Country3() === 'JPN' ? JPMenuList : MenuList

        const filteredMenuList = DefaultMenuList.filter(item => {
            const isInvite = item.key === 'invite'
            if (baStatus !== 'H') return true
            return !isInvite
        })
        setUserPath(get(filteredMenuList, '[0].key', '/user'))
    }, [storeUser.GetBaStatus()])

    return (
        <BrowserRouter basename={`${PathHelper.BuildBasename(StoreCountry.CountryLowerCase())}`}>
            <ResizeObserver
                onResize={size => {
                    if (size.width !== storeElement.dynamicWidth) {
                        runInAction(() => {
                            storeElement.dynamicWidth = size.width
                        })
                    }
                }}
            />
            <Header />
            <Routes>
                <Route
                    path={`/:customerId`}
                    element={
                        <LoginWrapper>
                            <Index />
                        </LoginWrapper>
                    }
                />
                <Route
                    path={`/`}
                    element={
                        bypassPath.isBypass ? (
                            <Navigate to={bypassPath.path} replace />
                        ) : (
                            <LoginWrapper>
                                <Index />
                            </LoginWrapper>
                        )
                    }
                />
                <Route path="/products" element={<Products />} />
                <Route path={`/reset-password`} element={<ResetPassword />} />
                <Route path={`/reset-password-success`} element={<ResetPasswordSuccess type={'success'} />} />
                <Route path={`/reset-password-error`} element={<ResetPasswordSuccess type={'error'} />} />
                <Route path={`/new-password/:token`} element={<NewPassword />} />
                <Route path={`/new-password-success`} element={<NewPasswordSuccess type={'success'} />} />
                <Route path={`/new-password-error`} element={<NewPasswordSuccess type={'error'} />} />
                <Route
                    path={`/login`}
                    element={
                        <LoginWrapper>
                            <Login />
                        </LoginWrapper>
                    }
                />
                <Route path={`/sign-up`} element={<SignUp />} />
                <Route path={`/sign-up-success`} element={<SignUpResponse type={'success'} />} />
                <Route path={`/sign-up-error`} element={<SignUpResponse type={'error'} />} />

                <Route
                    path={`/checkout`}
                    element={
                        <LoginWrapper>
                            <CheckoutPage />
                        </LoginWrapper>
                    }
                />
                <Route
                    path={`/checkout/:step`}
                    element={
                        <LoginWrapper>
                            <CheckoutPage />
                        </LoginWrapper>
                    }
                />

                <Route path={`/qr/payment`} element={<QRPaymentPage />} />

                <Route path={'tax-invoice'} element={<TaxInvoice />} />

                <Route path={`/payment-response/success`} element={<PaymentResponseSuccess />} />
                <Route path={`/payment-response/failed`} element={<PaymentResponseFailed />} />
                <Route path={`/payment-response/done`} element={<PaymentResponseDone />} />
                <Route path={'/test'} element={<Test />} />
                <Route path={`/contact-us`} element={<Contact />} />

                {/* user menu */}
                <Route
                    path={`/user/settings/*`}
                    element={
                        <LoginWrapper>
                            <PersonalDetailsWrapper />
                        </LoginWrapper>
                    }
                />

                <Route
                    path={`/user/orderhistory`}
                    element={
                        width <= 834 ? (
                            <LoginWrapper>
                                <OrderHistoryWrapperMobileLayout>
                                    <OrderHistory />
                                </OrderHistoryWrapperMobileLayout>
                            </LoginWrapper>
                        ) : (
                            <LoginWrapper>
                                <UserAccount>
                                    <OrderHistory />
                                </UserAccount>
                            </LoginWrapper>
                        )
                    }
                />

                <Route
                    path={'/user/orderhistory/:order_id'}
                    element={
                        width <= 834 ? (
                            <LoginWrapper>
                                <OrderHistoryWrapperMobileLayout>
                                    <OrderHistory />
                                </OrderHistoryWrapperMobileLayout>
                            </LoginWrapper>
                        ) : (
                            <LoginWrapper>
                                <UserAccount>
                                    <OrderHistory />
                                </UserAccount>
                            </LoginWrapper>
                        )
                    }
                />

                <Route
                    path={`/user/subscriptions/*`}
                    element={
                        width <= 834 ? (
                            <LoginWrapper>
                                <Subscriptions />
                            </LoginWrapper>
                        ) : (
                            <LoginWrapper>
                                <UserAccount>
                                    <Subscriptions />
                                </UserAccount>
                            </LoginWrapper>
                        )
                    }
                />

                <Route
                    path={`/user/invite`}
                    element={
                        width <= 834 ? (
                            <LoginWrapper>
                                <InviteWrapperMobileLayout />
                            </LoginWrapper>
                        ) : (
                            <LoginWrapper>
                                <UserAccount secondChildren={<CustomReferral />} thirdChildren={<SharingSocial />}>
                                    <Invite />
                                </UserAccount>
                            </LoginWrapper>
                        )
                    }
                />
                <Route
                    path={`/user`}
                    element={
                        width <= 834 ? (
                            <LoginWrapper>
                                <UserAccount />
                            </LoginWrapper>
                        ) : (
                            <Navigate to={userPath} replace />
                        )
                    }
                />
                {/* user menu */}

                {/* Prove of concept */}
                <Route path={`/poc`} element={<Poc />} />
                <Route path={`/direct-wp-poc`} element={<DirectIntegrationWP />} />
                {/* user menu */}

                <Route path="*" element={<Navigate to={`../${StoreCountry.CountryLowerCase()}`} replace />} />
            </Routes>
            <Footer />
            <TriggerAlwaysToTop />
        </BrowserRouter>
    )
})

const TriggerAlwaysToTop = () => {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return <div></div>
}

export default AppRouter
