import { styled, Container } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import BreadcrumbWrapper from './BreadcrumbWrapper'

export const breadcrumbOption = [
    {
        caption: 'customise',
        link: '/products',
        paramsRequire: false
    },
    {
        caption: 'signup_shipping',
        link: '/checkout/create-account',
        paramsRequire: true,
        authCaption: 'shipping',
        authLink: '/checkout/shipping'
    },
    {
        caption: 'billing',
        link: '/checkout/payment',
        paramsRequire: true
    },
    {
        caption: 'purchase',
        link: '/checkout/review',
        paramsRequire: true
    }
]

const Breadcrumb = observer(({ breadcrumbOption, activeAt = 0, disabled }) => {
    const [width, setWidth] = useState(window.innerWidth)
    const [circledimeter, setCircledimeter] = useState(54)

    const updateDimensions = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    useEffect(() => {
        // if (width < 400 && circledimeter !== 34) {
        //     setCircledimeter(34)
        // }
        if (width >= 400 && circledimeter !== 54) {
            setCircledimeter(54)
        }
    }, [width, circledimeter])

    return (
        <BreadcrumbContainer circledimeter={circledimeter}>
            {breadcrumbOption.map((option, index) => (
                <BreadcrumbWrapper
                    key={`breadcrumb-${index}`}
                    option={option}
                    circledimeter={circledimeter}
                    breadcrumbOption={breadcrumbOption}
                    index={index}
                    activeAt={activeAt}
                    disabled={disabled}
                />
            ))}
        </BreadcrumbContainer>
    )
})

const BreadcrumbContainer = styled(Container)`
    display: flex;
    justify-content: center;
    gap: ${({ circledimeter }) => `${circledimeter / 3}px`};

    @media screen and (min-width: 600px) and (max-width: 829px) {
        gap: 10px;
    }
`

export default Breadcrumb
