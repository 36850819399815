import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import SettingCard from './../UserAccountMenu/SettingCard'
import OrderHistories from './OrderHistories'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { Stack, styled } from '@mui/material'
import Breadcrumb from '../UserAccountMenu/Breadcrumb'
import { useParams } from 'react-router-dom'
import MobileLayoutWrapper from '../UserAccountMenu/MobileLayoutWrapper'

const OrderHistoryWrapper = observer(props => {
    return (
        <>
            <SettingCard title={storeElement.settingCard.title || ''} titleAlign={storeElement.settingCard.titleAlign || 'left'} display="block">
                <OrderHistories />
            </SettingCard>
        </>
    )
})

export const OrderHistoryWrapperMobileLayout = observer(props => {
    return (
        <MobileLayoutWrapper>
            <StackMobileLayout>
                <Breadcrumb />
                {props.children}
            </StackMobileLayout>
        </MobileLayoutWrapper>
    )
})

const StackMobileLayout = styled(Stack)`
    background: #d1d9e2 !important;
    width: 100%;
`

export default OrderHistoryWrapper
