import styled from '@emotion/styled'
import { Box, Stack, ThemeProvider } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import { get } from 'lodash'
import { PaymentAPIs } from 'GlobalStores/Checkout/PaymentServices/PaymentAPI'

const PaymentResponseDone = observer(() => {
    const navigate = useNavigate()
    const qs = new URLSearchParams(window.location.search)

    useLayoutEffect(() => {
        if (qs.get('tppPayload')) {
            try {
                const payload = JSON.parse(qs.get('tppPayload'))
                const orderId = get(payload, 'eventDetails.id.unicity')
                const error = get(payload, 'eventDetails.error')

                const paymentID = sessionStorage.getItem('paymentID')
                if (orderId) {
                    UpdatePaymentStatus(paymentID, 'success')
                } else {
                    UpdatePaymentStatus(paymentID, 'faild')
                }
            } catch (e) {}
        }
    }, [])

    const UpdatePaymentStatus = async (paymentID, status) => {
        setTimeout(() => {
            try {
                if (status === 'success') {
                    navigate(`/payment-response/success?payment_id=${paymentID}`)
                } else {
                    navigate(`/payment-response/failed?payment_id=${paymentID}`)
                } 
            } catch (e) {
                navigate(`/payment-response/failed?payment_id=${paymentID}`)
                console.log('Hello error => ', e)
            }
        }, 1000)
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <PaymentResponseDoneWrapper>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <CircularProgress />
                </Box>
            </PaymentResponseDoneWrapper>
        </ThemeProvider>
    )
})

const PaymentResponseDoneWrapper = styled(Stack)`
    width: 100%;
    min-height: calc(100vh - (74px + 64px));
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 20px 140px 20px;
    box-sizing: border-box;

    @media (min-width: 744px) {
        padding: 40px 0 54px 0;
    }
`

export default PaymentResponseDone
