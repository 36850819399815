import { Box, Container, Stack, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { MainTemplate } from '../../../../Template/MainTemplate'
import { storeElement } from 'GlobalStores/StoreElement'
import { toJS } from 'mobx'
import LandingBoxWrapper from 'Components/Elements/Boxes/LandingBoxWrapper'
import { useState } from 'react'
import { useEffect } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { useLayoutEffect } from 'react'
import WhatExpertsSayItem from './WhatExpertsSayItem'

const WhatExpertsSay = observer(props => {
    const expertsSay = props.data
    const [loading, setLoading] = useState(true)
    useLayoutEffect(() => {
        setLoading(false)
    }, [])

    const contentDefault = {
        title: 'What experts say',
        list: [
            {
                image: 'Dr_Afa_K_Palu',
                title: 'Dr. Afa K. Palu',
                description: `“As an advanced research scientist who has seen many weight-management programs over the last few decades, I know Feel Great will have an exponentially positive impact on many lives. Unimate and Balance help control hunger in a healthier way, while the practice of intermittent fasting has been shown to promote healthy blood sugar and cholesterol levels.”`
            },
            {
                image: 'Dr_Erin_Glynn',
                title: 'Dr. Erin Glynn',
                description: `“I drink Unimate in place of coffee as my morning source of caffeine. It gives me an energy boost, helps me focus, and helps support my mood. Combine these benefits with the cholesterol support and healthy glucose response to meals I get from Balance, and this pack is a powerhouse team that's simple to use and that made me feel better quickly.”`
            },
            {
                image: 'Dr_Coleen_Andruss',
                title: 'Dr. Coleen Andruss',
                description: `“As an obesity specialist, I am always looking for ways to help my patients feel like they aren't “dieting” but that really works on fat loss. Feel Great is the answer. I can honestly say this is by far the most successful method I have found in my 30 years of practice.”`
            }
        ]
    }
    return (
        <ThemeProvider theme={MainTemplate}>
            {!loading && (
                <LandingBoxWrapper>
                    {expertsSay.items.length > 0 ? (
                        <LayoutWhatExpertSay>
                            <Box textAlign={'center'} mb="40px" mt="60px">
                                <Typography variant="h6">{StoreTranslate.UseLanguage(expertsSay.title)}</Typography>
                            </Box>
                            {storeElement.dynamicWidth >= 834 ? (
                                <OwlCarouselPageDesktop isDefault={false} items={expertsSay.items} />
                            ) : (
                                <OwlCarouselPageMobile isDefault={false} items={expertsSay.items} />
                            )}
                        </LayoutWhatExpertSay>
                    ) : (
                        <LayoutWhatExpertSay>
                            <Box textAlign={'center'} mb="40px" mt="60px">
                                <Typography variant="h6">{StoreTranslate.UseLanguage(expertsSay.title)}</Typography>
                            </Box>
                            {storeElement.dynamicWidth >= 834 ? (
                                <OwlCarouselPageDesktop isDefault={true} items={expertsSay.items} />
                            ) : (
                                <OwlCarouselPageMobile isDefault={true} items={expertsSay.items} />
                            )}
                        </LayoutWhatExpertSay>
                    )}
                </LandingBoxWrapper>
            )}
        </ThemeProvider>
    )
})

const OwlCarouselPageDesktop = props => {
    const numPage = 3
    const oldItems = [...props.items]
    const newArr = []
    while (oldItems.length) newArr.push(oldItems.splice(0, numPage))

    const options = {
        loop: true,
        items: 1,
        autoplay: false,
        dots: false,
        nav: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        stagePadding: 0
    }

    const dynamicWidth = width => {
        if (width >= 1024 && width < 1200) {
            return 800
        } else if (width > 764 && width < 1024) {
            return 750
        } else {
            return 1000
        }
    }

    return (
        <BoxContentStyle>
            <Box maxWidth={dynamicWidth(storeElement.dynamicWidth)}>
                <OwlCarousel className="owl-theme" {...options} style={{ textAlign: '-webkit-center' }}>
                    {newArr.map((item, key) => {
                        return (
                            <GridLayout key={key}>
                                {item.map((item, itemKey) => {
                                    const isEven = storeElement.dynamicWidth > 834 ? itemKey : key
                                    const description = item.description

                                    return (
                                        <ListWithExpertsStyled
                                            key={itemKey}
                                            bgcolor={!(isEven % 2 === 0) ? '#FFFFFF' : '#e9f1fa'}
                                            border={!(isEven % 2 === 0) ? '1px solid #003B6F' : '0px'}>
                                            <WhatExpertsSayItem description={description} isDefault={props.isDefault} data={item} />
                                        </ListWithExpertsStyled>
                                    )
                                })}
                            </GridLayout>
                        )
                    })}
                </OwlCarousel>
            </Box>
        </BoxContentStyle>
    )
}

const OwlCarouselPageMobile = props => {
    const numPage = 1
    const oldItems = [...props.items]
    const newArr = []
    while (oldItems.length) newArr.push(oldItems.splice(0, numPage))

    const options = {
        loop: true,
        items: 1,
        autoplay: false,
        dots: true,
        nav: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        stagePadding: 0
    }

    return (
        // <LayoutMobileStyle>
        <BoxMobileStyle>
            <OwlCarousel className="owl-carousel owl-theme" {...options} style={{ textAlign: '-webkit-center' }}>
                {newArr.map((arr, key) => {
                    return (
                        <GridLayout key={key}>
                            {arr.map((item, itemKey) => {
                                const isEven = storeElement.dynamicWidth > 834 ? itemKey : key
                                const description = item.description
                                return (
                                    <ListWithExpertsStyled
                                        key={itemKey}
                                        bgcolor={!(isEven % 2 === 0) ? '#FFFFFF' : '#e9f1fa'}
                                        border={!(isEven % 2 === 0) ? '1px solid #003B6F' : '0px'}>
                                        <WhatExpertsSayItem description={description} isDefault={props.isDefault} data={item} />
                                    </ListWithExpertsStyled>
                                )
                            })}
                        </GridLayout>
                    )
                })}
            </OwlCarousel>
        </BoxMobileStyle>
        // </LayoutMobileStyle>
    )
}
const LayoutWhatExpertSay = styled(Stack)`
    margin: 20px 0px;
    // width: 86%;
    align-self: center;
    max-width: auto;

    @media screen and (max-width: 834px) {
        width: 100%;
    }
`

const IMGProfile = styled.img`
    height: 100px;
    width: 100px !important;
`

// const LayoutMobileStyle = styled(Box)`
//     text-align: center;
//     align-self: center;
//     width: 90%;

//     @media screen and (max-width: 834px) {
//         width: 100%;
//         display: inline-table;
//         text-align: -webkit-center;
//     }
// `
const BoxMobileStyle = styled(Box)`
    align-self: center;
    width: 100%;

    @media screen and (max-width: 834px) {
        width: 89%;
        margin-bottom: 80px;
    }
`

const BoxContentStyle = styled(Box)`
    margin-bottom: 100px;
    text-align: center;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;

    @media screen and (max-width: 834px) {
        width: 89%;
    }
`

const GridLayout = styled(Box)`
    align-self: center;
    justify-items: center;
    height: 100%;
    display: flex;
    grid-template-columns: auto auto auto;
    row-gap: 20px;
    gap: 20px;
    // margin: 0px 20px;
    margin-bottom: 20px;
    margin-right: 20px;

    @media screen and (max-width: 834px) {
        grid-template-columns: auto;
        width: 100%;
    }

    @media screen and (max-width: 430px) {
        grid-template-columns: auto;
        width: 94%;
    }
`

const ListWithExpertsStyled = styled(Stack)`
    background-color: ${({ bgcolor, ...props }) => bgcolor};
    border: ${({ border, ...props }) => border};
    border-radius: 12px;
    align-items: center;
    padding: 30px 20px;

    @media screen and (min-width: 900px) {
        width: 250px;
    }

    @media screen and (min-width: 1024px) {
        width: 250px;
    }

    @media screen and (min-width: 1180px) {
        width: 300px;
    }
`

export default WhatExpertsSay
