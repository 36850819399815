import { observer } from 'mobx-react-lite'
import { Stack, Typography, Card, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'

const ResponseCard = observer(({ icon, title, detail, button }) => {
    const navigate = useNavigate()

    return (
        <Card
            sx={{
                boxSizing: 'border-box',
                marginTop: '40px',
                padding: {
                    xs: '40px 24px 50px 24px',
                    sm: '40px 24px 50px 24px',
                    md: '40px 144px 50px 144px'
                },
                borderRadius: '12px',
                width: {
                    xs: '100%',
                    sm: '600px',
                    md: '773px'
                },
                boxShadow: 'none'
            }}>
            <Stack width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
                <Stack
                    sx={{
                        marginBottom: '32px',
                        width: '100%',
                        display: 'flex',
                        alignItems: {
                            xs: 'start',
                            sm: 'center'
                        }
                    }}>
                    {icon}
                </Stack>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: {
                            xs: '16px',
                            sm: '28px'
                        },
                        fontSize: {
                            xs: '24px',
                            sm: '32px'
                        },
                        textAlign: {
                            xs: 'start',
                            sm: 'center',
                            md: 'center'
                        },
                        width: '100%'
                    }}>
                    {title}
                </Typography>

                <Stack sx={{ width: '100%' }}>{detail}</Stack>

                {!button && (
                    <Typography
                        variant="h5"
                        sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            textAlign: {
                                xs: 'start',
                                sm: 'center'
                            },
                            color: '#153862',
                            textDecoration: 'underLine',
                            cursor: 'pointer',
                            marginTop: '32px',
                            width: '100%'
                        }}
                        onClick={() => navigate(`/login`)}>
                        <T>go_back</T>
                    </Typography>
                )}
                {button}
            </Stack>
        </Card>
    )
})

export default ResponseCard
