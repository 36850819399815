import { get } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import StoreLoader from '../Elements/StoreLoader'
import StoreTranslate from './StoreTranslate'

export function useTranslate(fallback = 'en') {
    function translate(key, options = { defaultDisplay: null }) {
        const { defaultDisplay } = options || {}

        const fallbackLanguage = `${fallback}.${key}`

        let translated = ''
        let showKey = false
        if (/localhost/.test(window.location.href)) {
            showKey = true
        }

        if (StoreTranslate.Instance().fetchOnce === false) {
            translated = get(StoreTranslate.Dictionaries(), `${StoreTranslate.CurrentLanguage().toLowerCase()}.${key}`, showKey ? key : '')

            if (translated === key) {
                translated = get(StoreTranslate.Dictionaries(), fallbackLanguage, defaultDisplay ? defaultDisplay : showKey ? key : '')
            }
        } else {
            translated = get(StoreTranslate.Instance().lists, `${StoreTranslate.CurrentLanguage().toLowerCase()}.${key}`, showKey ? key : '')

            if (translated === key) {
                translated = get(StoreTranslate.Instance().lists, fallbackLanguage, defaultDisplay ? defaultDisplay : showKey ? key : '')
            }
        }

        return translated !== '' ? translated : key
    }

    return translate
}

export function useTranslateStaticComponents(fallback = 'en') {
    function translateStaticComponents(key, options = { defaultDisplay: null }) {
        const { defaultDisplay } = options || {}

        const fallbackLanguage = `${fallback}.${key}`

        let translated = ''
        let showKey = false
        if (/localhost/.test(window.location.href)) {
            showKey = true
        }

        if (StoreTranslate.Instance().fetchOnce === false) {
            translated = get(StoreTranslate.GetDictionaryStaticComponents(), `${StoreTranslate.CurrentLanguage().toLowerCase()}.${key}`, showKey ? key : '')

            if (translated === key) {
                translated = get(StoreTranslate.GetDictionaryStaticComponents(), fallbackLanguage, defaultDisplay ? defaultDisplay : showKey ? key : '')
            }
        } else {
            translated = get(StoreTranslate.Instance().lists, `${StoreTranslate.CurrentLanguage().toLowerCase()}.${key}`, showKey ? key : '')

            if (translated === key) {
                translated = get(StoreTranslate.Instance().lists, fallbackLanguage, defaultDisplay ? defaultDisplay : showKey ? key : '')
            }
        }

        return translated
    }

    return translateStaticComponents
}

export const T = observer(({ children, defaultDisplay }) => {
    const translate = useTranslate()
    return <>{StoreLoader.dictionary && translate(children, { defaultDisplay })}</>
})
