import { makeAutoObservable } from "mobx"

class StoreTaxInvoice {

    requestTaxInvoice = false
    invoiceGovernmentId = ''
    invoiceTaxId = ''
    invoiceType = 'individual'
    invoiceForms = []
    invoiceFormsErrors = []
    invoiceInformation = {}
    invoiceIndividualForms = []
    invoiceCorporateForms = []
    showTaxInvoiceEdit = true

    SetInvoiceFormsErrors(errors) {
        this.invoiceFormsErrors = errors
    }

    SetRequestTaxInvoice(value) {
        this.requestTaxInvoice = value
    }

    GetInvoiceIndividualForms() {
        return this.invoiceIndividualForms
    }

    GetInvoiceCorporateForms() {
        return this.invoiceCorporateForms
    }

    GetInvoiceValue(key) {
        if (this.invoiceType === 'individual') {
            const item = this.invoiceIndividualForms.find(item => item.key === key)
            if (item) {
                return item.value
            }
        } else {
            const item = this.invoiceCorporateForms.find(item => item.key === key)
            if (item) {
                return item.value
            }
        }
    }

    SetInvoiceValue(key, value) {

        this.invoiceForms.map(item => {
            if (item.key === key) {
                item.value = value
            }
            return item
        })

        if (this.invoiceType === 'individual') {
            this.invoiceIndividualForms.map(item => {
                if (item.key === key) {
                    item.value = value
                }
                return item
            })
        } else {
            this.invoiceCorporateForms.map(item => {
                if (item.key === key) {
                    item.value = value
                }
                return item
            })
        }
    }

    GetTaxInvoiceValues() {
        let values = {}
        if (this.invoiceType === 'individual') {
            this.invoiceIndividualForms.map(item => {
                values[item.key] = item.value
                return item
            })
        } else {
            this.invoiceCorporateForms.map(item => {
                values[item.key] = item.value
                return item
            })
        }

        return values
    }


    constructor() {
        makeAutoObservable(this)
    }
}

const storeTaxInvoice = new StoreTaxInvoice()
export default storeTaxInvoice