import { ThemeProvider } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import ContactUs from '../Elements/Contact/ContactUs'
import ContactUsSouthafrica from '../Elements/Contact/ContactUsSouthafrica'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

const Contact = observer(() => {
    const navigate = useNavigate()
    const country = storeCountry.Country2()

    const countryIsOpenPage = ['JP', 'ZA']

    useEffect(() => {
        if (countryIsOpenPage.includes(country) === false) {
            navigate('/')
        }
    }, [])

    return (
        <ThemeProvider theme={MainTemplate}>
            <ContactWrapper>
                {country === 'ZA' ? <ContactUsSouthafrica /> : <ContactUs />}
            </ContactWrapper>
        </ThemeProvider>
    )
})

const ContactWrapper = styled('div')`
    background-color: #F2F9F8;
    height: auto;
    padding: 60px 40px 80px 40px;

    @media screen and (min-width: 600px) {
        padding: 80px 70px 100px 70px;
    }

    @media screen and (min-width: 1200px) {
        padding: 80px 135px 150px 135px;
    }
`

export default Contact
