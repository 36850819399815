import React, { useEffect, useState } from 'react'
import { ReactComponent as FaceIcon } from './Assets/icon-face-yellow.svg'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, styled, Box } from '@mui/material'
import { DefaultButton } from '../Forms'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SpinnerIcon } from './Assets/button-icon-spinner.svg'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { runInAction } from 'mobx'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

const CancelSubscriptions = observer(props => {
    const [loadingButton, setLoadingButton] = useState(false)
    const [loading, setLoading] = useState(false)

    const translate = useTranslate()
    const navigate = useNavigate()

    const isShowEditSubscription = () => {
        return !['JPN'].includes(storeCountry.Country3())
    }

    useEffect(() => {
        runInAction(() => {
            storeElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeElement.cardTitle.titleStep2 = <T>header_cancel_subscription</T>
            storeElement.settingCard.padding = '50px 40px 20px 40px'
            storeElement.settingCard.titleAlign = 'center'
        })

        return () => {
            runInAction(() => {
                storeElement.cardTitle.titleStep1 = ''
                storeElement.cardTitle.titleStep2 = ''
                storeElement.settingCard.padding = ''
                storeElement.settingCard.titleAlign = ''
            })
        }
    }, [])

    const handleSkipNextDelivery = () => {
        setLoadingButton(true)
        setTimeout(() => {
            navigate('/user/subscriptions/skip-delivery')
            setLoadingButton(false)
        }, 1500)
    }

    const handleCancelDelivery = () => {
        setLoading(true)
        setTimeout(() => {
            navigate('/user/subscriptions/reason-cancel-subscriptions')
        }, 1500)
    }

    return (
        <Box position="relative">
            {(loading || loadingButton) && <CardDisable />}
            <Stack justifyContent="center" flexDirection="row">
                <Typography variant="title3">
                    <T>header_cancel_subscription</T>
                </Typography>
            </Stack>

            <Stack alignItems={'center'} justifyContent={'center'} textAlign="center" mt="140px">
                <FaceIcon />
                <Box padding="0px 50px" mt="40px" mb="40px">
                    <Box mb="20px">
                        <Typography variant="title2" color="#023764">
                            <T>cancel_subscription_title</T>
                        </Typography>
                    </Box>

                    <Box width="400px">
                        <Typography variant="caption5" textAlign={'center'} mb="40px" color="#023764">
                            <T>cancel_subscription_content</T>
                        </Typography>
                    </Box>
                </Box>

                {isShowEditSubscription() && (
                    <DefaultButton
                        type="submit"
                        fullWidth={true}
                        titleText={translate('skip_next_delivery_button')}
                        width="324px"
                        fontSize="20px"
                        fontWeight="400"
                        onClick={() => handleSkipNextDelivery()}
                        isloading={loadingButton.toString()}
                    />
                )}

                <Typography onClick={() => handleCancelDelivery()} variant="link" color="#003B6F" fontWeight={500} mt="30px">
                    {loading ? <ButtonIconSpinner /> : <T>take_to_cancel</T>}
                </Typography>
            </Stack>
        </Box>
    )
})

const CardDisable = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 65%;
    border-radius: 8px;
    z-index: 1;
`

const ButtonIconSpinner = styled(SpinnerIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    circle {
        stroke: #079ff4;
    }
    path {
        stroke: #c7ddfb;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export default CancelSubscriptions
