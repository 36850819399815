import { Typography, Box, styled, Modal, Button } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

const JPNFRModal = observer(() => {
    const translate = useTranslate()

    const [isShowModal, setIsShowModal] = useState(true)

    const handleClose = () => {
        setIsShowModal(false)
        localStorage.setItem('isSeenNFRModal', true)
    }

    return (
        <Modal open={isShowModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <BoxStyled>
                <Title id="modal-modal-title">{translate('nfr_title_modal')}</Title>
                <Description id="modal-modal-description">{translate('nfr_description_modal')}</Description>
                <ButtonStyled onClick={handleClose}>
                    <Typography sx={{ color: '#FFFFFF', fontWeight: 600, fontSize: '24px', textTransform: 'none' }}>{translate('continue')}</Typography>
                </ButtonStyled>
            </BoxStyled>
        </Modal>
    )
})

export default JPNFRModal

const BoxStyled = styled(Box)`
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    padding: 40px 20px;
    background-color: #003b6f;
    border-radius: 12px;
    text-align: center;

    @media screen and (min-width: 768px) {
        width: 684px;
        padding: 60px;
    }
    &:focus-visible {
        outline: none;
    }
`

const Title = styled(Typography)`
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 29px;

    @media screen and (min-width: 768px) {
        font-size: 30px;
        text-align: initial;
    }
`

const Description = styled(Typography)`
    margin-top: 30px;
    color: #ffffff;
    font-size: 17px !important;
    font-weight: 400;

    @media screen and (min-width: 768px) {
        font-size: 18px;
        text-align: initial;
        line-height: 34px;
    }
`

const ButtonStyled = styled(Button)`
    height: 60px;
    width: 100%;
    max-width: 305px;
    background-color: #079ff4;
    border-radius: 32px;
    padding: 9px 33px;
    margin-top: 30px;

    :hover {
        background-color: #0088d4;
    }

    @media screen and (min-width: 768px) {
        width: 393px;
        width: 305px;
    }
`
