import { Stack, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'

const MainLayout = observer(props => {
    return props.isEnable ? (
        <MainLayoutStyled id={props.id || ''} background={props.background || ''} minHeight={props.minHeight || '500px'} style={{ ...props.style }}>
            {props.children}
        </MainLayoutStyled>
    ) : (
        <></>
    )
})

const MainLayoutStyled = styled(Stack)`
    padding: 0px;
    background-color: ${props => (props.background ? props.background : '#ffffff')};

    @media screen and (min-width: 1440px) {
        width: 100%;
        height: 100%;
    }
`

export default MainLayout
