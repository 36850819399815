import axios from 'axios'
import HttpHelper from 'Services/HttpHelper'
const domain = 'https://member-calls2.unicity.com'

const getFeelGreatCountries = () => {
    const url = `${domain}/settings/countryFeelGreatV2/enable`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }))
}

const getSiteVersion = ({ country = 'thailand' }) => {
    const url = `https://member-calls4.unicity.com/ALL/ALL_Versions.asp`

    return axios.post(
        HttpHelper.Proxy({ originalUrl: url }),
        {
            strData: JSON.stringify({
                source: `fg.unicity.com/${country}`
            })
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    )
}

const feelGreateAPI = { getFeelGreatCountries, getSiteVersion }

export default feelGreateAPI
