import { observer } from 'mobx-react-lite'
import { Stack, createTheme, ThemeProvider } from '@mui/material'
import ResetPasswordForm from '../Elements/ResetPassword/ResetPasswordForm'

const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 320, // phone
            sm: 768, // tablets
            md: 900 // small laptop
        }
    },
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#FFF'
        },
        button: {
            main: '#5A8FC4',
            disabled: 'rgba(90, 143, 196, .3)'
        }
    },
    typography: {
        fontFamily: 'Inter, NotoSansThai',
        fontStyle: 'normal',
        h3: {
            color: '#0B3B61',
            fontFamily: 'Inter, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px'
        },
        label: {
            color: '#94A2B4',
            fontSize: 12,
            fontWeight: 500
        },
        link: {
            cursor: 'pointer',
            textAlign: 'center',
            fontFamily: 'Inter, NotoSansThai',
            fontStyle: 'normal',
            color: '#153862',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            textDecoration: 'underline'
        },
        caption: {
            fontFamily: 'Inter, NotoSansThai',
            fontStyle: 'normal',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#003764'
        }
    }
})

const ResetPassword = observer(props => {
    return (
        <ThemeProvider theme={customTheme}>
            <Stack bgcolor={'#D1D9E2'} py="18px" alignItems={'center'} sx={{ minHeight: '100vh', padding: '18px 3.5em' }}>
                <ResetPasswordForm />
            </Stack>
        </ThemeProvider>
    )
})

export default ResetPassword
