import styled from '@emotion/styled'
import { ThemeProvider } from '@mui/material'
import PaymentResponseFailedCard from 'Components/Elements/Checkout/PaymentResponseFailed/PaymentResponseFailedCard'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'

const PaymentResponseFailed = observer(() => {
    return (
        <ThemeProvider theme={MainTemplate}>
            <PaymentResponseFailedWrapper style={{ backgroundColor: MainTemplate.palette.background.default, minHeight: 'calc(100vh - (74px + 64px))' }}>
                <PaymentResponseFailedCard></PaymentResponseFailedCard>
            </PaymentResponseFailedWrapper>
        </ThemeProvider>
    )
})

const PaymentResponseFailedWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 20px 140px 20px;
    box-sizing: border-box;

    @media (min-width: 744px) {
        padding: 40px 0 54px 0;
    }
`
export default PaymentResponseFailed
