import { observer } from 'mobx-react-lite'
import { Box, Typography } from '@mui/material'
import { get, isEmpty } from 'lodash'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { toJS } from 'mobx'
import { useNavigate } from 'react-router-dom'

const LinkElement = observer(({ data }) => {
    const navigate = useNavigate()

    /// Function to handle the link to contact us page use on line 63
    const handleLinkToContact = () => {
        navigate('/contact-us')
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    sm: 'row'
                }
            }}>
            {data
                ? data.map((mainItem, mainIndex) => {
                    if (isEmpty(mainItem?.items)) return
                    return (
                        <Box
                            key={`main-menu-${mainIndex}`}
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '50%'
                                },
                                marginBottom: {
                                    xs: '20px',
                                    sm: '0'
                                }
                            }}>
                            <Typography
                                variant="h2"
                                sx={{
                                    fontFamily: 'Inter, NotoSansThai',
                                    fontSize: '18px !important',
                                    color: '#153862',
                                    marginBottom: '20px'
                                }}>
                                {StoreTranslate.UseLanguage(mainItem.title)}
                            </Typography>
                            <Box>
                                {mainItem.items.map((subItem, subIndex) => {
                                    let hrefLink = StoreTranslate.UseLanguage(subItem[subItem.usageType])

                                    return (
                                        <Box
                                            key={`sub-menu-${subIndex}`}
                                            sx={{
                                                marginBottom: '20px'
                                            }}>
                                            {subItem.usageType === 'handleFunction' ? (
                                                <Box
                                                    onClick={eval(StoreTranslate.UseLanguage(subItem.handleFunction))}
                                                >
                                                    <Typography
                                                        variant="body3"
                                                        sx={{
                                                            fontFamily: 'Inter, NotoSansThai',
                                                            fontSize: '18px !important',
                                                            marginBottom: '20px',
                                                            color: '#153862',
                                                            cursor: 'pointer'
                                                        }}>
                                                        {StoreTranslate.UseLanguage(subItem.title)}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Typography
                                                    variant="body3"
                                                    sx={{
                                                        fontFamily: 'Inter, NotoSansThai',
                                                        fontSize: '18px !important',
                                                        marginBottom: '20px'
                                                    }}>
                                                    <a
                                                        href={hrefLink}
                                                        target={get(subItem, 'openTarget', '')}
                                                        style={{ color: '#153862', textDecoration: 'none' }}>
                                                        {StoreTranslate.UseLanguage(subItem.title)}
                                                    </a>
                                                </Typography>
                                            )}
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    )
                })
                : null}
        </Box>
    )
})

export default LinkElement
