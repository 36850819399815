import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import httpHelper from 'Services/HttpHelper'
import HttpHelper from 'Services/HttpHelper'

const domain = `https://${ServerEnv.MemberCalls2()}.unicity.com`

const putHotFormatV2 = (data, countryCode3Alpha, isSkipValidate) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address${isSkipValidate ? '?isSkipValidate=1' : ''}`
    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const createUuid = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data)
}

const putAddress = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address`
    console.log('putAddress')
    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const putAddressAddItemQty = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address?isSkipValidate=1`

    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const getHotFormatV2 = (uuid, countryCode3Alpha, calc) => {
    const url = `${domain}/hot/format/${countryCode3Alpha}/feelGreat/detail-v2/${uuid}${calc ? '?ordercalC=1' : ''}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }))
}

const postHotFormatWithConfirmPassword = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address?password2=1`

    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const checkDuplicateOrder = ({ countryCode3Alpha, uuid }) => {
    const url = `${domain}/hot/payment_router/duplicateOrder/${countryCode3Alpha}/feelGreat/${uuid}`

    return httpHelper.Get({
        url: url
    })
}

export const FormatAPIs = {
    putAddressAddItemQty,
    getHotFormatV2,
    putHotFormatV2,
    createUuid,
    putAddress,
    postHotFormatWithConfirmPassword,
    checkDuplicateOrder
}
