import { formToJSON } from 'Helpers/GlobalHelpers'
import { isSomething } from 'Utils/Utils'
import { observer } from 'mobx-react-lite'

import { useLayoutEffect } from 'react'

const PaymentHydraForm = observer(({ dataPayment }) => {
    console.log('dataPayment', dataPayment)

    useLayoutEffect(() => {
        if (dataPayment.formMethod === 'GET') {
            window.location.href = dataPayment.formAction
        } else {
            const elements = document.getElementById('formHydraPayment').elements
            let formJson = formToJSON(elements)
            delete formJson['']
            document.getElementById('formHydraPayment').submit()
        }
    }, [])

    return (
        <>
            {isSomething(dataPayment) ? (
                <form method={dataPayment.formMethod} name="formHydraPayment" id="formHydraPayment" className="form" action={dataPayment.formAction}>
                    {!!dataPayment.formData &&
                        Object.keys(dataPayment.formData).map(key => {
                            return <input type="hidden" name={key} value={dataPayment.formData[key]} key={key} />
                        })}
                </form>
            ) : null}
        </>
    )
})

export default PaymentHydraForm
