import StoreTranslate from "GlobalStores/Cores/Translator/StoreTranslate"

function isEmpty(obj) {
    return Object.keys(obj).length === 0
}

function swapKeysAndValues(arr) {
    return Object.fromEntries(Object.entries(arr).map(([key, value]) => [value, key]))
}

function hasFalseElements(arr) {
    return arr.some(el => el === false)
}

function TowRomanAndNativeKey(key) {
    return StoreTranslate.IsEnglish() ? `${key}_roman` : `${key}_native`
}

export const ObjectHelpers = {
    isEmpty,
    swapKeysAndValues,
    hasFalseElements,
    TowRomanAndNativeKey
}
