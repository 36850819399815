import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { get, isEmpty, keyBy } from 'lodash'
import AutoAddress from './AutoAddress/AutoAddress'
import CountryBox from './CountryBox/CountryBox'
import DefaultInput from './DefaultInput'
import ModalSelect from './ModalSelect/ModalSelect'
import ModalSearch from './ModalSearch/ModalSearch'
import ModalExpSelect from './ModalExpSelect/ModalExpSelect'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { DefaultLabelTypo } from './InputsStyled'
import ModalManySelect from './ModalManySelect/ModalManySelect'

export function FormSelector(translate, item, onChange, helpertextcardtype, formattedValue, onKeyUp, forceType = '', pickupShippingFormDetail, allItems = []) {
    if (!item) return null
    const newType = forceType ? forceType : item?.type || 'text'

    switch (newType) {
        default:
            let defaultType = 'text'
            if (['zip_code', 'zip', 'phone', 'cvv', 'card_number'].includes(item.key)) {
                defaultType = 'tel'
            }
            if (item.key === 'email') {
                defaultType = 'email'
            }
            if (item.key === 'zip_code' && storeCountry.Country3() === 'JPN') {
                let options = []
                return (
                    <ModalSearch
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetShippingForm(), value)
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetCheckoutForms(), value)
                        }}
                        options={options}
                        value={item.value}
                        onChange={e => {
                            let allowNumber = e.target.value.replace(/[^0-9]/g, '')
                            if (onChange) {
                                onChange(item, allowNumber)
                            } else {
                                StoreCheckout.SetCheckoutFormValue(item.key, allowNumber)
                            }
                        }}
                        itemKeys={StoreCheckout.CheckOutInstance().AutoAddressDisplayKeys()}
                    />
                )
            }
            if (item.key === 'zip_code' && storeCountry.Country3() === 'THA') {
                let options = []

                return (
                    <ModalSearch
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetShippingForm(), value)
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetCheckoutForms(), value)
                        }}
                        options={options}
                        value={item.value}
                        onChange={e => {
                            let allowNumber = e.target.value.replace(/[^0-9]/g, '')
                            if (onChange) {
                                onChange(item, allowNumber)
                            } else {
                                StoreCheckout.SetCheckoutFormValue(item.key, allowNumber)
                            }
                        }}
                        itemKeys={StoreCheckout.CheckOutInstance().AutoAddressDisplayKeys()}
                    />
                )
            }
            if (item.key === 'province') {
                return (
                    <DefaultInput
                        label={translate('fg_province')}
                        type={defaultType}
                        value={item.value}
                        key={item.key}
                        placeholder={item.placeholder || ''}
                        onKeyUp={e => {
                            if (onKeyUp) {
                                onKeyUp(item, e)
                            }
                        }}
                        onChange={e => {
                            if (onChange) {
                                onChange(item, e)
                            } else {
                                StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                            }
                        }}
                        {...item.inputProps}
                    />
                )
            }
            if (
                item.key === 'state' ||
                (item.key === 'district' && !['JPN', 'EGY', 'JOR', 'THA'].includes(storeCountry.Country3())) ||
                item.key === 'prefecture'
            ) {
                const options = []
                StoreCheckout.CheckOutInstance()
                    .Instance()
                    .StatesList()
                    .map(item => {
                        options.push(item.value)
                    })
                if (!item.value) {
                    item.value = ''
                }
                return (
                    <ModalSelect
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.SetCheckoutFormValue(item.key, value)
                        }}
                        options={options}
                        value={item.value.toUpperCase()}
                    />
                )
            }

            if (item.key === 'city' && (storeCountry.Country3() === 'TWN' || storeCountry.Country3() === 'EGY')) {
                const options = StoreCheckout.CheckOutInstance()
                    .Instance()
                    .getCityOptionList()
                    .map(item => {
                        return item.value
                    })

                return (
                    <ModalSelect
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.SetCheckoutFormValue(item.key, value)
                        }}
                        options={options}
                        value={item.value.toUpperCase()}
                    />
                )
            }
            if ((item.key === 'fullName' || item.key === 'fullNameNative') && StoreCountry.Country3() === 'THA') {
                return (
                    <DefaultInput
                        label={
                            <DefaultLabelTypo variant="label" style={{ color: 'rgba(21, 56, 98, 0.5)' }}>
                                {translate(item.label)}
                            </DefaultLabelTypo>
                        }
                        type={defaultType}
                        value={item.value}
                        key={item.key}
                        typeKey={item.key}
                        disabled={item.disabled || false}
                        placeholder={item.placeholder || ''}
                        onKeyUp={e => {
                            if (onKeyUp) {
                                onKeyUp(item, e)
                            }
                        }}
                        onChange={e => {
                            if (onChange) {
                                onChange(item, e)
                            } else {
                                if (e.target.value.length === 0) {
                                    StoreCheckout.SetCheckoutFormValue(item.key, '')
                                } else {
                                    const regx = get(item, 'args.regx', null)
                                    if (regx) {
                                        const regex = new RegExp(regx, 'i')
                                        if (regex.test(e.target.value)) {
                                            StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                        }
                                    } else {
                                        StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                    }
                                }
                            }
                        }}
                        {...item.inputProps}
                        style={{ '-webkit-text-security': 'none' }}
                    />
                )
            } else {
                return (
                    <DefaultInput
                        helpertextcardtype={item.key === 'card_number' && helpertextcardtype}
                        label={
                            <DefaultLabelTypo variant="label" style={{ color: 'rgba(21, 56, 98, 0.5)' }}>
                                {translate(item.key)} {item.optional ? <span style={{ fontSize: 10 }}>({translate('optional')})</span> : ''}
                            </DefaultLabelTypo>
                        }
                        type={defaultType}
                        value={item.key === 'card_number' ? formattedValue : item.value}
                        key={item.key}
                        typeKey={item.key}
                        disabled={item.disabled || false}
                        placeholder={item.placeholder || ''}
                        onKeyUp={e => {
                            if (onKeyUp) {
                                onKeyUp(item, e)
                            }
                        }}
                        onChange={e => {
                            if (onChange) {
                                onChange(item, e)
                            } else {
                                if (e.target.value.length === 0) {
                                    StoreCheckout.SetCheckoutFormValue(item.key, '')
                                } else {
                                    const regx = get(item, 'args.regx', null)
                                    if (regx) {
                                        const regex = new RegExp(regx, 'i')
                                        if (regex.test(e.target.value)) {
                                            StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                        }
                                    } else {
                                        StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                    }
                                }
                            }
                        }}
                        {...item.inputProps}
                        style={{ '-webkit-text-security': ['cvv'].includes(item.key) ? 'disc' : 'none' }}
                    />
                )
            }
        case 'password':
            return (
                <DefaultInput
                    type="password"
                    label={translate(item.label)}
                    value={item.value}
                    key={item.key}
                    onChange={e => {
                        if (onChange) {
                            onChange(item, e.target.value)
                        } else {
                            StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                        }
                    }}
                    showAdornmentLabel={item.args?.onEnroll}
                    item={item}
                />
            )
        case 'AutoAddress':
            return (
                <AutoAddress
                    key={item.key}
                    item={item}
                    selectedAddressCallback={value => {
                        if (onChange) {
                            onChange(item, value)
                        } else {
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetShippingForm(), value)
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetCheckoutForms(), value)
                        }
                    }}
                    itemKeys={StoreCheckout.CheckOutInstance().AutoAddressDisplayKeys()}
                    inputCallback={value => {
                        if (onChange) {
                            onChange(item, value)
                        } else {
                            StoreCheckout.SetCheckoutFormValue(item.key, value)
                        }
                    }}
                />
            )

        case 'CountryBox':
            return <CountryBox label={'country'} value={item?.value || StoreCountry.CountryCapitalize()} />
        case 'ExpDate':
            const monthOptionsFactory = () => {
                const lm = []
                for (let i = 1; i <= 12; i++) {
                    const m = i < 10 ? '0' + i : i
                    lm.push(m.toString())
                }
                return lm
            }

            const yearOptionsFactory = () => {
                const min = new Date().getFullYear()
                const max = 10
                const ly = []
                for (let i = 0; i <= max; i++) {
                    const year = `${min + i}`
                    ly.push(year)
                }
                return ly
            }
            const [month, year] = (item.value || '').split('/')
            return (
                <ModalExpSelect
                    label={translate(item.key)}
                    onSet={(month, year) => {
                        StoreCheckout.SetCreditCard({ key: item.key }, { month, year })
                    }}
                    options={{ month: monthOptionsFactory(), year: yearOptionsFactory() }}
                    value={{ month: month || '', year: year ? '20' + year : '' }}
                />
            )
        case 'select':
            const options = []
            StoreCheckout.CheckOutInstance()
                .Instance()
                .AddressOptionList(item.key, StoreCheckout)
                .map(item => {
                    options.push(item.value)
                })
            if (!item.value) {
                item.value = ''
            }

            if (StoreCountry.Country3() === 'PHL' && item.key === 'province_selection') {
                return (
                    <ModalManySelect
                        item={item}
                        label={pickupShippingFormDetail ? translate(pickupShippingFormDetail.label) : translate(item.key)}
                        // options={options || []}
                        // onSet={value => {
                        //     StoreCheckout.SetCheckoutFormValue(item.key, value)

                        //     const isChainAddress = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.isChainAddress')
                        //     const groupAddress = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.groupAddress', []) || []

                        //     if (isChainAddress && groupAddress.includes(item.key)) {
                        //         const newGroupAddress = groupAddress.reduce((arr, cur) => {
                        //             if (forceType === 'select' || isEmpty(allItems)) return [...arr, cur]
                        //             const kbItem = keyBy(allItems, 'key')
                        //             if (kbItem[cur].type === 'select') {
                        //                 return [...arr, cur]
                        //             }
                        //             return arr
                        //         }, [])

                        //         const keyToSetInit = newGroupAddress.slice(newGroupAddress.indexOf(item.key) + 1) || []
                        //         keyToSetInit.forEach(key => StoreCheckout.SetCheckoutFormValue(key, ''))
                        //         const element = document.getElementById(`address-${keyToSetInit[0]}`)
                        //         console.log('element', element)
                        //         if (element) {
                        //             setTimeout(() => {
                        //                 element.click()
                        //             }, 800)
                        //         }
                        //     }
                        // }}
                    />
                )
            }

            return (
                <ModalSelect
                    label={pickupShippingFormDetail ? translate(pickupShippingFormDetail.label) : translate(item.key)}
                    onSet={value => {
                        StoreCheckout.SetCheckoutFormValue(item.key, value)

                        const isChainAddress = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.isChainAddress')
                        const groupAddress = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.groupAddress', []) || []

                        if (isChainAddress && groupAddress.includes(item.key)) {
                            const newGroupAddress = groupAddress.reduce((arr, cur) => {
                                if (forceType === 'select' || isEmpty(allItems)) return [...arr, cur]
                                const kbItem = keyBy(allItems, 'key')
                                if (kbItem[cur].type === 'select') {
                                    return [...arr, cur]
                                }
                                return arr
                            }, [])

                            const keyToSetInit = newGroupAddress.slice(newGroupAddress.indexOf(item.key) + 1) || []
                            keyToSetInit.forEach(key => StoreCheckout.SetCheckoutFormValue(key, ''))


                            // ## *** REMOVE BECAUSE https://app.clickup.com/t/86du7j3nj
                            // const element = document.getElementById(`address-${keyToSetInit[0]}`)
                            // if (element) {
                            //     setTimeout(() => {
                            //         element.click()
                            //     }, 800)
                            // }
                        }
                    }}
                    disabled={isEmpty(options)}
                    options={options}
                    value={item.value}
                    keys={item.key}
                    placeholder={translate(get(item, 'placeholder', '') || get(pickupShippingFormDetail, 'placeholder', ''))}
                />
            )
    }
}
