import { observer } from 'mobx-react-lite'
import { Box, Skeleton, Stack, ThemeProvider, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { MainTemplate } from '../../../../Template/MainTemplate'

import { ReactComponent as Asterisk } from './assets/asterisk.svg'
import { storeElement } from 'GlobalStores/StoreElement'
import LandingBoxWrapper from 'Components/Elements/Boxes/LandingBoxWrapper'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { useEffect, useState } from 'react'
import { get } from 'lodash'

const OurApproach = observer(props => {
    const [loading, setLoading] = useState(true)

    const md = storeElement.dynamicWidth < 824
    const ourApproach = StoreFeelGreat.GetContent('ourApproach')

    const getImage = () => {
        const image2 = get(StoreFeelGreat.GetContent('ourApproach'), 'image2', '')
        if (image2) {
            return StoreTranslate.UseLanguage(image2)
        }
        return StoreFeelGreat.GetContent('ourApproach').image || ''
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            {StoreFeelGreat.GetContent('ourApproach') != null ? (
                <Stack maxWidth={'1536px'} alignSelf={'center'}>
                    <LandingBoxWrapper>
                        <LayoutOurApproachStyle>
                            <BoxOurApproachStyle>
                                <Typography variant="h6" textAlign={md ? 'center' : 'left'} justifySelf={md ? 'center' : 'left'}>
                                    {StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('ourApproach').title)}
                                    <Asterisk style={{ marginLeft: '20px' }} />
                                </Typography>
                                <Typography
                                    variant="body2"
                                    mt="20px"
                                    component={'div'}
                                    className="our-approch--description"
                                    dangerouslySetInnerHTML={{
                                        __html: StoreTranslate.UseLanguage(StoreFeelGreat.GetContent('ourApproach').description)
                                    }}
                                />
                            </BoxOurApproachStyle>
                            {getImage() && (
                                <BoxIMGStyle>
                                    <img src={getImage()} alt="" width="100%" />
                                </BoxIMGStyle>
                            )}
                        </LayoutOurApproachStyle>
                    </LandingBoxWrapper>
                </Stack>
            ) : (
                <Stack maxWidth={'1536px'} width={!md ? '1200px' : '100%'} alignSelf={'center'}>
                    <LandingBoxWrapper>
                        <LayoutOurApproachStyle>
                            <BoxOurApproachStyle justifyItems={'center'} width={!md ? '387px' : '100%'}>
                                <Skeleton variant="rounded" width={!md ? '100%' : '60%'} height={'40px'} sx={{ marginTop: '0px' }} />
                                <Skeleton variant="rounded" width={'100%'} height={'80px'} sx={{ marginTop: '36px' }} />
                            </BoxOurApproachStyle>
                            <BoxIMGStyle>
                                <Skeleton variant="rounded" width={'100%'} height={'292px'} />
                            </BoxIMGStyle>
                        </LayoutOurApproachStyle>
                    </LandingBoxWrapper>
                </Stack>
            )}
        </ThemeProvider>
    )
})

const LayoutOurApproachStyle = styled(Box)`
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
    width: 100%;
    display: flex;
    flex-flow: column;

    @media screen and (min-width: 768px) {
        flex-flow: column;
        justify-content: center;
        padding-left: 60px;
        padding-right: 60px;
        width: 80%;
    }

    @media screen and (min-width: 1024px) {
        padding-left: 65px;
        padding-right: 65px;
        flex-flow: row;
        justify-content: space-between;
    }

    @media screen and (min-width: 1366px) {
        width: 100%;
        padding-left: 135px;
        padding-right: 135px;
        justify-content: space-between;

        .our-approch--description {
            max-width: 387px;
        }
    }
    /*

    @media screen and (max-width: 430px) {
        width: 86%;
        display: block;
        padding: 60px 24px;
    } */
`

const BoxOurApproachStyle = styled(Box)`
    text-align: left;
    align-self: center;
    display: grid;
    /* width: 387px; */

    /* @media screen and (min-width: 768px) {
        .our-approch--description {
            width: 576px;
        }
    }


    @media screen and (max-width: 820px) {
        width: 100%;
        display: contents;
    } */
`

const BoxIMGStyle = styled(Box)`
    text-align: center;
    justify-self: center;
    width: 470px;

    @media screen and (max-width: 820px) {
        margin-top: 50px;
        width: 450px;
    }

    @media screen and (max-width: 430px) {
        margin-top: 30px;
        width: 335px;
    }
`

export default OurApproach
