import { Box, Container, Grid, Skeleton, Stack, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { MainTemplate } from '../../../../Template/MainTemplate'
import { ReactComponent as Refresh } from './assets/refresh.svg'
import styled from '@emotion/styled'
import LandingBoxWrapper from 'Components/Elements/Boxes/LandingBoxWrapper'
import { DefaultButton } from 'Components/Elements/Forms'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useNavigate } from 'react-router-dom'
import { storeElement } from 'GlobalStores/StoreElement'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { useEffect, useState } from 'react'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { get } from 'lodash'

const ThePractices = observer(props => {
    const navigate = useNavigate()
    const translate = useTranslate()

    const md = storeElement.dynamicWidth <= 824 ? true : false

    const [loading, setLoading] = useState(true)

    const thePractices = StoreFeelGreat.GetContent('thePractice')

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    const getImage = () => {
        const image2 = get(thePractices, 'image2', '')
        if (image2) {
            return StoreTranslate.UseLanguage(image2)
        }
        return thePractices.image || ''
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <LandingBoxWrapper style={{ textAlign: '-webkit-center' }}>
                {!loading && thePractices !== null ? (
                    <LayoutThePracticesStyle>
                        <BoxThePracticesStyle>
                            <Typography variant="h6" fontSize={'40px'} lineHeight="20px" justifySelf="center" display={'flex'} alignItems={'center'}>
                                {StoreTranslate.UseLanguage(thePractices.title)}
                                <Refresh style={{ marginLeft: '20px' }} />
                            </Typography>
                            <TypographyPracticeStyled
                                component={'div'}
                                variant="body2"
                                fontWeight={400}
                                dangerouslySetInnerHTML={{
                                    __html: StoreTranslate.UseLanguage(thePractices.description)
                                }}
                                mt="0px"
                            />
                            {storeElement.dynamicWidth > 1180 && (
                                <DefaultButton
                                    style={{
                                        width: 'auto',
                                        padding: '0 42px',
                                        height: '48px',
                                        background: '#003764',
                                        margin: '50px auto 0 auto'
                                    }}
                                    titleText={translate('buy_now')}
                                    onClick={() => navigate('/products')}
                                />
                            )}
                        </BoxThePracticesStyle>
                        {getImage() && (
                            <BoxIMGStyle>
                                <img src={getImage()} alt="" width="100%" height="100%" />
                            </BoxIMGStyle>
                        )}
                        {storeElement.dynamicWidth < 1180 && (
                            <DefaultButton
                                style={{
                                    width: '295px',
                                    padding: '0 42px',
                                    height: '48px',
                                    background: '#003764',
                                    margin: '50px auto 0 auto'
                                }}
                                // width="auto"
                                titleText={translate('buy_now')}
                                onClick={() => navigate('/products')}
                            />
                        )}
                    </LayoutThePracticesStyle>
                ) : (
                    <Container>
                        <Stack mb="60px">
                            <Box display={md ? 'grid' : 'flex'} justifyContent={'center'} alignItems={'center'} mb="40px" mt="80px">
                                <Grid
                                    item
                                    sm={12}
                                    key={'skel'}
                                    width={storeElement.dynamicWidth <= 414 ? '380px' : storeElement.dynamicWidth <= 824 ? '680px' : '100%'}
                                    mr={md ? '0px' : '60px'}>
                                    <Skeleton variant="rounded" width={'60%'} height={40} sx={{ marginTop: md ? '0px' : '54px' }} />
                                    <Box textAlign="-webkit-left">
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={10} sx={{ marginTop: '20px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={10} sx={{ marginTop: '5px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={10} sx={{ marginTop: '5px' }} />
                                        <Skeleton variant="rounded" width={'40%'} height={10} sx={{ marginTop: '5px', marginBottom: '20px' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={20} sx={{ marginY: '10px' }} />
                                    </Box>
                                </Grid>
                                <Skeleton variant="rounded" width={'100%'} height={'360px'} sx={{ marginTop: md ? '40px' : '0px' }} />
                            </Box>
                        </Stack>
                    </Container>
                )}
            </LandingBoxWrapper>
        </ThemeProvider>
    )
})

const ContainerStyled = styled(Container)`
    @media screen and (max-width: 824px) {
        width: 80%;
    }

    @media screen and (max-width: 430px) {
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        padding-left: 0;
        padding-right: 0;
    }
`

const ListWithIconsStyled = styled(Stack)`
    margin-bottom: 40px;
`

const ListWithIconStyled = styled(Stack)`
    &.list-item {
        display: grid;
        grid-template-columns: 30px auto;
        align-items: flex-start;
    }
`

const LayoutThePracticesStyle = styled(Box)`
    margin: 60px 0px;
    /* margin: 100px 0px; */
    // width: 100%;
    display: flex;
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    @media screen and (min-width: 768px) {
        width: 80%;
    }

    @media screen and (min-width: 1024px) {
        margin: 100px 0px;
        flex-flow: column;
        align-self: center;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 1180px) {
        flex-flow: row;
        width: 80%;

        row-gap: 40px;
        gap: 130px;
    }
`

const BoxThePracticesStyle = styled(Box)`
    text-align: left;
    align-self: center;
    align-self: start;
    display: grid;

    @media screen and (max-width: 824px) {
    }
`

const BoxIMGStyle = styled(Box)`
    text-align: center;
    vertical-align: bottom;
    max-width: 470px;
    height: 100%;

    @media screen and (max-width: 824px) {
        margin-top: 60px;
        justify-self: start;
    }
`

const TypographyPracticeStyled = styled(Typography)`
    margin-top: 30px;
    p {
        margin-top: 0;
        margin-block-end: 0;
    }

    @media screen and (min-width: 1180px) {
        width: 570px;
        margin-top: 40px;
    }
`

export default ThePractices
