import { Stack, styled } from '@mui/material'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreProducts from 'GlobalStores/StoreProducts'
import { autorun, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

const Gallery = observer(() => {
    const gallery = useLocalObservable(() => ({
        images: []
    }))

    useEffect(() => {
        let disposeAutorun = autorun(() => {
            if (StoreProducts.GetFlavors().length > 0 && storeCheckout.selectedItem.length > 0) {
                runInAction(() => {
                    let flavor = StoreProducts.GetFlavors().find(item => item.value === storeCheckout.selectedItem)
                    if (flavor) {
                        gallery.images = flavor.images.map(image => {
                            return {
                                original: image,
                                thumbnail: image,
                                originalHeight: '100%'
                            }
                        })
                    }
                })
            }
        })

        return () => {
            disposeAutorun()
        }
    }, [])


    return (
        <ImageGalleryStyled className="fg--image-gallery">
            <ImageGallery
                items={gallery.images}
                autoPlay={false}
                showPlayButton={false}
                showFullscreenButton={false}
                showNav={false}
                slideOnThumbnailOver={false}
                useTranslate3D={false}
            />
        </ImageGalleryStyled>
    )
})

const ImageGalleryStyled = styled(Stack)`
    .image-gallery {
        .image-gallery-slide {
            opacity: 0;
            transform: none !important;
            img {
                border-radius: 12px;
            }
        }
        .image-gallery-slide.center, .image-gallery-center {
            opacity: 1;
        }
        .image-gallery-thumbnails-wrapper {
            margin-top: 20px;
            .image-gallery-thumbnails {
                .image-gallery-thumbnail {
                    margin-right: 16px;
                    transition: none;
                    .image-gallery-thumbnail-image {
                        width: 80px;
                        height: 64px;
                        border-radius: 8px;
                    }
                }
                .image-gallery-thumbnail.active,
                .image-gallery-thumbnail:focus {
                    border: 0;
                    .image-gallery-thumbnail-image {
                        border: 2px solid rgb(255, 130, 14);
                    }
                }
                .image-gallery-thumbnail:hover {
                    border: 0;
                    transition: none;
                }
            }
        }
    }
`

export default Gallery
