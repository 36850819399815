import { Box, Card, CardMedia, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { language } from 'GlobalStores/Language/StoreLanguage'
import { staticFooter } from 'GlobalStores/MainStore'
import { get, upperCase } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Background from './Assets/coming_soon.jpg' 
import UnicityLogo from './Assets/unicity-logo.png'
import { ReactComponent as SpinnerIcon } from './Assets/spinner.svg'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { ReactComponent as ArrowIcon } from './Assets/arrow.svg'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { MainTemplate } from 'Components/Template/MainTemplate'

const Maintenance = observer(() => {

  const { width } = useSizeWindow()

  useEffect(() => {
    StoreTranslate.Dictionaries()
  }, [])

  useEffect(() => {
      // const languagesList = appConfig.language[getCountry()]
      // let resultLanguagesList = ''
      // if (Array.isArray(languagesList)) {
      //     if (languagesList.length === 0) throw 'languagesList should not be empty.'
      //     if (languagesList.length > 1) {
      //         languagesList.forEach(lang => {
      //             resultLanguagesList = resultLanguagesList.concat(lang, ',')
      //         })
      //         resultLanguagesList = resultLanguagesList.substring(0, resultLanguagesList.length - 1)
      //     } else {
      //         resultLanguagesList = languagesList[0]
      //     }
      // } else {
      //     resultLanguagesList = languagesList
      // }
      // getTranslationsList((response, status) => {
      //     if (status) {
      //         const createDictionary = rawDictionary => {
      //             const _temp = {}
      //             Object.keys(rawDictionary).map((label) => {
      //                 if (label !== '_empty_') _temp[label] = rawDictionary[label]
      //             })
      //             return _temp
      //         }

      //         Object.keys(response).map((language) => {
      //             Object.assign(store.dictionaryAll, {
      //                 [language]: createDictionary(response[language])
      //             })
      //         })
      //         setLanguageListRenderedState(true)
      //     }
      // }, resultLanguagesList)

      const textMaintenance = document.getElementById('maintenance-text')
      if (textMaintenance) {
          textMaintenance.innerHTML = get(StoreCountry, `country.maintenance_mode.text.${language.footer}`)
      }
  }, [])
  
  const scrollToBottom = () => {
    const elem = document.getElementById('arrow-scrolldown')
    window.scrollTo({ behavior: 'smooth', top: elem.offsetTop })
  }

  return (
    <ThemeProvider theme={MainTemplate}>
      <Stack flexDirection={width < 900 ? 'column' : 'row'}>
        <Wrapper bg={Background}>
          <OverlayStyled/>
          
          <Stack>
            <Stack sx={{ zIndex: '999', height: '100vh' }} justifyContent={'space-between'} alignItems={'center'} >
              <Stack className='logo' sx={{ width: '145px', padding: '3rem 0' }}>
                <img src={UnicityLogo} alt={UnicityLogo} />
              </Stack>

              <Stack alignItems={'center'}>
                <Typography variant='h2' fontSize={width <= 834 ? '11vw' : '60px'} sx={{ color: '#FFFFFF', textAlign: 'center', padding: '0 20px' }}>
                  <T>under_maintenance</T>
                </Typography>
                {width < 900 && (
                  <ArrowIconStyled 
                    style={{ marginTop: '30px', cursor: 'pointer' }} 
                    onClick={() => scrollToBottom()}
                  />
                )}
              </Stack>

              <Title variant='caption' padding={'3rem 0'} gap={'5px'}>
                <a className='text-contact' 
                  href={`tel:` + get(StoreFeelGreat, `contents.contact.phone`, '')}>
                  {get(StoreFeelGreat, `contents.contact.phone`, '')}
                </a>
                <a className='text-contact' 
                  href={`mailto:` + get(StoreFeelGreat, `contents.contact.email`, '')}>
                  {get(StoreFeelGreat, `contents.contact.email`, '')}
                </a>
              </Title>
            </Stack>
          </Stack>
        </Wrapper>

        <RightWrapper id="arrow-scrolldown" >
          {get(StoreCountry, `country.maintenance_mode.text.${language.footer}`, false) ? (
            <Stack sx={{ padding: '0 50px', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography id="maintenance-text" variant='h4' textAlign="center" ></Typography>
            </Stack>
          ) : (
            <Stack height={'100%'} alignItems={'center'} justifyContent={'center'}>
              <IconSpinnerStyled />
            </Stack>
          )}
        </RightWrapper>
      </Stack>
    </ThemeProvider>
  )
})

const Wrapper = styled('div')`
  position: relative;
  background-image: url(${props => props.bg});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  height: 100vh;

  @media screen and (max-width: 900px){
    width: 100%;
  }
`

const OverlayStyled = styled(Stack)`
  background-color: rgb(55, 66, 80);
  opacity: .6;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
`

const Title = styled(Typography)`
  font-family: 'Prompt', sans-serif !important;
  font-style: normal !important;
  font-weight: 500;
  color: #FFFFFF;

  display: flex;
  flex-direction: column;
  align-items: center;

  .text-contact {
    color: #FFFFFF;
    text-decoration: none;

  }
`

const RightWrapper = styled('div')`
  background-color: #FFFFFF;
  width: 50%;
  height: 100vh;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const IconSpinnerStyled = styled(SpinnerIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 65px;
    height: 65px;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const ArrowIconStyled = styled(ArrowIcon)`
  width: 25px;
  height: 25px;
  margin: 10px 0 0;
  padding: 5px;
  border: 1px solid white;
  border-radius: 100px;
`
export default Maintenance