import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import httpHelper from 'Services/HttpHelper'
import { makeAutoObservable, runInAction } from 'mobx'

class SessionAuth {
    storeKey = 'login-extends'

    user = null
    users = []

    needResetPassword = false

    SetNeedResetPassword(value) {
        this.needResetPassword = value
    }

    NeedResetPassword() {
        return this.needResetPassword
    }

    ResetSessionStorage() {
        // sessionStorage.removeItem('menu')
        // sessionStorage.removeItem('menuGroupA-desktop')
        // sessionStorage.removeItem('menuGroupA-mobile')
        // sessionStorage.removeItem('menuGroupB-desktop')
        // sessionStorage.removeItem('menuGroupB-mobile')
        // sessionStorage.removeItem('genealogy-filter')

        // sessionStorage.removeItem('dummy-user')
        // sessionStorage.removeItem('customerData')
        // sessionStorage.removeItem('customerHref')
        // sessionStorage.removeItem('customerToken')
        // sessionStorage.removeItem(`${StoreAuth.GetId()}-breadcrumbs`)
        // sessionStorage.removeItem(`${StoreAuth.GetId()}-breadcrumbsHistory`)
        // sessionStorage.removeItem('user-token')
        // sessionStorage.removeItem('user-href')
        // sessionStorage.removeItem('onself-response')
        // sessionStorage.removeItem('tokenExpire')
        sessionStorage.clear()
        localStorage.clear()
    }

    async Current() {
        await this.GetStoredSession()
            .then(response => {
                runInAction(() => {
                    this.user = response
                })
            })
            .catch(error => {
                console.log('error', error)
            })

        return this.user
    }

    Token() {
        return this.GetLoginExtend('token')
    }

    Href() {
        return this.GetLoginExtend('href')
    }

    CustomerData() {
        let customerData = sessionStorage.getItem('customerData')
        if (customerData) {
            return JSON.parse(customerData)
        } else {
            return null
        }
    }

    SaveSession(data) {
        delete data.customerData
        let loginExtends = this.GetLoginExtends()
        loginExtends[storeCountry.CountryLowerCase()] = data

        this.SaveLoginExtends(JSON.stringify(loginExtends))

        runInAction(() => {
            this.user = data
        })
    }

    GetLoginExtends() {
        const defaultObjects = {
            [storeCountry.CountryLowerCase()]: null
        }

        return localStorage.getItem(this.storeKey) ? JSON.parse(localStorage.getItem(this.storeKey)) : defaultObjects
    }

    GetLoginExtend(key) {
        const loginExtends = this.GetLoginExtends()

        return loginExtends[storeCountry.CountryLowerCase()] ? loginExtends[storeCountry.CountryLowerCase()][key] || null : null
    }

    SaveLoginExtends(data) {
        localStorage.setItem(this.storeKey, data)
    }

    SetLoginExtend(key, value) {
        const loginExtends = this.GetLoginExtends()
        if (loginExtends[storeCountry.CountryLowerCase()]) {
            loginExtends[storeCountry.CountryLowerCase()][key] = value
        }

        this.SaveLoginExtends(JSON.stringify(loginExtends))
    }

    async CheckToken(token, country, baId) {
        const response = await httpHelper.Get({
            url: `https://member-calls2.unicity.com/etlV2/refreshToken?ushopCountryCode=${country}&baId=${baId}&login_id=${baId}`,
            config: {
                headers: {
                    'authorization-hydra': `Bearer ${token}`
                }
            }
        })

        return response
    }

    ShouldRestore() {
        return new Promise((resolve, reject) => {
            if (this.GetLoginExtends()[storeCountry.CountryLowerCase()]) {
                const hasToken = this.GetLoginExtend('token')
                if (hasToken) {
                    resolve(true)
                } 
            } else {
                this.ResetSessionStorage()
                reject({
                    message: 'no-token'
                })
            }
        })
    }

    FetchAll() {
        this.users = []
        return new Promise((resolve, reject) => {
            let loginExtends = localStorage.getItem(this.storeKey)
            if (loginExtends) {
                loginExtends = JSON.parse(loginExtends)
                if (loginExtends[storeCountry.CountryLowerCase()] && loginExtends.length > 0) {
                    loginExtends[storeCountry.CountryLowerCase()].map((v, k) => {
                        this.users.push(v)
                    })
                } else {
                    reject(this.users)
                }
            }
        })
    }

    GetStoredSession() {
        return new Promise((resolve, reject) => {
            let loginExtends = localStorage.getItem(this.storeKey)
            if (loginExtends) {
                loginExtends = JSON.parse(loginExtends)
                resolve(loginExtends[storeCountry.CountryLowerCase()])
            }

            reject(null)
        })
    }

    RemoveSession(force = false) {
        if (this.user || force === true) {
            localStorage.removeItem('last-visited')
            const clearData = resolve => {
                let stored = this.GetLoginExtends()
                if (stored[storeCountry.CountryLowerCase()]) {
                    delete stored[storeCountry.CountryLowerCase()]
                    this.SaveLoginExtends(JSON.stringify(stored))
                }

                this.ResetSessionStorage()
                runInAction(() => {
                    this.user = null
                })
                if (resolve) {
                    resolve()
                }
            }

            if (force === false) {
                clearData()
            } else {
                return new Promise((resolve, reject) => {
                    clearData(resolve)
                })
            }
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new SessionAuth()
