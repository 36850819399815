const ENV_DEV = 'DEV'
const ENV_LIVE = 'LIVE'

const FORCE_ENV = ENV_LIVE
// const FORCE_ENV = ENV_DEV

const ENV = IsForceEnv() ? FORCE_ENV : /localhost/.test(window.location.href) ? ENV_DEV : ENV_LIVE

function IsForceEnv() {
    if (FORCE_ENV) {
        return true
    } else {
        return false
    }
}

function MemberCall() {
    return ENV === ENV_DEV ? 'member-calls2-dev' : 'member-calls2'
}

function MemberCalls2(forceLive = false) {
    return ENV === ENV_DEV && forceLive === false ? 'member-calls2-dev' : 'member-calls2'
}

function MemberCalls2Dev() {
    return 'member-calls2-dev'
}

function Hydra() {
    return ENV === ENV_DEV ? 'https://hydraqa.unicity.net/v5a-test' : 'https://hydra.unicity.net/v5a'
}

export const ServerEnv = {
    MemberCall,
    MemberCalls2,
    MemberCalls2Dev,
    Hydra,
    ENV
}
