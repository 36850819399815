import { Stack, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactComponent as LemonadeIcon } from './Assets/LemonadeIcon.svg'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { DefaultButton } from '../Forms'
import { NumberHelpers } from 'Helpers/NumberHelpers'
import moment from 'moment-timezone'
import { runInAction } from 'mobx'
import { storeElement } from 'GlobalStores/StoreElement'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { MainTemplate } from 'Components/Template/MainTemplate'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'

const DuplicatedOrder = observer(({ confirmPlaceOrder, setOpenModal, paymentId, dateAndTime, totalPrice, is_dubplicate, currency }) => {
    const translate = useTranslate()
    const isThaiLanguage = StoreTranslate.CurrentLanguage() === 'TH'

    //create function convert date and time from this string "2024-03-06T14:38:02.000Z" look like "04-Mar-2024 at 23:55:43"
    function convertToThailandTime(utcTimestamp) {
        const inputDate = moment(utcTimestamp).subtract(7, 'hours')

        return inputDate
            .clone()
            .tz('Asia/Thailand')
            .format(`DD-MMM-YYYY [${isThaiLanguage ? 'เวลา' : 'at'}] HH:mm:ss`)
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack justifyContent={'center'} alignItems={'center'} flexDirection={'column'} width={'100%'}>
                <LemonadeIcon />
                <Stack mt="32px" textAlign={'center'}>
                    <Typography variant="h3">
                        <T>duplicated_order_title</T>
                    </Typography>
                </Stack>
                <Stack mt="16px" textAlign={'center'}>
                    <Typography fontSize={'16px'}>
                        {translate('duplicated_order_description')
                            .replace('{{payment_id}}', paymentId)
                            .replace('{{date_and_time}}', convertToThailandTime(dateAndTime))
                            .replace(
                                '{{total_price}}',
                                NumberHelpers.currency({
                                    currency: currency || 'THB',
                                    number: totalPrice || 0,
                                    swapPosition: true
                                })
                            )
                            .replace('THB', translate('currency_THB'))}
                    </Typography>
                </Stack>
                <Stack
                    mt="32px"
                    textAlign={'center'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={{
                        xs: '100%',
                        sm: '100%',
                        md: '325px'
                    }}>
                    <DefaultButton
                        onClick={confirmPlaceOrder}
                        titleText={translate('place_order')}
                        type="button"
                        backgroundcolor="#5A8FC4"
                        fontSize={'20px'}
                        height="48px"
                    />
                    <Typography
                        mt="16px"
                        variant="link3"
                        onClick={() => {
                            setOpenModal(false)

                            runInAction(() => {
                                storeElement.showCardFrontDrop = false
                                storeCheckout.paymentLoading = false
                            })
                        }}
                        sx={{ cursor: 'pointer' }}>
                        <T>cancel</T>
                    </Typography>
                </Stack>
            </Stack>
        </ThemeProvider>
    )
})

export default DuplicatedOrder
