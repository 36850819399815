export const AllCountry = [
    { name: 'Afghanistan', code: '004', alpha2: 'AF', alpha3: 'AFG', currencies: [], languages: [] },
    { name: 'Åland Islands', code: '248', alpha2: 'AX', alpha3: 'ALA', currencies: [], languages: [] },
    { name: 'Albania', code: '008', alpha2: 'AL', alpha3: 'ALB', currencies: [], languages: [] },
    {
        name: 'Algeria',
        code: '012',
        alpha2: 'DZ',
        alpha3: 'DZA',
        currencies: [{ name: 'Algerian dinar', 'name@en': 'Algerian dinar', code: 'DZD', symbol: 'DA', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'French', 'name@en': 'French', code: 'fr', default: false },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'American Samoa', code: '016', alpha2: 'AS', alpha3: 'ASM', currencies: [], languages: [] },
    { name: 'Andorra', code: '020', alpha2: 'AD', alpha3: 'AND', currencies: [], languages: [] },
    { name: 'Angola', code: '024', alpha2: 'AO', alpha3: 'AGO', currencies: [], languages: [] },
    {
        name: 'Anguilla',
        code: '660',
        alpha2: 'AI',
        alpha3: 'AIA',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Antarctica', code: '010', alpha2: 'AQ', alpha3: 'ATA', currencies: [], languages: [] },
    { name: 'Antigua and Barbuda', code: '028', alpha2: 'AG', alpha3: 'ATG', currencies: [], languages: [] },
    { name: 'Argentina', code: '032', alpha2: 'AR', alpha3: 'ARG', currencies: [], languages: [] },
    { name: 'Armenia', code: '051', alpha2: 'AM', alpha3: 'ARM', currencies: [], languages: [] },
    { name: 'Aruba', code: '533', alpha2: 'AW', alpha3: 'ABW', currencies: [], languages: [] },
    {
        name: 'Australia',
        code: '036',
        alpha2: 'AU',
        alpha3: 'AUS',
        currencies: [{ name: 'Australian dollar', 'name@en': 'Australian dollar', code: 'AUD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    {
        name: 'Austria',
        code: '040',
        alpha2: 'AT',
        alpha3: 'AUT',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [
            { name: 'Deutsch', 'name@en': 'German', code: 'de', default: true },
            { name: 'English', 'name@en': 'English', code: 'en', default: false }
        ],
        translationName: 'austria'
    },
    { name: 'Azerbaijan', code: '031', alpha2: 'AZ', alpha3: 'AZE', currencies: [], languages: [] },
    {
        name: 'Bahrain',
        code: '048',
        alpha2: 'BH',
        alpha3: 'BHR',
        currencies: [{ name: 'Bahraini dinar', 'name@en': 'Bahraini dinar', code: 'BHD', symbol: '.د.ب', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'Bangladesh', code: '050', alpha2: 'BD', alpha3: 'BGD', currencies: [], languages: [] },
    {
        name: 'Barbados',
        code: '052',
        alpha2: 'BB',
        alpha3: 'BRB',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Belarus', code: '112', alpha2: 'BY', alpha3: 'BLR', currencies: [], languages: [] },
    {
        name: 'Belgium',
        code: '056',
        alpha2: 'BE',
        alpha3: 'BEL',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [
            { name: 'Français', 'name@en': 'French', code: 'fr', default: true },
            { name: 'English', 'name@en': 'English', code: 'en', default: false },
            { name: 'Nederlands', 'name@en': 'Dutch', code: 'nl', default: false }
        ],
        translationName: 'belgium'
    },
    { name: 'Belize', code: '084', alpha2: 'BZ', alpha3: 'BLZ', currencies: [], languages: [] },
    { name: 'Benin', code: '204', alpha2: 'BJ', alpha3: 'BEN', currencies: [], languages: [] },
    { name: 'Bermuda', code: '060', alpha2: 'BM', alpha3: 'BMU', currencies: [], languages: [] },
    { name: 'Bhutan', code: '064', alpha2: 'BT', alpha3: 'BTN', currencies: [], languages: [] },
    { name: 'Bolivia, Plurinational State of', code: '068', alpha2: 'BO', alpha3: 'BOL', currencies: [], languages: [] },
    { name: 'Bosnia and Herzegovina', code: '070', alpha2: 'BA', alpha3: 'BIH', currencies: [], languages: [] },
    { name: 'Botswana', code: '072', alpha2: 'BW', alpha3: 'BWA', currencies: [], languages: [] },
    { name: 'Bouvet Island', code: '074', alpha2: 'BV', alpha3: 'BVT', currencies: [], languages: [] },
    { name: 'Brazil', code: '076', alpha2: 'BR', alpha3: 'BRA', currencies: [], languages: [] },
    { name: 'British Indian Ocean Territory', code: '086', alpha2: 'IO', alpha3: 'IOT', currencies: [], languages: [] },
    {
        name: 'Brunei Darussalam',
        code: '096',
        alpha2: 'BN',
        alpha3: 'BRN',
        currencies: [{ name: 'Brunei dollar', 'name@en': 'Brunei dollar', code: 'BND', symbol: '$', default: true }],
        languages: [{ name: 'Malay', 'name@en': 'Malay', code: 'ms', default: true }]
    },
    { name: 'Bulgaria', code: '100', alpha2: 'BG', alpha3: 'BGR', currencies: [], languages: [] },
    { name: 'Burkina Faso', code: '854', alpha2: 'BF', alpha3: 'BFA', currencies: [], languages: [] },
    { name: 'Burundi', code: '108', alpha2: 'BI', alpha3: 'BDI', currencies: [], languages: [] },
    {
        name: 'Cambodia',
        code: '116',
        alpha2: 'KH',
        alpha3: 'KHM',
        currencies: [
            { name: 'Cambodian riel', 'name@en': 'Cambodian riel', code: 'KHR', symbol: '៛', default: true },
            { name: 'Cambodian riel', 'name@en': 'Cambodian riel', code: 'KSD', symbol: '៛', default: false }
        ],
        languages: [{ name: 'Khmer', 'name@en': 'Khmer', code: 'km', default: true }]
    },
    { name: 'Cameroon', code: '120', alpha2: 'CM', alpha3: 'CMR', currencies: [], languages: [] },
    {
        name: 'Canada',
        translationName: 'canada',
        code: '124',
        alpha2: 'CA',
        alpha3: 'CAN',
        currencies: [{ name: 'Canadian dollar', 'name@en': 'Canadian dollar', code: 'CAD', symbol: '$', default: true }],
        languages: [
            { name: 'Français', 'name@en': 'French', code: 'fr', default: false },
            { name: 'English', 'name@en': 'English', code: 'en', default: true }
        ]
    },
    { name: 'Cape Verde', code: '132', alpha2: 'CV', alpha3: 'CPV', currencies: [], languages: [] },
    {
        name: 'Caribbean Netherlands',
        code: '535',
        alpha2: 'BQ',
        alpha3: 'BES',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    {
        name: 'Cayman Islands',
        code: '136',
        alpha2: 'KY',
        alpha3: 'CYM',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Central African Republic', code: '140', alpha2: 'CF', alpha3: 'CAF', currencies: [], languages: [] },
    { name: 'Chad', code: '148', alpha2: 'TD', alpha3: 'TCD', currencies: [], languages: [] },
    {
        name: 'Chile',
        code: '152',
        alpha2: 'CL',
        alpha3: 'CHL',
        currencies: [
            { name: 'Chilean peso', 'name@en': 'Chilean peso', code: 'CLP', symbol: '$', default: true },
            { name: 'Unidad de Fomento', 'name@en': 'Unidad de Fomento', code: 'CLF', symbol: null, default: false }
        ],
        languages: [
            { name: 'Español', 'name@en': 'Spanish', code: 'es', default: true },
            { name: 'Mapudungun', 'name@en': 'Mapudungun', code: 'arn', default: false }
        ]
    },
    {
        name: 'China',
        code: '156',
        alpha2: 'CN',
        alpha3: 'CHN',
        currencies: [
            { name: 'Chinese Renminbi yuan', 'name@en': 'Chinese Renminbi yuan', code: 'CNY', symbol: '¥', default: true },
            { name: 'Chinese Renminbi yuan', 'name@en': 'Chinese Renminbi yuan', code: 'RMB', symbol: '¥', default: false }
        ],
        languages: [
            { name: 'Chinese', 'name@en': 'Chinese', code: 'zh', default: true },
            { name: 'Mongolian', 'name@en': 'Mongolian', code: 'mn', default: false },
            { name: 'Tibetan', 'name@en': 'Tibetan', code: 'bo', default: false },
            { name: 'Uyghur', 'name@en': 'Uyghur', code: 'ug', default: false },
            { name: 'Yi', 'name@en': 'Yi', code: 'ii', default: false }
        ]
    },
    { name: 'Christmas Island', code: '162', alpha2: 'CX', alpha3: 'CXR', currencies: [], languages: [] },
    { name: 'Cocos (Keeling) Islands', code: '166', alpha2: 'CC', alpha3: 'CCK', currencies: [], languages: [] },
    {
        name: 'Colombia',
        code: '170',
        alpha2: 'CO',
        alpha3: 'COL',
        currencies: [{ name: 'Colombian peso', 'name@en': 'Colombian peso', code: 'COP', symbol: '$', default: true }],
        languages: [{ name: 'Español', 'name@en': 'Spanish', code: 'es', default: true }]
    },
    { name: 'Comoros', code: '174', alpha2: 'KM', alpha3: 'COM', currencies: [], languages: [] },
    { name: 'Cook Islands', code: '184', alpha2: 'CK', alpha3: 'COK', currencies: [], languages: [] },
    { name: 'Costa Rica', code: '188', alpha2: 'CR', alpha3: 'CRI', currencies: [], languages: [] },
    { name: "Côte d'Ivoire", code: '384', alpha2: 'CI', alpha3: 'CIV', currencies: [], languages: [] },
    { name: 'Croatia', code: '191', alpha2: 'HR', alpha3: 'HRV', currencies: [], languages: [] },
    { name: 'Cuba', code: '192', alpha2: 'CU', alpha3: 'CUB', currencies: [], languages: [] },
    {
        name: 'Curaçao',
        code: '531',
        alpha2: 'CW',
        alpha3: 'CUW',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Cyprus', code: '196', alpha2: 'CY', alpha3: 'CYP', currencies: [], languages: [] },
    {
        name: 'Czech Republic',
        code: '203',
        alpha2: 'CZ',
        alpha3: 'CZE',
        currencies: [{ name: 'Czech koruna', 'name@en': 'Czech koruna', code: 'CZK', symbol: 'Kč', default: true }],
        languages: [{ name: 'Czech', 'name@en': 'Czech', code: 'cs', default: true }]
    },
    { name: 'Democratic Republic of the Congo', code: '180', alpha2: 'CD', alpha3: 'COD', currencies: [], languages: [] },
    {
        name: 'Denmark',
        code: '208',
        alpha2: 'DK',
        alpha3: 'DNK',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }],
        translationName: 'Denmark'
    },
    { name: 'Djibouti', code: '262', alpha2: 'DJ', alpha3: 'DJI', currencies: [], languages: [] },
    {
        name: 'Dominica',
        code: '212',
        alpha2: 'DM',
        alpha3: 'DMA',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    {
        name: 'Dominican Republic',
        code: '214',
        alpha2: 'DO',
        alpha3: 'DOM',
        currencies: [{ name: 'Dominican peso', 'name@en': 'Dominican peso', code: 'DOP', symbol: '$', default: true }],
        languages: [{ name: 'Español', 'name@en': 'Spanish', code: 'es', default: true }],
        translationName: 'dominican_republic'
    },
    { name: 'Ecuador', code: '218', alpha2: 'EC', alpha3: 'ECU', currencies: [], languages: [] },
    {
        name: 'Egypt',
        code: '818',
        alpha2: 'EG',
        alpha3: 'EGY',
        currencies: [{ name: 'Bahraini dinar', 'name@en': 'Bahraini dinar', code: 'BHD', symbol: '.د.ب', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'El Salvador', code: '222', alpha2: 'SV', alpha3: 'SLV', currencies: [], languages: [] },
    { name: 'Equatorial Guinea', code: '226', alpha2: 'GQ', alpha3: 'GNQ', currencies: [], languages: [] },
    { name: 'Eritrea', code: '232', alpha2: 'ER', alpha3: 'ERI', currencies: [], languages: [] },
    { name: 'Estonia', code: '233', alpha2: 'EE', alpha3: 'EST', currencies: [], languages: [] },
    { name: 'Ethiopia', code: '231', alpha2: 'ET', alpha3: 'ETH', currencies: [], languages: [] },
    { name: 'Falkland Islands', code: '238', alpha2: 'FK', alpha3: 'FLK', currencies: [], languages: [] },
    { name: 'Faroe Islands', code: '234', alpha2: 'FO', alpha3: 'FRO', currencies: [], languages: [] },
    { name: 'Fiji', code: '242', alpha2: 'FJ', alpha3: 'FJI', currencies: [], languages: [] },
    {
        name: 'Finland',
        code: '246',
        alpha2: 'FI',
        alpha3: 'FIN',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [
            { name: 'Finnish', 'name@en': 'Finnish', code: 'fi', default: true },
            { name: 'Swedish', 'name@en': 'Swedish', code: 'sv', default: false }
        ]
    },
    {
        name: 'France',
        code: '250',
        alpha2: 'FR',
        alpha3: 'FRA',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: false },
            { name: 'Français', 'name@en': 'French', code: 'fr', default: true }
        ],
        translationName: 'france'
    },
    { name: 'French Guiana', code: '254', alpha2: 'GF', alpha3: 'GUF', currencies: [], languages: [] },
    { name: 'French Polynesia', code: '258', alpha2: 'PF', alpha3: 'PYF', currencies: [], languages: [] },
    { name: 'French Southern and Antarctic Lands', code: '260', alpha2: 'TF', alpha3: 'ATF', currencies: [], languages: [] },
    { name: 'Gabon', code: '266', alpha2: 'GA', alpha3: 'GAB', currencies: [], languages: [] },
    { name: 'Georgia', code: '268', alpha2: 'GE', alpha3: 'GEO', currencies: [], languages: [] },
    {
        name: 'Germany',
        code: '49',
        alpha2: 'DE',
        alpha3: 'DEU',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: false },
            { name: 'Deutsch', 'name@en': 'German', code: 'de', default: true }
        ],
        translationName: 'germany'
    },
    { name: 'Ghana', code: '288', alpha2: 'GH', alpha3: 'GHA', currencies: [], languages: [] },
    { name: 'Gibraltar', code: '292', alpha2: 'GI', alpha3: 'GIB', currencies: [], languages: [] },
    { name: 'Greece', code: '300', alpha2: 'GR', alpha3: 'GRC', currencies: [], languages: [] },
    { name: 'Greenland', code: '304', alpha2: 'GL', alpha3: 'GRL', currencies: [], languages: [] },
    {
        name: 'Grenada',
        code: '308',
        alpha2: 'GD',
        alpha3: 'GRD',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Guadeloupe', code: '312', alpha2: 'GP', alpha3: 'GLP', currencies: [], languages: [] },
    { name: 'Guam', code: '316', alpha2: 'GU', alpha3: 'GUM', currencies: [], languages: [] },
    { name: 'Guatemala', code: '320', alpha2: 'GT', alpha3: 'GTM', currencies: [], languages: [] },
    { name: 'Guernsey', code: '831', alpha2: 'GG', alpha3: 'GGY', currencies: [], languages: [] },
    { name: 'Guinea', code: '324', alpha2: 'GN', alpha3: 'GIN', currencies: [], languages: [] },
    { name: 'Guinea-Bissau', code: '624', alpha2: 'GW', alpha3: 'GNB', currencies: [], languages: [] },
    {
        name: 'Gulf Cooperation Council',
        code: '999',
        alpha2: 'GC',
        alpha3: 'GCC',
        currencies: [{ name: 'Bahraini dinar', 'name@en': 'Bahraini dinar', code: 'BHD', symbol: '.د.ب', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    {
        name: 'Guyana',
        code: '328',
        alpha2: 'GY',
        alpha3: 'GUY',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Haiti', code: '332', alpha2: 'HT', alpha3: 'HTI', currencies: [], languages: [] },
    { name: 'Heard Island and McDonald Islands', code: '334', alpha2: 'HM', alpha3: 'HMD', currencies: [], languages: [] },
    { name: 'Honduras', code: '340', alpha2: 'HN', alpha3: 'HND', currencies: [], languages: [] },
    {
        name: 'Hong Kong',
        code: '344',
        alpha2: 'HK',
        alpha3: 'HKG',
        currencies: [{ name: 'Hong Kong dollar', 'name@en': 'Hong Kong dollar', code: 'HKD', symbol: '$', default: true }],
        languages: [{ name: 'Chinese', 'name@en': 'Chinese', code: 'zh', default: true }]
    },
    {
        name: 'Hungary',
        code: '348',
        alpha2: 'HU',
        alpha3: 'HUN',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }],
        translationName: 'hungary'
    },
    { name: 'Iceland', code: '352', alpha2: 'IS', alpha3: 'ISL', currencies: [], languages: [] },
    {
        name: 'India',
        code: '356',
        alpha2: 'IN',
        alpha3: 'IND',
        currencies: [{ name: 'Indian rupee', 'name@en': 'Indian rupee', code: 'INR', symbol: '₹', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Hindi', 'name@en': 'Hindi', code: 'hi', default: false }
        ]
    },
    {
        name: 'Indonesia',
        code: '360',
        alpha2: 'ID',
        alpha3: 'IDN',
        currencies: [{ name: 'Indonesian rupiah', 'name@en': 'Indonesian rupiah', code: 'IDR', symbol: 'Rp', default: true }],
        languages: [{ name: 'Indonesian', 'name@en': 'Indonesian', code: 'id', default: true }]
    },
    { name: 'Iran, Islamic Republic of', code: '364', alpha2: 'IR', alpha3: 'IRN', currencies: [], languages: [] },
    { name: 'Iraq', code: '368', alpha2: 'IQ', alpha3: 'IRQ', currencies: [], languages: [] },
    { name: 'Isle of Man', code: '833', alpha2: 'IM', alpha3: 'IMN', currencies: [], languages: [] },
    { name: 'Israel', code: '376', alpha2: 'IL', alpha3: 'ISR', currencies: [], languages: [] },
    {
        name: 'Italy',
        code: '380',
        alpha2: 'IT',
        alpha3: 'ITA',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'German', 'name@en': 'German', code: 'de', default: false }
        ],
        translationName: 'italy'
    },
    {
        name: 'Jamaica',
        code: '388',
        alpha2: 'JM',
        alpha3: 'JAM',
        currencies: [{ name: 'Jamaican dollar', 'name@en': 'Jamaican dollar', code: 'JMD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    {
        name: 'Japan',
        code: '392',
        alpha2: 'JP',
        alpha3: 'JPN',
        currencies: [{ name: 'Japanese yen', 'name@en': 'Japanese yen', code: 'JPY', symbol: '¥', default: true }],
        languages: [{ name: 'Japanese', 'name@en': 'Japanese', code: 'ja', default: true }]
    },
    { name: 'Jersey', code: '832', alpha2: 'JE', alpha3: 'JEY', currencies: [], languages: [] },
    {
        name: 'Jordan',
        code: '400',
        alpha2: 'JO',
        alpha3: 'JOR',
        currencies: [{ name: 'Jordanian dinar', 'name@en': 'Jordanian dinar', code: 'JOD', symbol: 'JOD', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'Kazakhstan', code: '398', alpha2: 'KZ', alpha3: 'KAZ', currencies: [], languages: [] },
    { name: 'Kenya', code: '404', alpha2: 'KE', alpha3: 'KEN', currencies: [], languages: [] },
    { name: 'Kiribati', code: '296', alpha2: 'KI', alpha3: 'KIR', currencies: [], languages: [] },
    {
        name: 'Kuwait',
        code: '414',
        alpha2: 'KW',
        alpha3: 'KWT',
        currencies: [
            { name: 'Kuwaiti dinar', 'name@en': 'Kuwaiti dinar', code: 'KWD', symbol: 'د.ك', default: true },
            { name: 'Bahraini dinar', 'name@en': 'Bahraini dinar', code: 'BHD', symbol: '.د.ب', default: false }
        ],
        languages: [
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false },
            { name: 'English', 'name@en': 'English', code: 'en', default: true }
        ]
    },
    { name: 'Kyrgyzstan', code: '417', alpha2: 'KG', alpha3: 'KGZ', currencies: [], languages: [] },
    {
        name: 'Laos',
        code: '418',
        alpha2: 'LA',
        alpha3: 'LAO',
        currencies: [{ name: 'Lao kip', 'name@en': 'Lao kip', code: 'LAK', symbol: '₭', default: true }],
        languages: [{ name: 'Lao', 'name@en': 'Lao', code: 'lo', default: true }]
    },
    { name: 'Latvia', code: '428', alpha2: 'LV', alpha3: 'LVA', currencies: [], languages: [] },
    {
        name: 'Lebanon',
        code: '422',
        alpha2: 'LB',
        alpha3: 'LBN',
        currencies: [{ name: 'Lebanese pound', 'name@en': 'Lebanese pound', code: 'LBP', symbol: 'ل.ل.‎', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'Lesotho', code: '426', alpha2: 'LS', alpha3: 'LSO', currencies: [], languages: [] },
    { name: 'Liberia', code: '430', alpha2: 'LR', alpha3: 'LBR', currencies: [], languages: [] },
    { name: 'Libya', code: '434', alpha2: 'LY', alpha3: 'LBY', currencies: [], languages: [] },
    {
        name: 'Liechtenstein',
        code: '438',
        alpha2: 'LI',
        alpha3: 'LIE',
        currencies: [{ name: 'Swiss franc', 'name@en': 'Swiss franc', code: 'CHF', symbol: 'CHF', default: true }],
        languages: [{ name: 'German', 'name@en': 'German', code: 'de', default: true }]
    },
    { name: 'Lithuania', code: '440', alpha2: 'LT', alpha3: 'LTU', currencies: [], languages: [] },
    {
        name: 'Luxembourg',
        code: '442',
        alpha2: 'LU',
        alpha3: 'LUX',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Deutsch', 'name@en': 'German', code: 'de', default: false },
            { name: 'Français', 'name@en': 'French', code: 'fr', default: false }
        ],
        translationName: 'luxembourg'
    },
    {
        name: 'Macau',
        code: '446',
        alpha2: 'MO',
        alpha3: 'MAC',
        currencies: [
            { name: 'Macanese pataca', 'name@en': 'Macanese pataca', code: 'MOP', symbol: 'MOP$', default: true },
            { name: 'Hong Kong dollar', 'name@en': 'Hong Kong dollar', code: 'HKD', symbol: '$', default: false }
        ],
        languages: [{ name: 'Chinese', 'name@en': 'Chinese', code: 'zh', default: true }]
    },
    { name: 'Madagascar', code: '450', alpha2: 'MG', alpha3: 'MDG', currencies: [], languages: [] },
    { name: 'Malawi', code: '454', alpha2: 'MW', alpha3: 'MWI', currencies: [], languages: [] },
    {
        name: 'Malaysia',
        code: '458',
        alpha2: 'MY',
        alpha3: 'MYS',
        currencies: [{ name: 'Malaysian ringgit', 'name@en': 'Malaysian ringgit', code: 'MYR', symbol: 'RM', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Malay', 'name@en': 'Malay', code: 'ms', default: false }
        ]
    },
    { name: 'Maldives', code: '462', alpha2: 'MV', alpha3: 'MDV', currencies: [], languages: [] },
    { name: 'Mali', code: '466', alpha2: 'ML', alpha3: 'MLI', currencies: [], languages: [] },
    { name: 'Malta', code: '470', alpha2: 'MT', alpha3: 'MLT', currencies: [], languages: [] },
    { name: 'Marshall Islands', code: '584', alpha2: 'MH', alpha3: 'MHL', currencies: [], languages: [] },
    { name: 'Martinique', code: '474', alpha2: 'MQ', alpha3: 'MTQ', currencies: [], languages: [] },
    { name: 'Mauritania', code: '478', alpha2: 'MR', alpha3: 'MRT', currencies: [], languages: [] },
    { name: 'Mauritius', code: '480', alpha2: 'MU', alpha3: 'MUS', currencies: [], languages: [] },
    { name: 'Mayotte', code: '175', alpha2: 'YT', alpha3: 'MYT', currencies: [], languages: [] },
    {
        name: 'Mexico',
        code: '484',
        alpha2: 'MX',
        alpha3: 'MEX',
        currencies: [{ name: 'Mexican peso', 'name@en': 'Mexican peso', code: 'MXN', symbol: '$', default: true }],
        languages: [{ name: 'Español', 'name@en': 'Spanish', code: 'es', default: true }]
    },
    { name: 'Micronesia, Federated States of', code: '583', alpha2: 'FM', alpha3: 'FSM', currencies: [], languages: [] },
    { name: 'Moldova, Republic of', code: '498', alpha2: 'MD', alpha3: 'MDA', currencies: [], languages: [] },
    { name: 'Monaco', code: '492', alpha2: 'MC', alpha3: 'MCO', currencies: [], languages: [] },
    {
        name: 'Mongolia',
        code: '496',
        alpha2: 'MN',
        alpha3: 'MNG',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'Mongolian', 'name@en': 'Mongolian', code: 'mn', default: true }]
    },
    { name: 'Montenegro', code: '499', alpha2: 'ME', alpha3: 'MNE', currencies: [], languages: [] },
    { name: 'Montserrat', code: '500', alpha2: 'MS', alpha3: 'MSR', currencies: [], languages: [] },
    {
        name: 'Morocco',
        code: '504',
        alpha2: 'MA',
        alpha3: 'MAR',
        currencies: [{ name: 'Moroccan dirham', 'name@en': 'Moroccan dirham', code: 'MAD', symbol: 'MAD', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'Mozambique', code: '508', alpha2: 'MZ', alpha3: 'MOZ', currencies: [], languages: [] },
    {
        name: 'Myanmar',
        code: '104',
        alpha2: 'MM',
        alpha3: 'MMR',
        currencies: [{ name: 'Myanmar kyat', 'name@en': 'Myanmar kyat', code: 'MMK', symbol: 'K', default: true }],
        languages: [{ name: 'Burmese', 'name@en': 'Burmese', code: 'my', default: true }]
    },
    { name: 'Namibia', code: '516', alpha2: 'NA', alpha3: 'NAM', currencies: [], languages: [] },
    { name: 'Nauru', code: '520', alpha2: 'NR', alpha3: 'NRU', currencies: [], languages: [] },
    { name: 'Nepal', code: '524', alpha2: 'NP', alpha3: 'NPL', currencies: [], languages: [] },
    {
        name: 'Netherlands ',
        code: '528',
        alpha2: 'NL',
        alpha3: 'NLD',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [
            { name: 'Deutsch', 'name@en': 'German', code: 'de', default: true },
            { name: 'English', 'name@en': 'English', code: 'en', default: false },
            { name: 'Nederlands', 'name@en': 'Dutch', code: 'nl', default: false }
        ],
        translationName: 'netherlands'
    },
    { name: 'New Caledonia', code: '540', alpha2: 'NC', alpha3: 'NCL', currencies: [], languages: [] },
    {
        name: 'New Zealand',
        code: '554',
        alpha2: 'NZ',
        alpha3: 'NZL',
        currencies: [{ name: 'New Zealand dollar', 'name@en': 'New Zealand dollar', code: 'NZD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Nicaragua', code: '558', alpha2: 'NI', alpha3: 'NIC', currencies: [], languages: [] },
    { name: 'Niger', code: '562', alpha2: 'NE', alpha3: 'NER', currencies: [], languages: [] },
    {
        name: 'Nigeria',
        code: '566',
        alpha2: 'NG',
        alpha3: 'NGA',
        currencies: [{ name: 'Nigerian naira', 'name@en': 'Nigerian naira', code: 'NGN', symbol: '₦', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Niue', code: '570', alpha2: 'NU', alpha3: 'NIU', currencies: [], languages: [] },
    { name: 'Norfolk Island', code: '574', alpha2: 'NF', alpha3: 'NFK', currencies: [], languages: [] },
    { name: 'North Korea', code: '408', alpha2: 'KP', alpha3: 'PRK', currencies: [], languages: [] },
    { name: 'Northern Mariana Islands', code: '580', alpha2: 'MP', alpha3: 'MNP', currencies: [], languages: [] },
    {
        name: 'Norway',
        code: '578',
        alpha2: 'NO',
        alpha3: 'NOR',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }],
        translationName: 'norway'
    },
    {
        name: 'Oman',
        code: '512',
        alpha2: 'OM',
        alpha3: 'OMN',
        currencies: [
            { name: 'Omani rial', 'name@en': 'Omani rial', code: 'OMR', symbol: 'ر.ع.', default: true },
            { name: 'Bahraini dinar', 'name@en': 'Bahraini dinar', code: 'BHD', symbol: '.د.ب', default: false }
        ],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'Pakistan', code: '586', alpha2: 'PK', alpha3: 'PAK', currencies: [], languages: [] },
    { name: 'Palau', code: '585', alpha2: 'PW', alpha3: 'PLW', currencies: [], languages: [] },
    { name: 'Panama', code: '591', alpha2: 'PA', alpha3: 'PAN', currencies: [], languages: [] },
    { name: 'Papua New Guinea', code: '598', alpha2: 'PG', alpha3: 'PNG', currencies: [], languages: [] },
    { name: 'Paraguay', code: '600', alpha2: 'PY', alpha3: 'PRY', currencies: [], languages: [] },
    { name: 'Peru', code: '604', alpha2: 'PE', alpha3: 'PER', currencies: [], languages: [] },
    {
        name: 'Philippines',
        code: '608',
        alpha2: 'PH',
        alpha3: 'PHL',
        currencies: [{ name: 'Philippine peso', 'name@en': 'Philippine peso', code: 'PHP', symbol: '₱', default: true }],
        languages: [{ name: 'Filipino', 'name@en': 'Filipino', code: 'fil', default: true }]
    },
    { name: 'Pitcairn Islands', code: '612', alpha2: 'PN', alpha3: 'PCN', currencies: [], languages: [] },
    {
        name: 'Poland',
        code: '616',
        alpha2: 'PL',
        alpha3: 'POL',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }],
        translationName: 'poland'
    },
    { name: 'Portugal', code: '620', alpha2: 'PT', alpha3: 'PRT', currencies: [], languages: [] },
    {
        name: 'Puerto Rico',
        translationName: 'puerto_rico',
        code: '630',
        alpha2: 'PR',
        alpha3: 'PRI',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [
            { name: 'Español', 'name@en': 'Spanish', code: 'es', default: true },
            { name: 'English', 'name@en': 'English', code: 'en', default: true }
        ]
    },
    {
        name: 'Qatar',
        code: '634',
        alpha2: 'QA',
        alpha3: 'QAT',
        currencies: [{ name: 'Bahraini dinar', 'name@en': 'Bahraini dinar', code: 'BHD', symbol: '.د.ب', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    {
        name: 'Republic of Ireland',
        code: '372',
        alpha2: 'IE',
        alpha3: 'IRL',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }],
        translationName: 'republic_of_ireland'
    },
    { name: 'Republic of Macedonia', code: '807', alpha2: 'MK', alpha3: 'MKD', currencies: [], languages: [] },
    { name: 'Republic of the Congo', code: '178', alpha2: 'CG', alpha3: 'COG', currencies: [], languages: [] },
    { name: 'Réunion', code: '638', alpha2: 'RE', alpha3: 'REU', currencies: [], languages: [] },
    { name: 'Romania', code: '642', alpha2: 'RO', alpha3: 'ROU', currencies: [], languages: [] },
    { name: 'Russian Federation', code: '643', alpha2: 'RU', alpha3: 'RUS', currencies: [], languages: [] },
    { name: 'Rwanda', code: '646', alpha2: 'RW', alpha3: 'RWA', currencies: [], languages: [] },
    { name: 'Saint Barthélemy', code: '652', alpha2: 'BL', alpha3: 'BLM', currencies: [], languages: [] },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        code: '654',
        alpha2: 'SH',
        alpha3: 'SHN',
        currencies: [],
        languages: []
    },
    { name: 'Saint Kitts and Nevis', code: '659', alpha2: 'KN', alpha3: 'KNA', currencies: [], languages: [] },
    {
        name: 'Saint Lucia',
        code: '662',
        alpha2: 'LC',
        alpha3: 'LCA',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Saint Martin', code: '663', alpha2: 'MF', alpha3: 'MAF', currencies: [], languages: [] },
    { name: 'Saint Pierre and Miquelon', code: '666', alpha2: 'PM', alpha3: 'SPM', currencies: [], languages: [] },
    { name: 'Saint Vincent and the Grenadines', code: '670', alpha2: 'VC', alpha3: 'VCT', currencies: [], languages: [] },
    { name: 'Samoa', code: '882', alpha2: 'WS', alpha3: 'WSM', currencies: [], languages: [] },
    { name: 'San Marino', code: '674', alpha2: 'SM', alpha3: 'SMR', currencies: [], languages: [] },
    { name: 'São Tomé and Príncipe', code: '678', alpha2: 'ST', alpha3: 'STP', currencies: [], languages: [] },
    {
        name: 'Saudi Arabia',
        code: '682',
        alpha2: 'SA',
        alpha3: 'SAU',
        currencies: [{ name: 'Saudi riyal', 'name@en': 'Saudi riyal', code: 'SAR', symbol: 'ر.س', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'Senegal', code: '686', alpha2: 'SN', alpha3: 'SEN', currencies: [], languages: [] },
    { name: 'Serbia', code: '688', alpha2: 'RS', alpha3: 'SRB', currencies: [], languages: [] },
    { name: 'Seychelles', code: '690', alpha2: 'SC', alpha3: 'SYC', currencies: [], languages: [] },
    { name: 'Sierra Leone', code: '694', alpha2: 'SL', alpha3: 'SLE', currencies: [], languages: [] },
    {
        name: 'Singapore',
        code: '702',
        alpha2: 'SG',
        alpha3: 'SGP',
        currencies: [{ name: 'Singapore dollar', 'name@en': 'Singapore dollar', code: 'SGD', symbol: '$', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Chinese', 'name@en': 'Chinese', code: 'zh', default: false }
        ]
    },
    { name: 'Sint Maarten', code: '534', alpha2: 'SX', alpha3: 'SXM', currencies: [], languages: [] },
    { name: 'Slovakia', code: '703', alpha2: 'SK', alpha3: 'SVK', currencies: [], languages: [] },
    { name: 'Slovenia', code: '705', alpha2: 'SI', alpha3: 'SVN', currencies: [], languages: [] },
    { name: 'Solomon Islands', code: '090', alpha2: 'SB', alpha3: 'SLB', currencies: [], languages: [] },
    { name: 'Somalia', code: '706', alpha2: 'SO', alpha3: 'SOM', currencies: [], languages: [] },
    { name: 'South Africa', code: '710', alpha2: 'ZA', alpha3: 'ZAF', currencies: [], languages: [] },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code: '239',
        alpha2: 'GS',
        alpha3: 'SGS',
        currencies: [],
        languages: []
    },
    {
        name: 'South Korea',
        code: '410',
        alpha2: 'KR',
        alpha3: 'KOR',
        currencies: [{ name: 'South Korean won', 'name@en': 'South Korean won', code: 'KRW', symbol: '₩', default: true }],
        languages: [{ name: 'Korean', 'name@en': 'Korean', code: 'ko', default: true }]
    },
    { name: 'South Sudan', code: '728', alpha2: 'SS', alpha3: 'SSD', currencies: [], languages: [] },
    {
        name: 'Spain',
        code: '724',
        alpha2: 'ES',
        alpha3: 'ESP',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }],
        translationName: 'spain'
    },
    { name: 'Sri Lanka', code: '144', alpha2: 'LK', alpha3: 'LKA', currencies: [], languages: [] },
    { name: 'State of Palestine', code: '275', alpha2: 'PS', alpha3: 'PSE', currencies: [], languages: [] },
    { name: 'Sudan', code: '729', alpha2: 'SD', alpha3: 'SDN', currencies: [], languages: [] },
    { name: 'Suriname', code: '740', alpha2: 'SR', alpha3: 'SUR', currencies: [], languages: [] },
    { name: 'Svalbard and Jan Mayen', code: '744', alpha2: 'SJ', alpha3: 'SJM', currencies: [], languages: [] },
    { name: 'Swaziland', code: '748', alpha2: 'SZ', alpha3: 'SWZ', currencies: [], languages: [] },
    {
        name: 'Sweden',
        code: '752',
        alpha2: 'SE',
        alpha3: 'SWE',
        currencies: [{ name: 'Euro', 'name@en': 'Euro', code: 'EUR', symbol: '€', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }],
        translationName: 'sweden'
    },
    {
        name: 'Switzerland',
        code: '756',
        alpha2: 'CH',
        alpha3: 'CHE',
        currencies: [{ name: 'Swiss franc', 'name@en': 'Swiss franc', code: 'CHF', default: true }],
        languages: [
            { name: 'Deutsch', 'name@en': 'German', code: 'de', default: true },
            { name: 'Français', 'name@en': 'French', code: 'fr', default: false },
            { name: 'English', 'name@en': 'English', code: 'en', default: false }
        ],
        translationName: 'switzerland'
    },
    { name: 'Syrian Arab Republic', code: '760', alpha2: 'SY', alpha3: 'SYR', currencies: [], languages: [] },
    {
        name: 'Taiwan',
        code: '158',
        alpha2: 'TW',
        alpha3: 'TWN',
        currencies: [{ name: 'New Taiwan dollar', 'name@en': 'New Taiwan dollar', code: 'TWD', symbol: '$', default: true }],
        languages: [{ name: 'Chinese', 'name@en': 'Chinese', code: 'zh', default: true }]
    },
    { name: 'Tajikistan', code: '762', alpha2: 'TJ', alpha3: 'TJK', currencies: [], languages: [] },
    {
        name: 'Tanzania, United Republic of',
        code: '834',
        alpha2: 'TZ',
        alpha3: 'TZA',
        currencies: [{ name: 'Omani rial', 'name@en': 'Omani rial', code: 'OMR', symbol: 'ر.ع.', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    {
        name: 'Thailand',
        code: '764',
        alpha2: 'TH',
        alpha3: 'THA',
        currencies: [{ name: 'Thai baht', 'name@en': 'Thai baht', code: 'THB', symbol: '฿', default: true }],
        languages: [{ name: 'Thai', 'name@en': 'Thai', code: 'th', default: true }]
    },
    {
        name: 'The Bahamas',
        code: '044',
        alpha2: 'BS',
        alpha3: 'BHS',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'The Gambia', code: '270', alpha2: 'GM', alpha3: 'GMB', currencies: [], languages: [] },
    { name: 'Timor-Leste', code: '626', alpha2: 'TL', alpha3: 'TLS', currencies: [], languages: [] },
    { name: 'Togo', code: '768', alpha2: 'TG', alpha3: 'TGO', currencies: [], languages: [] },
    { name: 'Tokelau', code: '772', alpha2: 'TK', alpha3: 'TKL', currencies: [], languages: [] },
    { name: 'Tonga', code: '776', alpha2: 'TO', alpha3: 'TON', currencies: [], languages: [] },
    {
        name: 'Trinidad and Tobago',
        code: '780',
        alpha2: 'TT',
        alpha3: 'TTO',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    {
        name: 'Tunisia',
        code: '788',
        alpha2: 'TN',
        alpha3: 'TUN',
        currencies: [{ name: 'Tunisian dinar', 'name@en': 'Tunisian dinar', code: 'TND', symbol: 'د.ت', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    {
        name: 'Turkey',
        code: '792',
        alpha2: 'TR',
        alpha3: 'TUR',
        currencies: [
            { name: 'Turkmenistani manat', 'name@en': 'Turkmenistani manat', code: 'TMT', symbol: null, default: true },
            { name: 'Turkmenistani manat', 'name@en': 'Turkmenistani manat', code: 'YTL', symbol: null, default: false }
        ],
        languages: [{ name: 'Turkish', 'name@en': 'Turkish', code: 'tr', default: true }]
    },
    { name: 'Turkmenistan', code: '795', alpha2: 'TM', alpha3: 'TKM', currencies: [], languages: [] },
    {
        name: 'Turks and Caicos Islands',
        code: '796',
        alpha2: 'TC',
        alpha3: 'TCA',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Tuvalu', code: '798', alpha2: 'TV', alpha3: 'TUV', currencies: [], languages: [] },
    { name: 'Uganda', code: '800', alpha2: 'UG', alpha3: 'UGA', currencies: [], languages: [] },
    {
        name: 'Ukraine',
        code: '804',
        alpha2: 'UA',
        alpha3: 'UKR',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [
            { name: 'Russian', 'name@en': 'Russian', code: 'ru', default: true },
            { name: 'English', 'name@en': 'English', code: 'en', default: false }
        ]
    },
    {
        name: 'United Arab Emirates',
        code: '784',
        alpha2: 'UE', //Hydra specific alpha2, should be "AE"
        alpha3: 'UAE',
        currencies: [
            {
                name: 'United Arab Emirates dirham',
                'name@en': 'United Arab Emirates dirham',
                code: 'AED',
                symbol: 'د.إ',
                default: true
            },
            { name: 'Bahraini dinar', 'name@en': 'Bahraini dinar', code: 'BHD', symbol: '.د.ب', default: false }
        ],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    {
        name: 'United Kingdom',
        translationName: 'united_kingdom',
        code: '826',
        alpha2: 'GB',
        alpha3: 'GBR',
        currencies: [{ name: 'Pound sterling', 'name@en': 'Pound sterling', code: 'GBP', symbol: '£', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    {
        name: 'United States',
        translationName: 'united_states',
        code: '840',
        alpha2: 'US',
        alpha3: 'USA',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Español', 'name@en': 'Spanish', code: 'es', default: false }
        ]
    },
    {
        name: 'United States Minor Outlying Islands',
        code: '581',
        alpha2: 'UM',
        alpha3: 'UMI',
        currencies: [],
        languages: []
    },
    { name: 'Uruguay', code: '858', alpha2: 'UY', alpha3: 'URY', currencies: [], languages: [] },
    { name: 'Uzbekistan', code: '860', alpha2: 'UZ', alpha3: 'UZB', currencies: [], languages: [] },
    { name: 'Vanuatu', code: '548', alpha2: 'VU', alpha3: 'VUT', currencies: [], languages: [] },
    { name: 'Vatican City', code: '336', alpha2: 'VA', alpha3: 'VAT', currencies: [], languages: [] },
    {
        name: 'Venezuela, Bolivarian Republic of',
        code: '862',
        alpha2: 'VE',
        alpha3: 'VEN',
        currencies: [
            { name: 'Venezuelan bolívar', 'name@en': 'Venezuelan bolívar', code: 'VEB', symbol: 'Bs.', default: true },
            { name: 'Venezuelan bolívar', 'name@en': 'Venezuelan bolívar', code: 'VEF', symbol: 'Bs.', default: false }
        ],
        languages: [{ name: 'Español', 'name@en': 'Spanish', code: 'es', default: true }]
    },
    {
        name: 'Vietnam',
        code: '704',
        alpha2: 'VN',
        alpha3: 'VNM',
        currencies: [{ name: 'Vietnamese dong', 'name@en': 'Vietnamese dong', code: 'VND', symbol: '₫', default: true }],
        languages: [{ name: 'Vietnamese', 'name@en': 'Vietnamese', code: 'vi', default: true }]
    },
    { name: 'Virgin Islands, British', code: '092', alpha2: 'VG', alpha3: 'VGB', currencies: [], languages: [] },
    {
        name: 'Virgin Islands, U.S.',
        code: '850',
        alpha2: 'VI',
        alpha3: 'VIR',
        currencies: [{ name: 'United States dollar', 'name@en': 'United States dollar', code: 'USD', symbol: '$', default: true }],
        languages: [{ name: 'English', 'name@en': 'English', code: 'en', default: true }]
    },
    { name: 'Wallis and Futuna', code: '876', alpha2: 'WF', alpha3: 'WLF', currencies: [], languages: [] },
    { name: 'Western Sahara', code: '732', alpha2: 'EH', alpha3: 'ESH', currencies: [], languages: [] },
    {
        name: 'Yemen',
        code: '887',
        alpha2: 'YE',
        alpha3: 'YEM',
        currencies: [{ name: 'Omani rial', 'name@en': 'Omani rial', code: 'OMR', symbol: 'ر.ع.', default: true }],
        languages: [
            { name: 'English', 'name@en': 'English', code: 'en', default: true },
            { name: 'Arabic', 'name@en': 'Arabic', code: 'ar', default: false }
        ]
    },
    { name: 'Zambia', code: '894', alpha2: 'ZM', alpha3: 'ZMB', currencies: [], languages: [] },
    { name: 'Zimbabwe', code: '716', alpha2: 'ZW', alpha3: 'ZWE', currencies: [], languages: [] }
]
