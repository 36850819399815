import { InputBase, styled } from '@mui/material'

const DefaultInput = (props) => {

    const {
        placeholder = '',
        value,
        onChange,
        onBlur,


    } = props

    return (
        <DefaultInputStyled
            variant="outlined"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{ 'aria-autocomplete': 'none', spellCheck: 'false' }}
            {...props}


        />
    )
}

export default DefaultInput



const DefaultInputStyled = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        boxSizing: 'border-box',
        position: 'relative',
        border: '1px solid rgba(153, 188, 223, 0.25)',
        fontSize: '16px',
        width: '100%',
        padding: '14px 15px',
        marginTop: '0px',
        borderRadius: '8px',
        color: '#000',
        fontWeight: 400,
        height: '45px',

        '&:focus': {
            outline: '#99BCDF 1px solid',
        }
    }
})) 