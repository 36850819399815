import mobiscroll from '@mobiscroll/react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import './ThailandProvinceSelect.css'
import { ThemeProvider } from '@emotion/react'
import { reaction } from 'mobx'
import { ReactComponent as DropdownIcon } from '../Assets/dropdown.svg'
import { Stack } from '@mui/material'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { lowerCase } from 'lodash'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { DefaultLabelTypo } from 'Components/Elements/Forms/InputsStyled';
import storeTaxInvoice from 'GlobalStores/Checkout/StoreTaxInvoice'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'


const ThailandProvinceSelect = observer(props => {
    const [provinceLoading, setProvinceLoading] = useState(false)

    const translate = useTranslate()

    const localObserver = useLocalObservable(() => ({
        listForRender: [],
        value: ''
    }))

    const renderProvinces = async () => {
        try {
            localObserver.listForRender = []
            setProvinceLoading(true)
            let list = []
            const keyEnglish = 'city_roman'
            const keyNative = 'city_native'

            storeCheckout.CheckOutInstance().Instance().provinceList.map(province => {
                const englishTitle = province[keyEnglish] ? province[keyEnglish].trim() : ''
                const nativeTitle = province[keyNative] ? province[keyNative].trim() : ''
                const title = StoreTranslate.CurrentLanguage() === 'EN' ? englishTitle : nativeTitle

                let multiLangTitle = StoreTranslate.CurrentLanguage() === 'EN' ? `${englishTitle} (${nativeTitle})` : `${nativeTitle} (${englishTitle})`

                list.push({
                    value: title,
                    text: multiLangTitle ? multiLangTitle : title,
                    html:
                        StoreTranslate.CurrentLanguage() === 'EN'
                            ? `<span>${englishTitle}</span><span style="color:#9DA9B9;padding-left:6px;">(${nativeTitle})</span>`
                            : `<span>${nativeTitle}</span><span style="color:#9DA9B9;padding-left:6px;">(${englishTitle})</span>`
                })

                return null
            })

            const bangkokIndex = list.findIndex(l => l.value === 'Bangkok' || l.value === 'กรุงเทพมหานคร')

            // Move Bangkok to the top if found
            if (bangkokIndex !== -1) {
                const bangkokLocation = list.splice(bangkokIndex, 1)[0]
                list.unshift(bangkokLocation)
            }
            localObserver.listForRender = list
            setProvinceLoading(false)
            setPropValueToState(localObserver.value)
        } catch (err) {
            setProvinceLoading(false)
            console.log(err)
        }
    }

    const setPropValueToState = propValue => {
        if (propValue) {
            let val = lowerCase(propValue)
            localObserver.listForRender.map(l => {
                if (lowerCase(l.text).includes(val)) {
                    localObserver.value = l.value
                }
                return l
            })
        }
    }

    useEffect(() => {
        const disposer = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    renderProvinces()
                }
            }
        )

        return () => {
            disposer()
        }
    }, [])

    useEffect(() => {
        if (props.value) {
            if (localObserver.listForRender.length > 0) {
                setPropValueToState(props.value)
            } else if (localObserver.listForRender.length === 0) {
                renderProvinces()
                setPropValueToState(props.value)
            }
        } else {
            if (localObserver.listForRender.length === 0) {
                renderProvinces()
            }
        }
    }, [props.value, localObserver.listForRender.length])

    const onSelected = (event, inst, key) => {
        switch (key) {
            case 'tax_invoice_province':
                storeTaxInvoice.SetInvoiceValue('province', inst.getVal())
                break
            default:
                storeCheckout.SetCheckoutFormValue('province', inst.getVal())
                break
        }
        // storeCheckout.SetCheckoutFormValue('province', inst.getVal())
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack className="mobi-custom-input">
                <Stack className="th_province_label_stack" sx={{ mb: "8px", pl: "16px", height: "auto", justifyContent: "center" }}>
                    <DefaultLabelTypo variant="label" style={{ color: 'rgba(21, 56, 98, 0.5)' }}>
                        {props.label}
                    </DefaultLabelTypo>
                </Stack>

                <mobiscroll.Select
                    className={props.provinceKey || 'province_selection'}
                    key={props.provinceKey || 'province_selection'}
                    display="center"
                    touchUi={false}
                    filter={true}
                    height={40}
                    maxWidth={400}
                    data={localObserver.listForRender}
                    onSet={(event, inst) => onSelected(event, inst, props.provinceKey)}
                    value={localObserver.value}
                    filterPlaceholderText=''
                    filterEmptyText={translate('not_found_province')}
                    disabled={provinceLoading}
                    group={{
                        groupWheel: false,
                        header: false
                    }}
                >
                    <mobiscroll.Input>
                        <Stack position={'absolute'} right={'15px'} top={'45%'}>
                            <DropdownIcon style={{ cursor: 'pointer' }} />
                        </Stack>
                    </mobiscroll.Input>
                </mobiscroll.Select>
            </Stack>
        </ThemeProvider>
    )
})

export default ThailandProvinceSelect
