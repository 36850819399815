import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Skeleton, Stack, ThemeProvider, Typography } from '@mui/material'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { MainTemplate } from '../../../../Template/MainTemplate'

import { ReactComponent as Plus } from './assets/svg/plus.svg'
import { ReactComponent as Minus } from './assets/svg/minus.svg'
import { ReactComponent as SunCloud } from './assets/svg/sun-cloud.svg'
import { ReactComponent as Sun } from './assets/svg/sun.svg'
import { ReactComponent as Cloud } from './assets/svg/cloud.svg'
import { ReactComponent as Moon } from './assets/svg/moon.svg'

import { storeElement } from 'GlobalStores/StoreElement'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useNavigate } from 'react-router-dom'
import { DefaultButton } from 'Components/Elements/Forms'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { reaction, runInAction } from 'mobx'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'

const HowItWork = observer(props => {
    const translate =  useTranslate()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const data = useLocalObservable(() => ({
        howItWorks: null,
        selectedItems: {}
    }))

    const handleShowDescription = (key, value) => {
        runInAction(() => (data.selectedItems[key] = value))
    }

    useEffect(() => {
        if (StoreFeelGreat.GetContent('howItWorks')) {
            runInAction(() => {
                data.howItWorks = StoreFeelGreat.GetContent('howItWorks')
            })
        }
        setLoading(false)
        reaction(
            () => StoreFeelGreat.GetContent('howItWorks'),
            (value, prev, disposer) => {
                runInAction(() => {
                    data.howItWorks = value
                })
                disposer.dispose()
            }
        )
    }, [])

    return (
        <Stack mb="60px" mt="20px">
            <ThemeProvider theme={MainTemplate}>
                {!loading && data.howItWorks !== null ? (
                    <ContainerStyled>
                        <Box textAlign={'center'} mb="40px" mt="60px">
                            <Typography variant="h6">{StoreTranslate.UseLanguage(data.howItWorks.title)}</Typography>
                        </Box>
                        {data.howItWorks.items.length > 0 && (
                            <ListWithIconsStyled>
                                {data.howItWorks.items.map((item, itemKey) => {
                                    const isSelectedItem = data.selectedItems[`key-${itemKey}`] === 'open' ? 'true' : 'false'

                                    return (
                                        <AccordionStyled
                                            key={itemKey}
                                            isshow={itemKey !== data.howItWorks.items.length - 1 ? 'true' : 'false'}
                                            style={{
                                                marginBottom: 16,
                                                padding: 20
                                            }}
                                            TransitionProps={{ unmountOnExit: true }}>
                                                <AccordionSummaryStyled
                                                    expandIcon={
                                                        isSelectedItem === 'true' ? (
                                                            <Minus
                                                                width={32}
                                                                height={32}
                                                                onClick={() => {
                                                                    handleShowDescription(
                                                                        `key-${itemKey}`,
                                                                        isSelectedItem === 'true' ? 'closed' : 'open'
                                                                    )
                                                                }}
                                                            />
                                                        ) : (
                                                            <Plus
                                                                width={32}
                                                                height={32}
                                                                onClick={() => {
                                                                    handleShowDescription(
                                                                        `key-${itemKey}`,
                                                                        isSelectedItem === 'true' ? 'closed' : 'open'
                                                                    )
                                                                }}
                                                            />
                                                        )
                                                    }
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    onClick={() => {
                                                        handleShowDescription(`key-${itemKey}`, isSelectedItem === 'true' ? 'closed' : 'open')
                                                    }}
                                                >
                                                    <Box alignItems="center" display="flex">
                                                        {item.icon === 'Sun_Cloud' && <SunCloud width={48} height={48} style={{ marginRight: 20 }} />}
                                                        {item.icon === 'Sun' && <Sun width={48} height={48} style={{ marginRight: 20 }} />}
                                                        {item.icon === 'Cloud' && <Cloud width={48} height={48} style={{ marginRight: 20 }} />}
                                                        {item.icon === 'Moon' && <Moon width={48} height={48} style={{ marginRight: 20 }} />}
                                                        <Typography
                                                            key={itemKey}
                                                            variant="h7"
                                                            lineHeight={'48px'}
                                                            style={{ listStyle: 'none' }}
                                                        >
                                                            {StoreTranslate.UseLanguage(item.title)}
                                                        </Typography>
                                                    </Box>
                                                </AccordionSummaryStyled>
                                                <AccordionDetailsStyled>
                                                    <ListWithDescriptionStyled
                                                        className="list-item">
                                                        <BoxDescriptionStyle>
                                                            <Typography
                                                                variant="caption2"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: StoreTranslate.UseLanguage(item.description)
                                                                }}
                                                            />
                                                        </BoxDescriptionStyle>
                                                        <IMGStyle src={item.image} alt="" />
                                                    </ListWithDescriptionStyled>
                                                </AccordionDetailsStyled>
                                        </AccordionStyled>
                                    )
                                })}
                            </ListWithIconsStyled>
                        )}
                        <DefaultButton
                            style={{
                                width: storeElement.dynamicWidth < 1180 ? '295px' : 'auto',
                                padding: "0 42px",
                                height: '48px',
                                background: '#003764',
                                margin: '20px auto 0 auto'
                            }}
                            titleText={translate('buy_now')}
                            onClick={() => navigate('/products')}
                        />
                    </ContainerStyled>
                ) : (
                    <>
                        <ContainerStyled>
                            <Box textAlign={'-webkit-center'} mb="40px" mt="60px">
                                <Skeleton variant="rounded" width={'40%'} height={'50px'} sx={{ marginTop: '54px' }} />
                            </Box>
                            <ListWithIconsStyled>
                                {Array.from(Array(4).keys()).map(num => {
                                    return <Skeleton key={num + 'listWork'} variant="rounded" width={'100%'} height={'70px'} sx={{ margin: '0px 0 30px 0' }} />
                                })}
                            </ListWithIconsStyled>
                        </ContainerStyled>
                    </>
                )}
            </ThemeProvider>
        </Stack>
    )
})

const AccordionStyled = styled(Accordion)`
    background-color: transparent;
    box-shadow: none;
    margin: 0px;
    ${props => (props.isshow === 'true' ? `border-bottom: 3px solid #99bcdf;` : ``)}
    &.MuiPaper-root.MuiAccordion-root {
        ::before {
            background-color: transparent;
            // border-top: 3px solid #99bcdf;
        }
        &.Mui-expanded {
            margin: 0px;
        }
    }
`

const AccordionSummaryStyled = styled(AccordionSummary)`
    &.MuiButtonBase-root {
        &.MuiAccordionSummary-root {
            padding: 0;
            min-height: auto;
            .MuiAccordionSummary-content {
                padding: 0;
                margin: 0;
            }
        }
    }
`

const AccordionDetailsStyled = styled(AccordionDetails)`
    &.MuiAccordionDetails-root {
        padding: 0;
    }
`

const ContainerStyled = styled(Container)`
    text-align: -webkit-center;

    @media screen and (max-width: 824px) {
        width: 80%;
    }

    @media screen and (max-width: 430px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 600px) {
        // padding-left: 0;
        // padding-right: 0;
    }
`

const ListWithIconsStyled = styled(Stack)`
    max-width: 770px;
    align-self: center;
    margin-bottom: 40px;

    @media screen and (max-width: 824px) {
    }
`

const ListWithDescriptionStyled = styled(Box)`
    &.list-item {
        align-items: center;
        display: flex;
        padding-top: 24px;
        padding-bottom: 50px;
        justify-content: space-between;

        @media screen and (max-width: 430px) {
            display: grid;
            justify-items: center;
            padding-top: 4px;
            padding-bottom: 50px;
        }
    }
`

const BoxDescriptionStyle = styled(Box)`
    max-width: 269px;
    text-align: -webkit-left;
    align-self: start;

    @media screen and (max-width: 824px) {
    }

    @media screen and (max-width: 430px) {
        max-width: 100% !important;
    }
`

const IMGStyle = styled.img`
    max-width: 290px;
    max-height: 166px;
    padding-right: 50px;

    @media screen and (max-width: 824px) {
        max-width: 145px;
        padding-right: 30px;
    }

    @media screen and (max-width: 430px) {
        max-width: 160px;
        margin-top: 40px;
        padding-right: 0px;
    }
`

export default HowItWork
