import styled from "@emotion/styled"
import { InputBase, Typography } from "@mui/material"
import StoreSubScriptions from "GlobalStores/StoreSubScriptions"

export const DefaultLabelTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Inter, NotoSansThai',
    fontStyle: 'normal',
    fontSize: StoreSubScriptions.fontSize,
    color: 'rgba(21, 56, 98, 0.5)',
    fontWeight: theme?.typography?.label?.fontWeight || 400
}))

export const DefaultInputStyled = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        boxSizing: 'border-box',
        position: 'relative',
        border: '1px solid rgba(153, 188, 223, 0.25)',
        fontSize: 14,
        width: '100%',
        padding: '24px 16px',
        marginTop: '0px',
        borderRadius: '6px',
        color: '#153862',
        fontWeight: 400,
        height: '50px',
        fontFamily: 'Inter, NotoSansThai',
    },
    '.MuiOutlinedInput-root': {
        border: '1px solid rgba(153, 188, 223, 0.25)',
        borderRadius: 6,
        height: 50
    },
    '.Mui-focused': {
        borderColor: 'rgb(21, 56, 98)',
        color: 'rgb(21, 56, 98)'
    },
    '.MuiOutlinedInput-notchedOutline': {
        display: 'none'
    }
})) 

