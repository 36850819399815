import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { ServerEnv } from 'Configs/ServerEnv'
import axios from 'axios'
import { Buffer } from 'buffer'
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    TextField,
    Typography
} from '@mui/material'
import httpHelper from 'Services/HttpHelper'

const DirectIntegrationWP = observer(() => {
    const [mbcUrl, setMbcURL] = useState({
        DEV: 'member-calls2-dev',
        PROD: 'member-calls2-dev'
    })

    const [ddcLoading, setDDCLoading] = useState(false)
    const [orderLoading, setOrderLoading] = useState(false)
    const [loginLoading, setLoginLoading] = useState(false)

    const [ddcHTML, setDdcHTML] = useState(null)
    const [challengeHTML, setChallengeHTML] = useState(null) 
    const [sessionId, setSessionId] = useState(null)

    const [selectedENV, setSelectedENV] = useState('DEV')
    const [cardHolderName, setCardHolderName] = useState('TESTER')
    const [cardNumber, setCardNumber] = useState('5200000000001096')
    const [expiryDate, setExpiryDate] = useState('2025-12')
    const [cvv, setCvv] = useState('123')
    const [email, setEmail] = useState('it-exp@unicity.com')
    const [testPrice, setTestPrice] = useState('2')

    const [frameElement, setFrameElement] = useState(null)
    const [createOrderRes, setCreateOrderRes] = useState(null)

    const [countPathChange, setCountPathChange] = useState(0)

    const [open, setOpen] = useState(false)

    const handleFrameElement = useCallback(e => {
        setFrameElement(e.target)
    }, [])

    const handleCardNumberChange = event => {
        // Allow only digits and limit to 16 characters
        const value = event.target.value.replace(/\D/g, '').slice(0, 16)
        setCardNumber(value)
    }

    const handleCvvChange = event => {
        // Allow only digits and limit to 3 characters
        const value = event.target.value.replace(/\D/g, '').slice(0, 3)
        setCvv(value)
    }

    const handleTestPriceChange = event => {
        // Allow only digits and limit to 2 decimal places
        const value = event.target.value.replace(/\D/g, '')
        if (parseInt(value) >= 10) {
            setTestPrice(10)
        } else {
            setTestPrice(value)
        }
    }

    const handleSubmit = async () => {
        // Handle form submission
        console.log('Form submitted:', {
            cardHolderName,
            cardNumber,
            expiryDate,
            cvv,
            email,
            testPrice
        })

        // request DDC
        await _getWorldPayDeviceDataCollection()
    }

    let _getWorldPayDeviceDataCollection = async () => {
        try {
            setDDCLoading(true)
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://${mbcUrl[selectedENV]}.unicity.com/hot/payment_router/world-pay/ddc?stag=${selectedENV}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    bin: setCardNumber
                })
            }
            axios
                .request(config)
                .then(response => {
                    const cleanedHtml = response.data.ddcHTML.replace(/\r\n/g, '')
                    setDdcHTML(cleanedHtml)
                    setSessionId(response.data.sessionId)
                    setTimeout(() => {
                        setDDCLoading(false)
                        _order({ pass_sessionId: response.data.sessionId })
                    }, 5000)
                })
                .catch(error => {
                    console.log(error)
                })
        } catch (error) {
            console.log('_getWorldPayDeviceDataCollection Error ', error)
            setDDCLoading(false)
        }
    }

    let _order = async ({ pass_sessionId }) => {
        setOrderLoading(true)
        let orderData = {
            referenceID: pass_sessionId,
            creditCardDetail: {
                cardHolderName: cardHolderName,
                cardNumber: cardNumber,
                cardExp: expiryDate,
                cardCVV: cvv
            },
            shopperInfo: {
                email: email || null,
                firstName: null,
                lastName: null,
                address1: null,
                postalCode: null,
                city: null,
                countryCode: null,
                telephoneNumber: null
            },
            paymentData: {
                value: testPrice * 100,
                currencyCode: 'JPY'
            }
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://${mbcUrl[selectedENV]}.unicity.com/hot/payment_router/world-pay/order?stag=${selectedENV}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: orderData
        }

        axios
            .request(config)
            .then(response => {
                setCreateOrderRes(response.data)
                if (response.data.challengeHTML) {
                    setChallengeHTML(response.data.challengeHTML.replace(/\r\n/g, ''))
                }
                setOrderLoading(false)
            })
            .catch(error => {
                console.log(error)
                setCreateOrderRes(error)
                setOrderLoading(false)
            })
    }

    useLayoutEffect(() => {
        try {
            let headerElem = document.getElementById('header')
            headerElem.parentNode.removeChild(headerElem)
            let footerElem = document.getElementById('fg_footer')
            footerElem.parentNode.removeChild(footerElem)
        } catch (error) {
            console.log('Just remove parent Tag ERROR', error)
        }
    }, [])

    useEffect(() => {
        console.log('countPathChange ', countPathChange)
    }, [countPathChange])

    return (
        <Box>
            <Container maxWidth="sm">
                <Box sx={{ p: '20px 10px', height: '100vh' }}>
                    <Typography variant="h3" sx={{ color: 'red' }}>Direct Integrate WorldPay (JAPAN)</Typography>

                    <Card>
                        <CardContent>
                            <div>
                                <FormControl variant="outlined" sx={{ width: '100%', marginBottom: '30px' }}>
                                    <FormLabel>SELECT ENV</FormLabel>
                                    <Select
                                        value={selectedENV}
                                        onChange={e => {
                                            setSelectedENV(e.target.value)
                                        }}
                                        sx={{ width: '100%' }}>
                                        <MenuItem value="DEV">USE JAPAN DEV</MenuItem>
                                        <MenuItem value="PROD">USE JAPAN PROD</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    sx={{ margin: '10px 0' }}
                                    label="Card Holder Name"
                                    fullWidth
                                    value={cardHolderName}
                                    onChange={e => setCardHolderName(e.target.value)}
                                />
                                <TextField
                                    sx={{ margin: '10px 0' }}
                                    label="Card Number"
                                    fullWidth
                                    value={cardNumber}
                                    onChange={handleCardNumberChange}
                                    inputProps={{ maxLength: 16 }}
                                />
                                <TextField
                                    sx={{ margin: '10px 0' }}
                                    label="Expiry Date"
                                    fullWidth
                                    value={expiryDate}
                                    onChange={e => setExpiryDate(e.target.value)}
                                />
                                <TextField
                                    sx={{ margin: '10px 0' }}
                                    label="CVV"
                                    fullWidth
                                    value={cvv}
                                    onChange={handleCvvChange}
                                    type="password"
                                    inputProps={{ maxLength: 3 }}
                                />
                                <TextField sx={{ margin: '10px 0' }} label="Email" fullWidth value={email} onChange={e => setEmail(e.target.value)} />
                                <TextField sx={{ margin: '10px 0' }} label="Test Price" fullWidth value={testPrice} onChange={handleTestPriceChange} />
                                <Button disabled={ddcHTML || ddcLoading ? true : false} variant="contained" color="primary" onClick={handleSubmit}>
                                    Order
                                </Button>
                            </div>

                            {createOrderRes && createOrderRes.threeDSecureResult && (
                                <Box sx={{ margin: '10px 0', padding: '10px', backgroundColor: "green", color: "white" }}>
                                   Success !!
                                   {JSON.stringify(createOrderRes.threeDSecureResult)}
                                </Box>
                            )}

                            {createOrderRes && createOrderRes.ISO8583ReturnCode && (
                                <Box sx={{ margin: '10px 0', padding: "10px", backgroundColor: "red", color: "white" }}>
                                    ERROR CODE : {createOrderRes?.ISO8583ReturnCode?.code} <br />
                                    Description : {createOrderRes?.ISO8583ReturnCode?.description}
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Box>
            </Container>

            {ddcHTML && (
                <iframe
                    srcDoc={ddcHTML}
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    onLoad={handleFrameElement}
                    width="10px"
                    height="10px"
                />
            )}

            <Modal open={challengeHTML} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4
                    }}>
                    {challengeHTML && (
                        <iframe
                            srcDoc={challengeHTML}
                            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                            onLoad={e => {
                                handleFrameElement(e)
                                setCountPathChange(cpc => {
                                    return cpc + 1
                                })
                            }}
                            style={{ minHeight: '600px', minWidth: '200px' }}
                        />
                    )}
                </Box>
            </Modal>
        </Box>
    )
})

export default DirectIntegrationWP
