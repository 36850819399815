import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Switch, ThemeProvider, createTheme } from '@mui/material'

import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import './ModalExpSelect.css'
import SelectExpWheel from './SelectExpWheel'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import DefaultInput from '../DefaultInput'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { MainTemplate } from 'Components/Template/MainTemplate'

const ModalExpSelect = observer(props => {
    const [open, setOpen] = useState(false)

    const [month, setMonth] = useState(props.value.month || '')
    const [year, setYear] = useState(props.value.year || '')

    const onDataSet = () => {
        props.onSet(month, year)
        setOpen(false)
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden !important'
            document.getElementById('root').style.touchAction = 'none'
            document.getElementsByTagName('body')[0].style.touchAction = 'none'
        } else {
            document.body.style.overflow = ''
            document.getElementById('root').style.touchAction = ''
            document.getElementsByTagName('body')[0].style.touchAction = ''
        }
    }, [open])

    useEffect(() => {
        return () => {
            if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                let body = document.getElementById('root')
                if (body) {
                    body.style.position = 'relative'
                }
            }
        }
    }, [])

    const onSetValue = (type, selectedValue) => {
        switch (type) {
            case 'month':
                setMonth(selectedValue)
                break
            case 'year':
                setYear(selectedValue)
                break
            default:
                break
        }
    }

    const getHeader = type => {
        switch (type) {
            case 'month':
                return 'MM'
            case 'year':
                return 'YYYY'
        }
    }

    return (
        <ThemeProvider theme={ModalSelectTheme}>
            <Stack id="dialog-modal">
                <DefaultInput
                    onClick={() => {
                        setOpen(true)
                    }}
                    value={props.value.month && props.value.year ? `${props.value.month}/${props.value.year.slice(2, props.value.year.length)}` : ''}
                    label={props.label}
                    placeholder="MM/YY"
                />
                <Dialog open={open} onClose={() => setOpen(false)} disableTouchRipple scroll="body">
                    <Stack style={{ flexDirection: 'row' }}>
                        {!isEmpty(Object.keys(props.options)) &&
                            Object.keys(props.options).map((item, key) => (
                                <DialogContent disableTouchRipple>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: '#ababab',
                                            fontSize: '12px',
                                            paddingTop: '10px',
                                            fontFamily: '-apple-system, Helvetica Neue, Helvetica, Arial, sans-serif'
                                        }}>
                                        {getHeader(item)}
                                    </div>
                                    <SelectExpWheel
                                        onSet={selectedValue => {
                                            onSetValue(item, selectedValue)
                                        }}
                                        optionSet={props.options[item]}
                                        value={props.value[item]}
                                        optionName={item}
                                    />
                                </DialogContent>
                            ))}
                    </Stack>
                    <DialogActions>
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} borderRight={'1px solid #e3e8ed'}>
                            <Button onClick={() => setOpen(false)} disableTouchRipple disableRipple disableFocusRipple>
                                <T>cancel</T>
                            </Button>
                        </Box>
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} ml="0px!important">
                            <Button onClick={onDataSet} className="ok-button" disableTouchRipple disableRipple disableFocusRipple>
                                <T>ok</T>
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </Stack>
        </ThemeProvider>
    )
})

const ModalSelectTheme = createTheme({
    typography: {
        ...MainTemplate.typography,
        fontFamily: 'Prompt, sans-serif!important',
        option: {
            fontSize: '1.833333em',
            fontFamily: 'Prompt, sans-serif!important',
            padding: '0 .5em'
        }
    },
    components: {
        MuiDialog: {
            defaultProps: {
                disableTouchRipple: true,
                PaperComponent: 'div'
            },
            styleOverrides: {
                paper: {
                    backgroundColor: '#f7f7f7',
                    width: '270px',
                    borderRadius: '7px'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    zIndex: '2'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    border: '1px solid #e3e8ed',
                    padding: 0
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins, NotoSansThai!important',
                    color: '#22aaec',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '&.ok-button': {
                        fontWeight: 'bold'
                    }
                }
            }
        }
    }
})
export default ModalExpSelect
