import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreProducts from 'GlobalStores/StoreProducts'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { autorun, makeAutoObservable, runInAction } from 'mobx'

let order = {
    cart_message: { native: '', english: '' },
    cart_limit_qty: { native: '3', english: '3' },
    cart_message_2: {
        native: 'Not for Resale',
        english: 'Not for Resale'
    },
    cart_message_3: {
        native: 'The products listed on Unicity Express are not intended for resale. By submitting your order, you certify that you are purchasing the products for personal use only. ',
        english:
            'The products listed on Unicity Express are not intended for resale. By submitting your order, you certify that you are purchasing the products for personal use only. '
    },
    minimum_set_qty: { native: '3', english: '3' },
    cart_limit_price: { native: '700', english: '700' },
    minimum_item_qty: { native: '10', english: '10' },
    cart_limit_qty_total: { native: '', english: '' },
    cart_warning_qty_total: { native: '', english: '' },
    cart_warning_price_total: { native: '', english: '' },
    cart_warning_qty_per_item: { native: '', english: '' }
}

class StoreLoader {
    dictionary = false
    formatApi = false

    isWebReady = false

    mainApiLoaded = {
        country: false,
        settings: false,
        refreshToken: false
    }

    SetApiLoaded(key, value) {
        this.mainApiLoaded[key] = value
    }

    IsMainApiLoaded(key) {
        return this.mainApiLoaded[key]
    }

    IsWebReady() {
        return this.isWebReady
    }

    GetPercentSummary() {
        let percent = 0
        let totalMainApi = Object.keys(this.mainApiLoaded).length
        Object.keys(this.mainApiLoaded).map(key => {
            if (this.mainApiLoaded[key] === true) {
                percent += 100 / totalMainApi
            }
        })

        return percent
    }

    SetLoadFormatApi(load) {
        runInAction(() => {
            this.formatApi = load
        })
    }

    IsFormatApiLoading() {
        return this.formatApi
    }

    async InitAfterLoadSetting(settingResponseData, subscriptionConfigData) {
        // settingResponseData['order'] = order
        StoreProducts.Init(settingResponseData.flavors, settingResponseData.products)
        StoreFeelGreat.Init(settingResponseData)
        StoreFeelGreat.SetSubscriptionConfigData(subscriptionConfigData)
        StoreCheckout.Init(settingResponseData)
        StoreSubScriptions.Init(settingResponseData)
    }

    constructor() {
        makeAutoObservable(this)
        autorun(disposer => {
            if (this.GetPercentSummary() >= 100) {
                setTimeout(() => {
                    runInAction(() => {
                        this.isWebReady = true
                    })
                }, 1000)
                disposer.dispose()
            }
        })
    }
}

export default new StoreLoader()
