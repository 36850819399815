import React, { useEffect } from 'react'
import { ReactComponent as SuccessIcon } from '../Assets/success.svg'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, Box } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { runInAction } from 'mobx'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'

const SkipSuccessMobile = observer(props => {
    const translate = useTranslate()

    useEffect(() => {
        runInAction(() => {
            storeElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeElement.settingCard.padding = '40px 20px'
        })

        return () => {
            runInAction(() => {
                storeElement.cardTitle.titleStep1 = ''
                storeElement.settingCard.padding = ''
            })
        }
    }, [])

    const date = StoreSubScriptions.GetNextDateDelivery()
    const dateFormat = new Date(date).toLocaleDateString('en-uk', { year: 'numeric', month: 'short', day: 'numeric' })

    return (
        <>
            <Stack justifyContent="center" flexDirection="row">
                <Typography variant="title3" textAlign="center">
                    <T>skip_next_delivery</T>
                </Typography>
            </Stack>

            <Stack alignItems={'center'} justifyContent={'center'} textAlign="center" mt="60px">
                <SuccessIcon />

                <Box mt="40px" mb="40px" width="88%">
                    <Box mb="15px">
                        <Typography variant="title">
                            <T>success_skip_delivery_title</T> {' '}
                            <T>success_skip_delivery_title2</T>
                        </Typography>
                    </Box>

                    <Typography variant="caption5" textAlign={'center'} color="#023764">
                        {translate('success_skip_delivery_content').replace('{{date}}', dateFormat)}
                    </Typography>
                </Box>
            </Stack>
        </>
    )
})

export default SkipSuccessMobile
