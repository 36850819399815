import { styled } from '@mui/system'
import { observer } from 'mobx-react-lite'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { ThemeProvider, Stack } from '@mui/material'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import CardMenu from 'Components/Elements/UserAccountMenu/CardMenu'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { useEffect } from 'react'
import Breadcrumb from 'Components/Elements/UserAccountMenu/Breadcrumb'
import { runInAction } from 'mobx'
import { useSizeWindow } from './../../Helpers/GlobalHelpers'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'
import LoginWrapper from './LoginWrapper'

const UserAccount = observer(props => {
    const { width } = useSizeWindow()

    useEffect(() => {
        runInAction(() => {
            if (width <= 834) {
                storeElement.cardTitle.titleStep1 = ''
            }
        })
    }, [])

    return (
        <ThemeProvider theme={MainTemplate}>
            <LoginWrapper>
                {StoreAuth.IsAuthorized() === true ? (
                    <UserAccountWrapper>
                        <Stack height={'100%'} sx={{ flex: 1 }}>
                            <Breadcrumb display={width <= 834 ? 'none' : ''} />
                            <BlockWrapper flexDirection={'row'} gap={width > 834 ? '30px' : ''} mt={width > 834 ? '25px' : ''}>
                                <CardMenu />
                                <Stack gap="20px">
                                    <UserChildLayoutStyled
                                        className={storeElement.userLayout.className || ''}
                                        height={/invite/.test(window.location.pathname) ? 'fit-content' : '100%'}>
                                        {props.children}
                                    </UserChildLayoutStyled>
                                    <UserChildLayoutStyled>
                                        {props.secondChildren}
                                    </UserChildLayoutStyled>
                                    <UserChildLayoutStyled>
                                        {props.thirdChildren}
                                    </UserChildLayoutStyled>
                                </Stack>
                            </BlockWrapper>
                        </Stack>
                    </UserAccountWrapper>
                ) : (
                    <Stack minHeight={'90vh'} justifyContent={'center'} alignItems={'center'}>
                        <IconSpinner />
                    </Stack>
                )}
            </LoginWrapper>
        </ThemeProvider>
    )
})

const BlockWrapper = styled(Stack)`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (min-width: 900px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    // @media screen and (min-width: 1024px) {
    //     display: grid;
    //     grid-template-columns: 430px 2fr;
    // }

    @media screen and (min-width: 1366px) {
        display: grid;
        grid-template-columns: 430px 860px;
    }
`

const UserAccountWrapper = styled(Stack)`
    background: #d1d9e2;
    /* min-height: calc(100vh - (74px)); */

    @media screen and (min-width: 1024px) {
        padding: 30px 30px 40px 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

const UserChildLayoutStyled = styled(Stack)`
    position: relative;
    display: none;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;

    &.feelgreat--orderhistory {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }

    @media screen and (min-width: 900px) {
        display: block;
    }
`

export default UserAccount
