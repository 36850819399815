import { Box, Stack, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { storeElement } from 'GlobalStores/StoreElement'
import styled from '@emotion/styled'
import { MainTemplate } from '../../../../Template/MainTemplate'
import { useState } from 'react'
import { useEffect } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import RealResultsItem from './RealResultsItem'

const RealResults = observer(props => {
    const realResults = props.data

    const [items, setItems] = useState([])

    useEffect(() => {
        if (items.length === 0 && realResults.items.length > 0) {
            setItems(realResults.items)
        }
    })

    return (
        <ThemeProvider theme={MainTemplate}>
            <LayoutRealResultStyle>
                <BoxFlexStyle>
                    <Typography variant="h6">
                        {/* {realResults.title['english']} */}
                        {StoreTranslate.UseLanguage(realResults.title)}
                    </Typography>
                    <Typography variant="body2" mt="15px">
                        {/* {realResults.subTitle['english']} */}
                        {StoreTranslate.UseLanguage(realResults.subTitle)}
                    </Typography>
                </BoxFlexStyle>
                {storeElement.dynamicWidth > 834 ? (
                    <OwlCarouselPageDesktop isDefault={false} items={items} />
                ) : (
                    <OwlCarouselPageMobile isDefault={false} items={items} />
                )}
            </LayoutRealResultStyle>
        </ThemeProvider>
    )
})

const OwlCarouselPageDesktop = props => {
    const numPage = storeElement.dynamicWidth >= 834 ? 2 : 1
    const [items, setItems] = useState([])

    const options = {
        loop: true,
        // margin: 100,
        items: 1,
        autoplay: false,
        dots: true,
        nav: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        stagePadding: 0
    }

    useEffect(() => {
        if (props.items.length > 0) {
            const pageSize = numPage
            const numPages = Math.ceil(props.items.length / pageSize)

            const pages = []
            for (let i = 0; i < numPages; i++) {
                const startIndex = i * pageSize
                const page = props.items.slice(startIndex, startIndex + pageSize)
                pages.push(page)
            }
            setItems(pages)
        }
    }, [props.items.length])

    return (
        <BoxContentStyle>
            {items.length > 0 && (
                <OwlCarouselStyled className="owl-theme" {...options} style={{ textAlignLast: 'right' }}>
                    {items.map((item, key) => {
                        return (
                            <GridLayoutV2 key={key}>
                                {item.map((item, itemKey) => {
                                    const description = item.description
                                    return (
                                        <ListWithExpertsStyledV2 key={itemKey}>
                                            <RealResultsItem description={description} data={item} />
                                        </ListWithExpertsStyledV2>
                                    )
                                })}
                            </GridLayoutV2>
                        )
                    })}
                </OwlCarouselStyled>
            )}
        </BoxContentStyle>
    )
}

const OwlCarouselPageMobile = props => {
    const numPage = storeElement.dynamicWidth >= 824 ? 2 : 1
    const [items, setItems] = useState([])

    const options = {
        loop: true,
        // margin: 100,
        items: 1,
        autoplay: false,
        dots: true,
        nav: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        stagePadding: 0
    }

    useEffect(() => {
        if (props.items.length > 0) {
            const pageSize = numPage
            const numPages = Math.ceil(props.items.length / pageSize)

            const pages = []
            for (let i = 0; i < numPages; i++) {
                const startIndex = i * pageSize
                const page = props.items.slice(startIndex, startIndex + pageSize)
                pages.push(page)
            }

            setItems(pages)
        }
    }, [props.items.length])

    return (
        // <LayoutMobileStyle>
        <BoxContentStyle>
            {items.length > 0 && (
                <OwlCarouselStyledMobile className="owl-theme" {...options} style={{ textAlign: '-webkit-center' }}>
                    {items.map((item, key) => {
                        return (
                            <GridLayoutV2 key={key}>
                                {item.map((item, itemKey) => {
                                    const description = item.description
                                    return (
                                        <ListWithExpertsStyledV2 key={itemKey}>
                                            <RealResultsItem description={description} data={item} />
                                        </ListWithExpertsStyledV2>
                                    )
                                })}
                            </GridLayoutV2>
                        )
                    })}
                </OwlCarouselStyledMobile>
            )}
        </BoxContentStyle>
        // </LayoutMobileStyle>
    )
}

const LayoutRealResultStyle = styled(Stack)`
    margin: 100px 0px;
    width: 86%;
    align-self: center;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 834px) {
        width: 100%;
        flex-direction: column;
    }
`

// const LayoutMobileStyle = styled(Box)`
//     text-align: center;
//     align-self: center;
//     width: 90%;
//     margin-top: 30px;

//     @media screen and (max-width: 834px) {
//         width: 100%;
//         display: inline-table;
//         text-align: -webkit-center;
//     }
// `

const GridLayoutV2 = styled(Box)`
    text-align-last: left;
    align-self: center;
    justify-items: right;
    height: 100%;
    column-gap: 30px;
    padding: 0px;
    display: flex;
    /* margin-bottom: 10px; */
    padding-right: 30px;
    @media screen and (max-width: 834px) {
        grid-template-columns: auto;
        justify-content: flex-end;
        width: 100%;
    }
`

const ListWithExpertsStyledV2 = styled(Stack)`
    width: 80%;
    background-color: #ffffff;
    justify-content: space-between;
    border: ${props => props.border};
    border-radius: 12px;
    padding: 30px;
    text-align: left;

    @media screen and (max-width: 834px) {
        width: 90%;
    }

    @media screen and (max-width: 430px) {
        width: 78%;
    }
`

const BoxFlexStyle = styled(Box)`
    text-align: left;
    align-self: center;
    display: grid;
    width: 280px;
    padding-right: 80px;

    @media screen and (max-width: 834px) {
        text-align: center;
        width: 90%;
        padding: 0px;
    }
`

const BoxContentStyle = styled(Box)`
    text-align: center;
    align-self: center;
    /* width: 60%; */
    width: 750px;

    @media screen and (max-width: 834px) {
        margin-top: 30px;
        // width: 74%;
        width: 90%;
    }

    @media screen and (max-width: 430px) {
        margin-top: 30px;
        width: 86%;
    }
`

const IMGProfile = styled.img`
    height: 50px;
    width: 50px !important;
    padding-right: 10px !important;
`

const OwlCarouselStyled = styled(OwlCarousel)`
    .owl-dots {
        margin-right: 20px;
    }

    .owl-stage {
        display: flex;
    }

    .owl-item {
        display: flex;
        flex: 1 0 auto;
    }
`

const OwlCarouselStyledMobile = styled(OwlCarousel)`
    .owl-stage {
        display: flex;
        justify-content: center;
        gap: 10px;
    }
`

export default RealResults
