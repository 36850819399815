import React from 'react'
import { Avatar, createTheme, Grid, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ReactComponent as SpinnerIcon } from '../Assets/Spinner.svg'
import storeUser from 'GlobalStores/User/StoreUser'
import { DefaultButton } from 'Components/Elements/Forms'

const ChangeProfileImage = observer(props => {

    const { width } = useSizeWindow()
    const { callbackRemoveProfile, label, callback, isLoading = false } = props

    const PROFILE_PICTURE = storeUser.CustomerData().ProfilePicture('original')
    const translate = useTranslate()

    const handleChange = e => {
        e.preventDefault()
        callback()
    }

    return (
        <ThemeProvider theme={customTheme}>
            <Item
                item
                sx={{
                    padding: {
                        xs: '25px 20px',
                        md: '24px 20px'
                    },
                }}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center'
                    }}>
                    <Grid item className="item-list">
                        <Typography variant="label" fontSize={width < 830 ? '16px' : '16px'}>
                            {label}
                        </Typography>
                        <Typography variant="value" mt={'10px'} fontSize={width < 830 ? '14px' : '14px'}>
                            <T>profile_photo_caption</T>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid 
                    sx={{ 
                        marginTop: '24px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {PROFILE_PICTURE ? (
                        <Avatar
                            sx={{
                                bgcolor: 'transparent',
                                height: '85px',
                                width: '85px',
                                boxShadow: '0px 1px 3px rgba(68, 68, 78, 0.5)',
                                borderRadius: '20px',
                                border: '2.5px solid white',
                                opacity: isLoading ? 0.5 : '',
                            }}>
                            <img src={PROFILE_PICTURE} width={'100%'} height={'100%'} />
                        </Avatar>
                    ) : (
                        <Avatar
                            src="/broken-image.jpg"
                            sx={{
                                height: '85px',
                                width: '85px',
                                boxShadow: '0px 1px 3px rgba(68, 68, 78, 0.5)',
                                borderRadius: '20px',
                                border: '2.5px solid white',
                                opacity: isLoading ? 0.5 : '',
                                position: 'relative'
                            }}
                        />
                    )}
                    <ContainerButton>
                        <DefaultButtonStyled
                            onClick={handleChange}
                            width={width < 700 ? '275px' : '280px'}
                            titleText={translate('change_photo')}
                        />

                        <DefaultButtonStyled
                            onClick={callbackRemoveProfile}
                            width={width < 700 ? '275px' : '280px'}
                            titleText={translate('remove_photo')}
                        />
                    </ContainerButton>
                </Grid>
            </Item>
        </ThemeProvider>
    )
})

const Item = styled(Grid)`
    border-bottom: 1px solid #CCDEEF; 
    display: flex;
    flex-direction: column;
    :first-of-type {
        padding: 0px 0px 24px 0px;
    }
    :last-of-type {
        border-bottom: none;
        margin-bottom: 0px;
        padding: 24px 0px 0px 0px;
    }
    height: fit-content;
    .item-list {
        display: flex;
        flex-direction: column;
    }
    .spinning {
        animation: spin 1s infinite;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const DefaultButtonStyled = styled(DefaultButton)`
    background: #F6F9FD;
    border: 1px solid #5A8FC3;
    width: auto;
    height: 45px;
    color: #5A8FC4;
    font-weight: 400;
    font-size: 16px;
    border-radius: 8px;

    &:hover {
        color: #FFFFFF;
        border: transparent;
    }

    @media screen and (min-width:  430px) {
        width: 234px;
        height: 50px;
    }
`

const ContainerButton = styled(Stack)`
    flex-direction: column;
    margin-left: 20px;
    gap: 16px;
    width: 100%;

    @media screen and (min-width:  430px) {
        margin-left: 32px;
    }

    @media screen and (min-width:  768px) {
        flex-direction: row;
        gap: 24px;
    }

    @media screen and (min-width:  900px) {
        margin-left: 48px;
    }
`

export const customTheme = createTheme({
    palette: {
        primary: {
            main: '#153862'
        },
        secondary: {
            main: '#ffffff'
        },
        background: {
            default: '#D1D9E2',
            main: '#D1D9E2'
        },
        button: {
            main: '#5A8FC4',
            disabled: 'rgba(90, 143, 196, .3)'
        },
        flavor: {
            main: '#023764'
        }
    },
    typography: {
        label: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: {
                xs: '16px',
                sm: '16px'
            },
            color: '#003B6F'
        },
        value: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: {
                xs: '14px',
                sm: '14px',
                md: '14px'
            },
            color: '#8699AF'
        },
        link: {
            fontFamily: 'Poppins, NotoSansThai',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: {
                xs: '14px',
                sm: '14px',
                md: '18px'
            },
            textDecoration: 'underline',
            color: '#5A8FC3',
            cursor: 'pointer, NotoSansThai'
        }
    }
})

export default ChangeProfileImage