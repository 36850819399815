import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { Stack, ThemeProvider, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import ArrowGreyIcon from './Assets/arrowGrey.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'

const Breadcrumb = observer(props => {
    const navigate = useNavigate()
    const { width } = useSizeWindow()
    const location = useLocation()

    const isActiveStep1 = location.pathname.split('/').slice(1).length === 1
    const isActiveStep2 = location.pathname.split('/').slice(1).length === 2
    const isActiveStep3 = location.pathname.split('/').slice(1).length === 3
    const isActiveStep4 = location.pathname.split('/').slice(1).length === 4
    const isActiveStep5 = location.pathname.split('/').slice(1).length === 5

    const onClickBackProfile = () => {
        if (width <= 834) {
            navigate('/user')
        }
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack flexDirection={'row'} padding={props.padding || width < 1024 ? '0 0 20px 0' : ''} display={props.display || ''}>
                <Typography
                    variant="caption"
                    sx={{ color: '#153862', opacity: isActiveStep1 ? '' : '0.5', fontSize: '20px', fontWeight: 300 }}
                    onClick={onClickBackProfile}>
                    <T>account</T> &nbsp;
                </Typography>

                {storeElement.cardTitle.titleStep1 && (
                    <>
                        {width <= 834 ? (
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#153862',
                                    opacity: storeElement.settingCard.opacity || '',
                                    fontSize: '20px',
                                    fontWeight: 700
                                }}>
                                <img src={ArrowGreyIcon} alt={ArrowGreyIcon} width={'6px'} height={'12px'} />
                                &nbsp;&nbsp;
                                {storeElement.cardTitle.titleStep1} &nbsp;&nbsp;
                            </Typography>
                        ) : (
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#153862',
                                    opacity: storeElement.settingCard.opacity || isActiveStep2 ? '' : '0.5',
                                    fontSize: '20px',
                                    fontWeight: isActiveStep2 ? 700 : 300
                                }}>
                                <img src={ArrowGreyIcon} alt={ArrowGreyIcon} width={'6px'} height={'12px'} />
                                &nbsp;&nbsp;
                                {storeElement.cardTitle.titleStep1} &nbsp;&nbsp;
                            </Typography>
                        )}
                    </>
                )}

                {storeElement.cardTitle.titleStep2 && (
                    <Typography
                        variant="caption"
                        sx={{
                            color: '#153862',
                            opacity: storeElement.settingCard.opacity || isActiveStep3 ? '' : '0.5',
                            fontSize: '20px',
                            fontWeight: isActiveStep3 ? 700 : 300
                        }}>
                        <img src={ArrowGreyIcon} alt={ArrowGreyIcon} width={'6px'} height={'12px'} />
                        &nbsp;&nbsp;
                        {storeElement.cardTitle.titleStep2} &nbsp;&nbsp;
                    </Typography>
                )}

                {storeElement.cardTitle.titleStep3 && (
                    <Typography
                        variant="caption"
                        sx={{
                            color: '#153862',
                            opacity: storeElement.settingCard.opacity || isActiveStep4 ? '' : '0.5',
                            fontSize: '20px',
                            fontWeight: isActiveStep4 ? 700 : 300
                        }}>
                        <img src={ArrowGreyIcon} alt={ArrowGreyIcon} width={'6px'} height={'12px'} />
                        &nbsp;&nbsp;
                        {storeElement.cardTitle.titleStep3} &nbsp;&nbsp;
                    </Typography>
                )}

                {storeElement.cardTitle.titleStep4 && (
                    <Typography
                        variant="caption"
                        sx={{
                            color: '#153862',
                            opacity: storeElement.settingCard.opacity || isActiveStep5 ? '' : '0.5',
                            fontSize: '20px',
                            fontWeight: isActiveStep5 ? 700 : 300
                        }}>
                        <img src={ArrowGreyIcon} alt={ArrowGreyIcon} width={'6px'} height={'12px'} />
                        &nbsp;&nbsp;
                        {storeElement.cardTitle.titleStep4} &nbsp;&nbsp;
                    </Typography>
                )}
            </Stack>
        </ThemeProvider>
    )
})

export default Breadcrumb
