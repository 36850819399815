import { CardContent, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import CautiousImage from './Assets/test.svg'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { DefaultButton } from 'Components/Elements/Forms'
import RedirectPaymentGateway from '../RedirectPaymentGateway'
import { isSomething } from '../../../../Utils/Utils'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { FormatAPIs } from 'Services/FeelGreat/FormatAPIs'
import { PaymentAPIs } from 'GlobalStores/Checkout/PaymentServices/PaymentAPI'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import _, { get } from 'lodash'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import Checkout from 'GlobalStores/Checkout/Country/Checkout'
import storeAuth from 'GlobalStores/User/StoreAuth'
import PaymentHydraForm from '../ShippingAddressForm/Country/PaymentHydraForm'

const PaymentResponseFailedCard = () => {
    const [dataForPayment, setDataForPayment] = useState()
    const [dataPayment, setDataPayment] = useState({})
    const [loading, setLoading] = useState(false)
    const [dataRedirectToPayment, setDataRedirectToPayment] = useState()

    const getPostBackUrlDEV = () => {
        const locationURL = window.location
        let pathname = ''

        if (/fg-dev/.test(window.location.href)) {
            const subPath = locationURL.pathname.split('/')
            pathname = `${subPath[1]}/${subPath[2]}/${subPath[3]}`
        } else if (/epic/.test(window.location.href)) {
            const subPath = locationURL.pathname.split('/')
            pathname = `${subPath[1]}/${subPath[2]}/${subPath[3]}/${subPath[4]}/${subPath[5]}`
        }
        
        const postBackUrlDEV = {
            success: `{{hostname}}/${pathname}/{{country}}/payment-response/success`,
            error: `{{hostname}}/${pathname}/{{country}}/payment-response/failed`
        }
        
        const hostname = window.location
        const successUrlDEV = postBackUrlDEV.success.replace('{{hostname}}', hostname.origin).replace('{{country}}', StoreCountry.CountryLowerCase())
        const errorUrlDEV = postBackUrlDEV.error.replace('{{hostname}}', hostname.origin).replace('{{country}}', StoreCountry.CountryLowerCase())

        return {
            success: successUrlDEV,
            error: errorUrlDEV
        }
    }

    const submitRedirectPayment = async () => {
        if (StoreCheckout.CheckOutInstance().Instance().PaymentGateway === 'hydra3ds') {
            submit3DSHydraRedirectPayment()
        } else {
            setLoading(true)
            const hostname = window.location.origin
            const postbackUrl = {
                success: `{{hostname}}/{{country}}/payment-response/success`,
                error: `{{hostname}}/{{country}}/payment-response/failed`
            }
            const successUrl = postbackUrl.success.replace('{{hostname}}', hostname).replace('{{country}}', StoreCountry.CountryLowerCase())
            const errorUrl = postbackUrl.error.replace('{{hostname}}', hostname).replace('{{country}}', StoreCountry.CountryLowerCase())

            const query = new URLSearchParams(document.location.search)

            const gateway_ref_id = query.get('gateway_ref_id')
            const isGCash = query.get('method') === 'GCash'

            const paymentDetail = await PaymentAPIs.GetPaymentProxyDetail(gateway_ref_id)
            const { uuid } = paymentDetail.data
            const dataFromUuid = await FormatAPIs.getHotFormatV2(uuid, StoreCountry.Country3())

            setDataForPayment({
                ba_id: dataFromUuid.login_id,
                gateway_error_url: errorUrl,
                gateway_success_url: successUrl,
                source_path: window.location.href,
                token: dataFromUuid.token,
                uuid: uuid,
                type: 'feelGreat'
            })

            const ENV_DEV = /fg-dev/.test(window.location.href)

            const data = {
                ba_id: dataFromUuid.data.ushop.detail_page.login_id,
                gateway_error_url: ENV_DEV ? getPostBackUrlDEV().error : errorUrl,
                gateway_success_url: ENV_DEV ? getPostBackUrlDEV().success : successUrl,
                source_path: window.location.href,
                token: dataFromUuid.data.ushop.detail_page.token,
                uuid: uuid,
                type: 'feelGreat'
            }

            if (isGCash) {
                data.gateway_name = 'GCASH'
                data.gateway_success_url = successUrl + '?method=GCash'
                data.gateway_error_url = errorUrl + '?method=GCash'
            }

            const result = await FeelGreatAPI.CreatePaymentDataForHostedGateway(data)
            let paymentData = { ...result.data }

            if (paymentData.data.relocation && !!paymentData.data.payment_action_url) {
                window.location.href = paymentData.data.payment_action_url
            } else if (paymentData.data.redirect_url) {
                window.location.href = paymentData.data.redirect_url
            } else {
                if (paymentData.data.PAYMENT_URL) {
                    paymentData.data['payment_action_url'] = paymentData.data.PAYMENT_URL
                }
                setDataPayment(paymentData)
            }
        }
    }

    const submit3DSHydraRedirectPayment = async () => {
        setLoading(true)
        const checkout = new Checkout()
        const detailPage = JSON.parse(sessionStorage.getItem('detailPage'))
        const uuid = sessionStorage.getItem('uuid')

        storeAuth.token = detailPage.token

        const response = await checkout.SubmitPayment(uuid, detailPage)

        if (response) {
            setDataRedirectToPayment({
                formMethod: get(response.orderData, 'transactions.items[0].methodDetails.iouReceipt.method') || 'POST',
                formAction: get(response.orderData, 'transactions.items[0].methodDetails.iouReceipt.url'),
                formData: get(response.orderData, 'transactions.items[0].methodDetails.iouReceipt.data')
            })
        }
        setLoading(false)
    }

    const cancel = () => {
        window.location.href = `/${StoreCountry.CountryLowerCase()}`
    }

    return (
        <Card>
            {isSomething(dataPayment) && <RedirectPaymentGateway dataPayment={dataPayment.data} />}
            <CardContent style={{ padding: '40px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Image src={CautiousImage} style={{ width: '70px', height: '70px', marginBottom: '30px' }}></Image>
                <Stack sx={{ gap: '16px', marginBottom: '30px' }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: '700', color: '#0B3B61', fontFamily: 'Inter, NotoSansThai', textAlign: 'center' }}>
                        <T>payment_response_failed_header</T>
                    </Typography>
                    <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#003764', fontFamily: 'Inter, NotoSansThai', textAlign: 'center' }}>
                        <T>payment_response_failed_detail</T>
                    </Typography>
                </Stack>
                <Stack sx={{ gap: '25px' }}>
                    <DefaultButton
                        titleText={<T>try_again</T>}
                        width={'295px'}
                        onClick={submitRedirectPayment}
                        disabled={loading}
                        isloading={loading.toString()}
                    />
                    <CancelButton onClick={cancel}>
                        <T>cancel</T>
                    </CancelButton>
                </Stack>
            </CardContent>
            {dataRedirectToPayment && (
                <div style={{ display: 'inherit' }}>
                    <PaymentHydraForm dataPayment={dataRedirectToPayment} />
                </div>
            )}
        </Card>
    )
}

const Card = styled.div`
    background: #ffffff;
    border-radius: 12px;

    @media (min-width: 744px) {
        width: 600px;
    }
`

const Image = styled.img`
    width: 100%;
    margin-bottom: 20px;
    @media (min-width: 600px) {
        width: inherit;
        margin-bottom: inherit;
    }
`
const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 295px;
    height: 48px;
    background: #5a8fc4;
    border-radius: 32px;
    fontFamily: 'Poppins, NotoSansThai';
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
`
const CancelButton = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Poppins, NotoSansThai';
    color: #003764;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
`

export default PaymentResponseFailedCard
