import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import Payment from '../Payment'
import { PaymentAPIs } from '../PaymentServices/PaymentAPI'
import States from 'Components/Elements/Forms/StatesModal/Country/AustraliaStates.json'
import Address from 'Components/Elements/Forms/StatesModal/Country/SouthafricaAddress.json'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout';

class Southafrica {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']
    autoAddressKeyToForm = {
        street: 'address',
        locality: 'city_selection',
        administrative_area: 'province_selection',
        postal_code: 'zip_code'
    }

    chainAddress = {
        isChainAddress: true,
        groupAddress: ['province_selection', 'city_selection']
    }

    uuid = ''

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password: ''
            // password1: '',
            // password2: ''
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password1: '',
            password2: ''
        },
        shop: {
            uuid: this.GetUuid(),
            fullname: '',
            address: '',
            province: '',
            city: '',
            zip: '',
            email: ''
        }
    }

    formShop = {
        fullName: '',
        email: '',
        phone: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: ''
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        firstname_en: '',
        lastname_en: '',
        email: '',
        phone: '',
        password: '',
        address: '',
        city: '',
        province: '',
        zip: '',
        period: ''
    }

    ExcludedFieldsShippingIfCreateAccount = []

    ExcludedFieldsShipping = []

    mapFormKeyToFormatKey = {
        "province_selection": "province",
        "city_selection": "city",
        "zip_code": 'zip',
        "firstname": "firstname_en",
        "lastname": "lastname_en",
    }

    ArrangeCreateCheckoutForm = ['firstname', 'lastname', 'email', 'password', 'phone', 'country', 'address', 'province_selection', 'city_selection', 'zip_code']

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        {
            caption: 'purchase',
            link: '/checkout/review',
            paramsRequire: true
        }
    ]

    shippingMethods = [
        // {
        //     id: 1,
        //     api_value: 'pickup',
        //     title: 'Pickup, Casablanca Office',
        //     dict_key: 'pickup_casablanca_office',
        //     for_status: [],
        //     options: [
        //         {
        //             id: 1,
        //             title: 'Credit card',
        //             dict_key: 'creditcard',
        //             for_status: []
        //         }
        //     ]
        // },
        // {
        //     id: 2,
        //     api_value: 'ctm',
        //     title: 'CTM, Delivery to Centers',
        //     dict_key: 'ctm_delivery_to_centers',
        //     for_status: [],
        //     options: []
        // },
        {
            id: 1,
            api_value: 'delivery',
            title: '',
            dict_key: '',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: []
                }
            ]
        },
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: false,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice',
            style: {}
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay',
            style: {}
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: {}
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {}
        },
        {
            nameComponent: 'frequentlyAskedQuestions',
            titleContent: 'questions',
            style: {
                backgroundColor: '#F6F9FC'
            }
        }
    ]

    SomeCustomFormSelector = (formItem) => {

        if (formItem.key === 'province_selection') {
            formItem.placeholder = 'select_your_province'
        }
        return formItem
    }

    StatesList() {
        let items = []
        States.map(raw => {
            items.push({
                text: raw.abbreviation,
                value: raw.abbreviation,
                html: `<span>${raw.name}</span>`
            })
        })
        return items
    }

    AddressOptionList(key = '', fn) {
        let items = { city: [], province: [] }

        const provinceValue = get(
            (fn.GetCheckoutForms() || []).find(item => item.key === 'province_selection'),
            'value'
        )

        const provinceIndexList = Object.keys(Address)

        provinceIndexList.forEach(index => {

            const province_text = Address[index]['text']

            items = {
                ...items,
                province: [
                    ...items.province,
                    {
                        text: province_text,
                        value: province_text,
                        html: `<span>${province_text}</span>`
                    }
                ]
            }

        })

        if (provinceValue) {
            // check
            const provinceIndex = provinceIndexList.findIndex(index => Address[index]['text'] === provinceValue)

            get(Address, `${provinceIndex}.city_list`, []).forEach(city => {

                const city_text = city.text

                items = {
                    ...items,
                    city: [
                        ...items.city,
                        {
                            text: city_text,
                            value: city_text,
                            html: `<span>${city_text}</span>`
                        }
                    ]
                }
            })
        } else {
            items = {
                ...items,
                city: []
            }
        }

        return items[key.replaceAll('_selection', '')]
    }

    MapAutoAddressToForm(form, autoAddressData) {
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)
        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData.data) {
                    item.value = autoAddressData.data[swappedValuesAsKeys[item.key]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }

            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    GetShippingMethod() {
        return this.shippingMethods
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, products) {
        try {
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            // const allowCreditEnable = StoreAuth.IsAllowCredit()

            if (!Pay.hydra.transactions) {
                Pay.hydra.transactions = {}
            }

            Pay.hydra.transactions = {
                items: [
                    {
                        amount: 'this.terms.total',
                        type: 'IOU',
                        method: 'BankWire',
                        billToEmail: responsePreparePayment.data.data.hydra.shipToEmail,
                        billToPhone: responsePreparePayment.data.data.hydra.shipToPhone,
                        billToAddress: {
                            country: StoreCountry.Country2()
                        }
                    }
                ]
            }

            await Pay.PostToHydra()

            Pay.SendEmailV4()
            Pay.SendSms()

            await Pay.UpdateToLog()

            const responsePaymentDetail = await PaymentAPIs.GetPaymentDetail(responsePreparePayment.data.data.payment_id)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })
            try {
                TrackingEvents.PurchaseEvent({
                    transaction_id: responsePreparePayment.data.data.payment_id,
                    value: Pay.ushop.total_price,
                    currency: 'ZAR',
                    category: Pay.ushop.shipment_purchas_option,
                    items: products
                })
            } catch (e) {
                console.error('TrackingEvents.PurchaseEvent', e)
            }
            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                order_number: responsePaymentDetail.data.order_number
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral, cartItems) {
        try {
            const forms = toJS(Object.assign(this.createAccountWithShippingAddress, accForm))

            shippingForm.forEach(item => {

                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value
                } else {
                    forms[item.key] = item.value
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            const periodResponse = await FeelGreatAPI.GetPeriod('ZA')

            forms.period = periodResponse.data.entryPeriod
            forms.uuid = uuid
            forms.shipment_packs = JSON.stringify(cartItems)

            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/ZAF/feelGreat/createAccount${ServerEnv.ENV === 'DEV' ? '?dev=1' : '?emailDelay=1h'
                }`
            const response = await axios.post(url, forms)

            return {
                ...response.data,
                form: forms
            }
        } catch (e) {
            console.error(e)

            return {
                success: false
            }
        }
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        StoreCheckout.SetShippingMethod(this.shippingMethods[0]) // Use on MVP only.
        makeAutoObservable(this)
    }
}

export default Southafrica
