import { Stack, Typography } from "@mui/material"

const ErrorStack = ({ message }) => {
    return (
        <Stack sx={{
            width: '100%',
            height: '40px',
            borderRadius: '8px',
            backgroundColor: '#FFE7EB',
            paddingTop: '12px',
            paddingBottom: '12px',
            alignItems: 'center',
            justifyContent: 'center',
            mb: '20px'
        }}>
            <Typography
                sx={{
                    color: "#FE5D7C",
                    fontSize: '14px',
                    fontWeight: 400,
                }}
            >{message}</Typography>
        </Stack>
    )
}

export default ErrorStack